import { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { BookmarkContext } from "../Context/BookmarkContext";

export default function Matchlinks() {

    const bookmarkContext = useContext(BookmarkContext);
    
    return (
        <>
            <NavLink to="/matchmaking" className="button nav-tab matchmaking-link" activeClassName="active">
                Matchmaking Search
            </NavLink>

            <NavLink to="/my-contacts" className="button nav-tab my-contacts-link" activeClassName="active">
                My Contacts
            </NavLink>

            <NavLink to="/bookmarks" className="button nav-tab my-bookmarks-link" activeClassName="active">
                My Bookmarks
                <span className="bookmark-counter">{bookmarkContext.bookmark.length}</span>
            </NavLink>

        </>
    );
}