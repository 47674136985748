import React, {createContext, useContext, useEffect, useState} from "react";
import { isLogin, logout, checkLiveAccess, decryptedUserId } from "../utils";
import { doRequest } from "../utils/doRequest";
import { ProfileContext } from "./ProfileContext";

export const AuthContext = createContext({
    hasLiveAccess: false,
    isAuth: false,
    login: () => {},
    logout: () => {}
});



const AuthContextProvider = props => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [hasLiveAccess, setHasLiveAccess] = useState(false);
    const { profile, setProfile } = useContext(ProfileContext);
    let isSubscribed = true;
    

    useEffect(() => {        
        setIsAuthenticated(isLogin);
        if(isSubscribed && decryptedUserId()) {
            doRequest('React', '/lib/storage/users/' + decryptedUserId() + '/profile.json', 'GET', { }, true, (err,data) => {
                setProfile(data);
                setHasLiveAccess(data.livepage)
            });
        }
        return () => (isSubscribed = false)
    }, [])

    const loginHandler = () => {
        setIsAuthenticated(true);
        doRequest('React', '/lib/storage/users/' + decryptedUserId() + '/profile.json', 'GET', { }, true, (err,data) => {
            setProfile(data);            
            setHasLiveAccess(data.livepage)
        });
    }

    const logoutHandler = () => {
        setIsAuthenticated(false);
        logout()
    }

    return (
        <AuthContext.Provider value={{login: loginHandler, isAuth: isAuthenticated, logout: logoutHandler, hasLiveAccess: hasLiveAccess}}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthContextProvider;