import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ProfileContext } from "../Context/ProfileContext";

export default function StepNavigation() {
  const { profile } = useContext(ProfileContext);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/")[2];

  const [ enableExhibitortab , setEnableExhibitortab ] = useState(false);

  useEffect(() => {
    if(profile.usergroup) {
      let usrgrp = profile.usergroup.split(',');
      if(usrgrp.includes('3') || usrgrp.includes('1')){
        setEnableExhibitortab(true)
      }
    }
    
  }, [])
  

  return (
    <section className="modul backend-form bg-white">
      <div className="item-box">
        <div className="tab-box flex-box wrap  center-center">
          <div className={splitLocation ==  "personal"  || splitLocation == undefined ? "tab-item active" : "tab-item"} >
            <Link to={"/user-editor/personal"}>
              <span>Personal Data</span>
            </Link>
          </div>

          <div className={splitLocation ==  "professional" ? "tab-item active" : "tab-item"} >
            <Link to={"/user-editor/professional"}>
              <span>Professional Data</span>
            </Link>
          </div>

          {enableExhibitortab ? (
            <div className={splitLocation ==  "exhibitor" ? "tab-item active" : "tab-item"}>
            <Link to={"/user-editor/exhibitor"}>
              <span>Exhibitor Data</span>
            </Link>
          </div>
          ) : ''}
          

          <div className={splitLocation ==  "matchmaking" ? "tab-item active" : "tab-item"}>
            <Link to={"/user-editor/matchmaking"}>
              <span>Matchmaking</span>
            </Link>
          </div>

          <div className={splitLocation ==  "summary" ? "tab-item active" : "tab-item"}>
            <Link to={"/user-editor/summary"}>
              <span>Summary</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
