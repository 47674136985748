import React, { useContext, useEffect, useState } from "react";
import { ProfileContext } from "../Context/ProfileContext";
import SmallHero from "../Components/SmallHero";
import StepNavigation from "./StepNavigation";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { doRequest } from "../utils/doRequest";
import { cryptedUserId, isLogin } from "../utils";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { LoadingContext } from "../Context/LoadingContext";
import { AuthContext } from "../Context/AuthContext";

const editorConfiguration = {
  toolbar: [
    "bold",
    // 'italic'
  ],
};

export default function Exhibitor() {
  const authContext = useContext(AuthContext);

  const { setLoading } = useContext(LoadingContext);
  const { profile } = useContext(ProfileContext);
  const [ position, setPosition] = useState("");
  const [ description, setDescription] = useState("");
  const [ activeVChat, setActiveVchat] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
	  let isSubscribed = true;
    document.body.classList.add("backend");
    document.body.classList.add("bd-user-profile");

    if(isSubscribed) {
      setActiveVchat(profile.activatevideo);
      setPosition(profile.position);
      setDescription(profile.shortdesc);
    }
     
    return () => {
      isSubscribed = false;
      document.body.classList.remove("backend");
      document.body.classList.remove("bd-user-profile");
    }
  }, [])

  useEffect(() => {
    if (!authContext.isAuth ) { return (navigate("/user-login")); }    
  }, [authContext.isAuth])
  
  function handleNext() {
    setLoading(true);
    doRequest('Server', '/api/v1/Frontend/user/updateUser','POST', 
      {
        "userid": cryptedUserId(),
        "step": 1,
        "fields": {
            "position": position,
            "shortdesc": description,
            "activatevideo": activeVChat ? 1:0
        }
      }, true, (err, data) => {
      if(data.success) {
        setLoading(false);
        doRequest('Server', '/api/v1/Frontend/user/updateMatch','POST', {}, true, (err,data) => console.log(err));
        return navigate('/user-editor/matchmaking');
          }
      })
  }

  function handlePrev() {
    setLoading(true);
    doRequest('Server', '/api/v1/Frontend/user/updateUser','POST', 
    {
      "userid": cryptedUserId(),
      "step": 1,
      "fields": {
          "position": position,
          "shortdesc": description,
          "activatevideo": activeVChat ? 1:0
      }
    }, true, (err, data) => {
        setLoading(false);
        doRequest('Server', '/api/v1/Frontend/user/updateMatch','POST', {}, true, (err,data) => console.log(err));
        return navigate('/user-editor/professional');
    })
  }

  return (
    <div className="base-box scroll-box" id="page-top">
      <Header hideOptions={true} />

      <div className="base-item">
        <SmallHero />

        <main className="main-content">
          <StepNavigation />
          <section className="modul backend-form pd-5">
            <div className="form-box txt-center">
              <h3 className="no-margin">Your Profile - Exhibitor Contact Data</h3>
              <p className="info-text txt-left small-box">
                This information will be shown in your Company Space
              </p>

              <div className="form-row approval-box">
                <div className="field-item standard-radio">
                  <input
                    type="checkbox"
                    name="tx_user_edituser[video-chat-appointments]"
                    id="video-chat-appointments"
                    value="Activate Video Chat Appointments"
                    checked={activeVChat}
                    onChange={(e) => setActiveVchat(e.target.checked)}
                  />
                  <label for="video-chat-appointments">
                    Activate Video Chat Appointments
                  </label>
                  <span className="button info open-tooltip"></span>
                  <div className="tooltip">
                      Activate this button in order to allow visitors to your company space to book a video appointment with you. Triggered by your activation you will receive an e-Mail with further instructions and the log-in information to the appointment tool.
                  </div>
                </div>
              </div>
			{activeVChat ? (
				<>
					<div className="form-row position-box" id="step3-position">
						<div className="field-item txt-left">
						<input
							type="text"
							id="exhibitor-position"
							placeholder=" "
							className="standard-field"
							value={position}
							onChange={(e) => setPosition(e.target.value)}
						/>
						<label className="field-label" for="exhibitor-position">
							Position
						</label>
						</div>
					</div>
					<div
						className="form-row short-description-box"
						id="step3-description"
					>
						<div className="field-item">
						<label className="block-label" for="editor">
							Short Description{" "}
							<span className="description">I am the right person for…</span>
						</label>

						<CKEditor
							editor={ClassicEditor}
							config={editorConfiguration}
							data={description}
							onBlur={(event, editor) => {
							setDescription(editor.getData());
							}}
						/>

						{/* <textarea id="editor" name="tx_user_edituser[editor]" >{profile.shortdesc}</textarea> */}
						</div>
					</div>
			  	</>
			  ): ''}

              <div className="button-box form-row flex-box">
                <div className="field-item left-item">
                  <button
                    className="button previous show-loader"
                    name="tx_user_edituser[saveStep3]"
                    value="prev"
                    onClick={handlePrev}
                  >
                    <span className="button-label">Previous</span>
                    <span className="icon-box">
                      <i className="icon"></i>
                    </span>
                  </button>
                </div>
                <div className="field-item right-item">
                  <button
                    className="button call-to-action next show-loader"
                    value="next"
					          onClick={handleNext}
                  >
                    <span className="button-label">Next</span>
                    <span className="icon-box">
                      <i className="icon"></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}
