import { useContext } from "react";
import overlayContext from "../Context/overlayContext";

export default function FirstView () {
    const { setOverlayData, setShowoverlay } = useContext(overlayContext);
    
    return (
        <section className="modul first-view-box next-on-stage pd-4">
            <div className="item-box">
                <h5 className="txt-uppercase">WELCOME TO THE DIGITAL IP SERVICE WORLD PLATFORM</h5>

                <div className="first-view flex-box wrap bg-white ">

                    <div className="flex-item box-20">
                        <div className="flex-item-wrapper txt-center">
                            <figure className="image-box">
                                <img src="images/icon-faqmovie.svg" width="150" height="150" />
                            </figure>
                            <h5 className="pd-t3">Quick Platform Tour</h5>
                            <p className="pd-p1">Please find our tutorials for using this unique platform</p>
                            <button 
                            onClick={() => {setOverlayData({src: '/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_2-GuidedPlatformTour-01.mp4' }); setShowoverlay('FirstView')}}
                            className="button call-to-action"  >more</button>
                        </div>
                    </div>
                
                <div className="flex-item box-20">
                    <div className="flex-item-wrapper txt-center">
                        <figure className="image-box">
                            <img src="images/icon-companyspace.svg" width="150" height="150" />
                        </figure>
                        <h5 className="pd-t3">Company Spaces</h5>
                        <p className="pd-p1">Explore the Company Spaces of our Supporters & Exhibitors</p>
                        <button 
                        onClick={() => {setOverlayData({src: '/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_Part6_Exhibitors_01.mp4' }); setShowoverlay('FirstView')}}
                        className="button call-to-action" >more</button>
                    </div>
                </div>
                
                <div className="flex-item box-20">
                    <div className="flex-item-wrapper txt-center">
                        <figure className="image-box">
                            <img src="/images/icon-handshake.svg" width="150" height="150" />
                        </figure>
                        <h5 className="pd-t3">Matchmaker</h5>
                        <p className="pd-p1">Find and chat with business partners with our Matchmaker at IP Service World</p>
                        <button
                        onClick={() => {setOverlayData({src: '/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211115_User_Tutorial_Part1_Matchmaker_02.mp4' }); setShowoverlay('FirstView')}}
                        className="button call-to-action" >more</button>
                    </div>
                </div>
                
                <div className="flex-item box-20">
                    <div className="flex-item-wrapper txt-center">
                        <figure className="image-box">
                            <img src="/images/icon-calendar.svg" width="150" height="150" />
                        </figure>
                        <h5 className="pd-t3">My Agenda</h5>
                        <p className="pd-p1">Create your own personalized Agenda</p>
                        <button 
                        onClick={() => {setOverlayData({src: '/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_Part3_ProgrammandMyAgenda_01.mp4' }); setShowoverlay('FirstView')}}
                        className="button call-to-action" >more</button>
                    </div>
                </div>

                <div className="flex-item box-20">
                    <div className="flex-item-wrapper txt-center">
                        <figure className="image-box">
                            <img src="/images/icon-topicsearch.svg" width="150" height="150" />
                        </figure>
                        <h5 className="pd-t3">Search for Topics</h5>
                        <p className="pd-p1">Filter all content of IP Service World and its exhibitors according to your interests</p>
                        <button
                            onClick={() => {setOverlayData({src: '/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_Part5_SearchforTopics_01.mp4' }); setShowoverlay('FirstView')}}
                            className="button call-to-action" >more</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
};