import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom"
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { AuthContext } from "../Context/AuthContext";

import { isLogin } from '../utils';
import WithoutLiveAccess from "./WithoutLiveAccess";

export default function PublicPage() { 
    const authContext = useContext(AuthContext);
    const [ withoutAccessPage ,showWithoutAccessPage] = useState(false);

    useEffect(() => {
      document.body.classList.add('countdown');
      return () => {
        document.body.classList.remove('countdown');
      }
    }, [])
    
    useEffect(() => {
      if (authContext.isAuth) { showWithoutAccessPage(true) }
    }, [authContext.isAuth])

    if(withoutAccessPage) {
      return <WithoutLiveAccess />;
    }

    return (
        <div className="base-box" id="page-top">
        
        <Header showCounter={true} />
          
        <div className="base-item">
          <section className="hero-content">
            <div className="item-box flex-box stretch full-size">
              <div className="bg-image">
                <figure className="image-box">
                  <img
                    srcSet="/images/bg-live-790.jpg 790w,
                                                           /images/bg-live-1080.jpg 1080w,
                                                           /images/bg-live.jpg 1680w"
                    sizes="(max-width: 1680px) 100vw, 1680px"
                    src="/Public/images/bg-live.jpg"
                  />
                </figure>
              </div>
  
              <div className="hero-item-box flex-box wrap top-center count-to-zerohero-item-box count-to-zero">
                <div className="hero-item-wrapper">
                <div className="text-box pd-t5">
                  <h1>Welcome to the second hybrid <br/> IP Service World</h1>
                  <h5>The 12th edition of “IP connecting people” is both a physical and a digital event. In Munich, Germany and digitally worldwide. <br/>21st – 22nd of November 2022 | Convention &amp; Trade Fair </h5>
                  <h5> Thank You for Joining! </h5>
                  <br/>
                  <br/>
                </div>
                  <div className="col-box col-2 teaser-box cl-white teaser-box">
                    <div className="col-item bg-1 cl-white teaser-item">
                      <div className="content-box">
                        <h3>Get Your Ticket</h3>
                        <p>Not an exhibitor or attendee yet? Don’t miss out! Get your ticket to visit IP Service World physically and/or digitally!</p>
                      </div>
                      <div className="button-box">
                        <a target="_blank" href="https://www.ipserviceworld.com/registration/" className="button" title="Registration">
                          Register now
                        </a>
                      </div>
                    </div>
  
                    <div className="col-item bg-1 cl-white teaser-item">
                      <div className="content-box">
                        <h3>For Ticketholders</h3>
                        <p>To get the most out of digital IP Service World make sure to complete your user profile!</p>
                      </div>
                      <div className="button-box">

                      <Link style={{ "marginBottom": "1rem" }} className="button call-to-action" title="Login" to="/user-editor">
                          Complete User Profile
                          <span className="icon-box">
                            <i className="icon"></i>
                          </span>
                      </Link>
                        {isLogin() ? (
                          <Link className="button" title="Login" to="/live">
                              Live
                          </Link>
                        ) : (
                          <Link className="button" title="Login" to="/user-login">
                              Login
                          </Link>
                        ) }
                        
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="overlay live-stream active scroll-overlay"
          id="saves"
          style={{ display: "none" }}
        >
          <div className="swiper-lazy-preloader"></div>
        </div>
        <Footer />
      </div>
    )

}