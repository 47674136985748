import React from "react";

export default function StickyVideo() {
  return (
    <div className="overlay-iframe">
      <div className="video-box">
        <div className="inner">
          <div className="ce-textpic ce-center ce-above">
            <div className="ce-gallery" data-ce-columns="1" data-ce-images="1">
              <div className="ce-outer">
                <div className="ce-inner">
                  <div className="ce-row">
                    <div className="ce-column">
                      <figure className="video">
                        <div className="video-embed">
                          <video
                            width="1500"
                            controls
                            no-cookie
                            className="video-embed-item"
                          >
                            <source
                              src={process.env.REACT_APP_SERVER_ENDPOINT + "/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_2-GuidedPlatformTour-01.mp4"}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <script
          async=""
          type="text/javascript"
          id="hs-script-loader"
          defer=""
          src="//js-eu1.hs-scripts.com/25140199.js"
        ></script>
      </div>
    </div>
  );
}
