import React, {createContext, useEffect, useState} from "react";

export const TopicsContext = createContext({
    topics: {}
});

const TopicsContextProvider = props => {
    const [topics, setTopics] = useState({});
    
    return (
        <TopicsContext.Provider value={{topics: topics, setTopics: setTopics}}>
            {props.children}
        </TopicsContext.Provider>
    )
}

export default TopicsContextProvider;