import { useEffect } from "react";
import Footer from "../Components/Footer";
import Header from "../Components/Header";

export default function RegistrationPage() { 

    useEffect(() => {
        document.body.classList.add('countdown');
        return () => {
          document.body.classList.remove('countdown');
        }
    }, [])
    

    return (

        <div className="base-box" id="page-top">
        
            <Header />

            <div className="base-item">
            <section className="hero-content flex-box stretch bg-1">
            <div className="item-box flex-box stretch full-size">
                <div className="bg-image">
                    <figure className="image-box">
                    <img
                        srcset="/images/bg-live-790.jpg 790w, /images/bg-live-1080.jpg 1080w, /images/bg-live.jpg 1680w"
                        sizes="(max-width: 1680px) 100vw, 1680px"
                        src="/Public/images/bg-live.jpg"
                    />
                    </figure>
                </div>
                <div className="hero-item-box flex-box wrap center-center ">
                    <div className="item-box">
                        <section className="modul modul-intro-text pd-t12 pd-b4">
                        <div className="teaser-box" style={{ maxWidth: "100%" }}>
                        <div className="teaser-item" style={{ width: "100%" }}>
                        <div className="content-box">
                            <iframe
                            title="connfair iframe"
                            id="ticketsIframe"
                            className="ticketsIframe"
                            width="100%"
                            height="1024px"
                            scrolling="yes"
                            frameborder="0"
                            src="//test.registration.iframes.connfair.com/app/purchase?apiKey=1c596c79-c922-4dcb-ae9d-03122e9b0eec&eventId=228d0aec-833d-4564-b1f9-a544741fa97a&language=de"
                            ></iframe>
                        </div>
                        </div>
                </div>
                        </section>
                    </div>
                </div>
                
            </div>
            </section>
            </div>

            <Footer />
        </div>
    )

}