export default function SmallHero() {
  return (	
    <section className="hero-content small-hero flex-box stretch bg-4">
      <div className="bg-image">
        <figure className="image-box">
          <img
            srcSet="/images/header-bg-790.jpg 760w,
	/images/header-bg.jpg 1080w"
            sizes="(max-width: 1600px) 100vw, 1600px"
            src="/images/header-bg.jpg"
          />
        </figure>
      </div>
    </section>
  );
}
