import React, { useContext, useEffect, useState } from "react";
import Header from "../Components/Header";
import SmallHero from "../Components/SmallHero";
import StepNavigation from "./StepNavigation";
import Footer from "../Components/Footer";
import { ProfileContext } from "../Context/ProfileContext";
import parse from 'html-react-parser';
import { Link, useNavigate } from "react-router-dom";
import { doRequest } from "../utils/doRequest";
import { decryptedUserId } from "../utils";
import { AuthContext } from "../Context/AuthContext";

export default function Summary() {
  const authContext = useContext(AuthContext);
  const { profile } = useContext(ProfileContext);
  const [expertise,setExpertise] = useState([]);
  const navigate = useNavigate();
  const [ enableExhibitortab , setEnableExhibitortab ] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    document.body.classList.add("backend");
    document.body.classList.add("bd-user-profile");

    if(profile.usergroup) {
      let usrgrp = profile.usergroup.split(',');
      if(usrgrp.includes('3') || usrgrp.includes('1')){
        setEnableExhibitortab(true)
      }
    }

    doRequest(
      "React",
      "/lib/storage/users/"+ decryptedUserId() +"/questions.json",
      "GET",
      {},
      true,
      (err, data) => {
        if (isSubscribed) {
          setExpertise(data.expertise);
          // setQuestions(data.questions);
        }
      }
    );

    return () => {
      isSubscribed = false;
      document.body.classList.remove("backend");
      document.body.classList.remove("bd-user-profile");
    };
  }, []);


  useEffect(() => {
    if (!authContext.isAuth ) { return (navigate("/user-login")); }    
  }, [authContext.isAuth])

  return (
    <div className="base-box scroll-box" id="page-top">
      <Header hideOptions={true} />

      <div className="base-item">
        <SmallHero />

        <main className="main-content">
          <StepNavigation />

          <section className="modul backend-form pd-5">
            <div className="item-box" id="personal-data-content">
              <div className="tab-content txt-center">
                <div className="form-box">
                  <h3 className="no-margin">Profile - Preview</h3>
                  {profile.activatematch ? (
                    <p className="infotext checkmark-box">
                      Your Profile is available for matchmaking{" "}
                      <span className="icon-box checkmark">
                        <i className="icon"></i>
                      </span>
                    </p>
                  ) : ''}
                    
                    <div className="preview-box txt-left">
                    <div className="flex-box profile-data">
                      <div className="flex-item">
                          {profile.image ? (
                            <figure className="user-image-preview">
                              {profile.image? (<img src={`${process.env.REACT_APP_SERVER_ENDPOINT}${profile.image}`} />) : ''}
                            </figure>
                          ) : (
                            <figure className="user-image-preview">
                              <img src="/images/icon-user.svg" />
                            </figure>
                          ) }
                      </div>
                      <div className="user-text-data">
                        <p>
                          <span className="preview-text-label">
                            {profile.salutation ? parseInt(profile.salutation) == 1 ?  'Mr.' : '' : '' }{' '}
                            {profile.salutation ? parseInt(profile.salutation) == 3 ? 'Ms.' : '' : '' }{' '}
                            {profile.title}{' '}
                            {profile.first_name} {' '}
                            {profile.last_name}{', '}
                          </span>
                          <span className="preview-text-label"><strong>{profile.position}{' '}</strong></span>
                          <span className="preview-text-label">{profile.company}</span>
                          <span className="preview-text-label">
                            <a href={`mailto:${profile.email}`}>
                              {profile.email}
                            </a>
                          </span>
                          <span className="preview-text-label">
                            <a href={`tel:{profile.telephone`}>{profile.telephone}</a>
                          </span>
                        </p>
                      </div>
                    </div>

                    <h5>My Interests</h5>

                    <div className="hashtag-box">
                      <ul className="hashtag">
                          {expertise.length ? 
                            expertise.map((expertAt) => (
                              expertAt.checked ? (<li htmlFor="company-topic-2" className="hashtag-label active"> {expertAt.name} </li>) : ''
                            )) : ''
                          }
                      </ul>
                    </div>

                    <h5>About Me</h5>
                    {profile.description && parse(profile.description)}
                  </div>
                  <hr />
                  {enableExhibitortab ? (
                    ''
                        ) : (
                            profile.activatematch ? (
                              ''
                              ) :
                              <p>
                                If you want to connect with interesting exhibitors, speakers and visitors based on their profile press „Edit Profile“ and check the box „Activate Matchmaking“
                              </p>
                        )}

                  
                  
                

                  <div className="button-box form-row flex-box">
                      <div className="field-item left-item">
                        <Link to="/user-editor/matchmaking" className="button previous show-loader">
                          {/* <a className="button previous show-loader" href="/user-editor"> */}
                            <span className="button-label">Edit Profile</span>
                            <span className="icon-box">
                              <i className="icon"></i>
                            </span>
                          {/* </a> */}
                        </Link>
                      </div>
                      <div className="field-item right-item">
                        <Link to="/live" className="button tab-next show-loader">
                          <span className="button-label">Done</span>
                          <span className="icon-box">
                            <i className="icon"></i>
                          </span>
                        </Link>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}
