import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import $ from 'jquery'
import { useState } from 'react'
import SmallHero from './SmallHero'

export default function FeedbackPage() {

 

  useEffect(() => {
    document.body.classList.add('backend');
    document.body.classList.add('bd-user-profile');
    
    return () => {
      document.body.classList.remove('backend');
      document.body.classList.remove('bd-user-profile');
    }
  }, [])
  

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }


  const [ email, setEmail ] = useState();
  const [ rating, setRating ] = useState();
  const [ reason, setReason ] = useState();
  const [ cover, setCover ] = useState();
  const [ submited, setSubmited ] = useState(false);

  const [ errors , setErrors ] = useState({email: false, firstTime: true});


  function handleSubmit(e) {

    let clickerror = {
        email: (email.length == 0 || !validateEmail(email)) ? true : false,
        firstTime: false
    };

    setErrors(clickerror);

    let sercall = false;
    if ((errors.email == false)) {
      sercall = true;
    }

    e.preventDefault();

    if (sercall && errors.firstTime == false) {

        $.ajax({
                url: 'https://api.live-ipserviceworld.com/?type=1000233233',
                type: 'post',
                data: {
                    email: email,
                    nps_rating: rating,
                    please_tell_us_the_main_reason_for_your_decision_: cover,
                    which_topics_would_you_like_us_to_cover_during_our_next_ip_serviceworld_: reason
                },
                success: function(content) {
                    const obj = JSON.parse(content);
                    console.log(obj);
                    if (obj.succ == 1) {
                        setSubmited(true);
                    }
                    console.log(content);
                    setEmail('');
                    setRating();
                    setReason('');
                    setCover('');
                }
            });
        }
    }

  return (
    <div class="base-box scroll-box" id="page-top">
        <Header hideOptions={true} />
        <SmallHero />
        <main class="main-content">
        <section class="modul backend-form">
            <div class="item-box pd-t12 pd-b5" style={{"maxWidth" :"960px"}}>
                {!submited ? (
                    <form name="feedbackForm" onSubmit={handleSubmit} method="post">
                    <div class="form-box">
                        <h4>Your email-address:</h4>
                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                                <input type="email" value={email} 
                                onChange={(e) => {setEmail(e.target.value); errors.email = (e.target.value == 0  || !validateEmail(e.target.value)) ? true : false;setErrors(errors);}}
                                name="email" id="js_email_feedback" class="required" />
                                <label class="field-label" for="email">Email</label>
                            </div>
                        </div>

                        <div className="field-item txt-left">
                            {errors.email && <h5 className="cl-error no-margin">Please fill out this field with a valid email address</h5>}
                        </div>

                        <h4 class="no-margin">How likely is it that you would recommend the IP Service World to colleagues?</h4>
                            <p class="info-text">0 = unlikely; 10 = very likely</p>
    
                        <div class="form-row flex-box wrap">
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio0" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="0" />
                                <label for="radio0" class="hashtag-label">0</label>
                            </div>
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio1" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="1" />
                                <label for="radio1" class="hashtag-label">1</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio2" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="2" />
                                <label for="radio2" class="hashtag-label">2</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio3" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="3" />
                                <label for="radio3" class="hashtag-label">3</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio4" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="4" />
                                <label for="radio4" class="hashtag-label">4</label>
                            </div>
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio5" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="5" />
                                <label for="radio5" class="hashtag-label">5</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio6" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="6" />
                                <label for="radio6" class="hashtag-label">6</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio7" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="7" />
                                <label for="radio7" class="hashtag-label">7</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio8" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="8" />
                                <label for="radio8" class="hashtag-label">8</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio9" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="9" />
                                <label for="radio9" class="hashtag-label">9</label>
                            </div>
    
    
                            <div class="field-item standard-radio feedback-formular-radio" style={{"maxWidth":"60px", "minHeight":"30px;"}}>
                                <input type="radio" id="radio10" name="would_you_recommend_the_innovation_days_to_colleagues" onChange={(e) => setRating(e.target.value)} value="10" />
                                <label for="radio10" class="hashtag-label">10</label>
                            </div>
                        </div>
    
    
                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                                <label class="block-label regular" for="please_tell_us_the_main_reason_for_your_decision">Please tell us the main reason for your decision:</label>
                                <textarea name="please_tell_us_the_main_reason_for_your_decision" id="please_tell_us_the_main_reason_for_your_decision" onChange={(e) => setReason(e.target.value)} class="required"></textarea>
                            </div>
                        </div>
                        <div class="form-row flex-box">
                            <div class="field-item txt-left box-100">
                                <label class="block-label regular" for="was_there_anything_that_was_missing_in_terms_of_the_topics_covered">Which topics would you like us to cover during our next IP Service World?</label>
                                <textarea name="was_there_anything_that_was_missing_in_terms_of_the_topics_covered" id="was_there_anything_that_was_missing_in_terms_of_the_topics_covered" onChange={(e) => setCover(e.target.value)}></textarea>
                            </div>
                        </div>
                        <div class="button-box form-row flex-box">
                            <div class="field-item box-100 txt-right">
                                <button class="button call-to-action" name="submit" type="submit"><span class="button-label">Submit</span></button>
    
                            </div>
    
                        </div>
                    </div>
                </form>
                ) : 'Thank you for your feedback!'}
            </div>
            </section>
        </main>
        <Footer />
</div>
  )
}
