import * as React from "react";
import { useEffect, useContext } from "react";
import axios from 'axios';
import $ from 'jquery'
import { Link, useNavigate } from 'react-router-dom';
import Matchlinks from "./Matchlinks";
import { doRequest } from "../utils/doRequest";
import { decryptedUserId } from "../utils";
import { BookmarkContext } from "../Context/BookmarkContext";
import overlayContext from "../Context/overlayContext";
import { ProfileContext } from "../Context/ProfileContext";
import { LoadingContext } from "../Context/LoadingContext";


export default function MatchFilter({type}) {
    
    const { profile } = useContext(ProfileContext);
    const bookmarkContext = useContext(BookmarkContext);
    const { showOverlay, setShowoverlay } = useContext(overlayContext); 
    
    let decryptedData = '';
    let filterLimit = 30;

    var [filterVar, setFilterVar] = React.useState([]);
    var [tempUsers, setTempUsers] = React.useState([]);
    var [matchUsers, setMatchUsers] = React.useState([]);
    var [paginatedUsers, setPaginatedUsers] = React.useState([]);
    var [paginationOutOfCount, setPaginationOutOfCount] = React.useState(0);
    var [activeItem, setActiveItem] = React.useState(1);
    var [paginationLinks, setPaginationLinks] = React.useState([]);
    // var [bookmarkUsers, setBookmarkUsers] = React.useState([]);

    var [positionFilter, setPositionFilter] = React.useState(0);
    var [industryFilter, setIndustryFilter] = React.useState(0);
    var [expertiseFilter, setExpertiseFilter] = React.useState(0);
    var [interestFilter, setInterestFilter] = React.useState(0);
    var tempBookmark = [];
    const { setLoading } = useContext(LoadingContext);
    
    const navigate = useNavigate();

    

    useEffect(() => {
        if(!profile.activatematch) {
            navigate('/user-editor/matchmaking');
        }
        
        doRequest('React', 'server.php', 'GET', { type: 'matchFilters'}, true, (err,data) => {
            setFilterVar(data.response);
        })

        if(type == 'matchmaking') {
            setLoading(true);
            doRequest('React', 'server.php', 'GET', { type: 'activeMatchUsers', user: decryptedUserId()}, true, (err,data) => {
                setLoading(false);
                setMatchUsers(data.response);
                setTempUsers(data.response);
                setPaginatedUsers(
                    data.response.slice(0,filterLimit)
                );
                calculatePaginationLinks(data.response?.length);
            })
        }

        if(type == 'contacts') {
            doRequest('React', 'server.php', 'GET', { type: 'matchContacts', user: decryptedUserId()}, true, (err,data) => {
                setMatchUsers(data.response);
                setTempUsers(data.response);
                setPaginatedUsers(
                    data.response.slice(0,filterLimit)
                );
                calculatePaginationLinks(data.response?.length);
            })
        }

        if(type == 'bookmark') {
            doRequest('React', 'server.php', 'GET', { type: 'matchBookmarks', user: decryptedUserId()}, true, (err,data) => {
                if(err) {
                    setMatchUsers([]);
                    setTempUsers([]);
                    setPaginatedUsers([]);
                    calculatePaginationLinks(0);
                } else {
                    setMatchUsers(data.response);
                    setTempUsers(data.response);
                    setPaginatedUsers(
                        data.response.slice(0,filterLimit)
                    );
                    calculatePaginationLinks(data.response?.length);
                }
            })
        }

        doRequest('React', 'server.php', 'GET', { type: 'bookmarkUsers', user: decryptedUserId()}, true, (err,data) => {
            if (data?.response) {
                // setBookmarkUsers(data?.response);
                bookmarkContext.setBookmark(data?.response);
            }
        })
       
    }, []);

    function calculatePaginationLinks(paginationCount) {
        let pageCount = Math.ceil(paginationCount / filterLimit);
        setPaginationOutOfCount(pageCount);

        let paginationLinkArr = [];
        for(let i=1; i<=pageCount; i++) {
            paginationLinkArr.push(i)
        }
        setPaginationLinks(paginationLinkArr);
    }

    function toggleBookmark(event) {
        event.preventDefault();
        let bookmarkId = parseInt(event.currentTarget.getAttribute('data-id'));
        
        let operation = 'add';
        if (bookmarkContext.bookmark.indexOf(bookmarkId) == -1) {
            bookmarkContext.setBookmark(bookmarkUsers => [...bookmarkUsers, bookmarkId]);
            // setBookmarkUsers(bookmarkUsers => [...bookmarkUsers, bookmarkId]);
        } else {
            tempBookmark = bookmarkContext.bookmark.filter(function(item) {
                return item !== bookmarkId
            });
            // setBookmarkUsers(tempBookmark);
            bookmarkContext.setBookmark(tempBookmark);
            operation = 'remove';
        }
        
        doRequest('React', 'server.php', 'GET', { type: 'addBookmarkUser', user: decryptedUserId(),toUser: bookmarkId,operation: operation}, true, (err,data) => {
            console.log()
        })
       
        
    }


    function filterRecs(event) {
        let filterSelection = event.target.getAttribute('id');

        let filterClass = event.target.getAttribute('data-id');
        let freeFilter = document.getElementById('freeTextFilter').value;

        let filterVal = event.target.value;

        if (filterSelection == 'position') {
            setPositionFilter(filterVal);
            setPositionFilter((state) => {
                filterCallback(
                    state,
                    industryFilter, 
                    interestFilter,
                    expertiseFilter,
                    freeFilter
                )
                return state;
            });
        } else if (filterSelection == 'industry') {
            setIndustryFilter(filterVal);
            setIndustryFilter((state) => {
                filterCallback(
                    positionFilter,
                    state, 
                    interestFilter,
                    expertiseFilter,
                    freeFilter
                )
                return state;
            });
        } else if (filterSelection == 'areaOfInterest') {
            setInterestFilter(filterVal);
            setInterestFilter((state) => {
                filterCallback(
                    positionFilter,
                    industryFilter, 
                    state,
                    expertiseFilter,
                    freeFilter
                )
                return state;
            });
        } else if (filterSelection == 'areaOfExpertise') {
            setExpertiseFilter(filterVal);
            setExpertiseFilter((state) => {
                filterCallback(
                    positionFilter,
                    industryFilter, 
                    interestFilter,
                    state,
                    freeFilter
                )
                return state;
            });
        } else if (filterClass == 'match-filter-btn') {
            filterCallback(
                positionFilter,
                industryFilter, 
                interestFilter,
                expertiseFilter,
                freeFilter
            );
        }
    }

    function resetFilter(event) {
        $('#position').val('');
        $('#industry').val('');
        $('#areaOfInterest').val('');
        $('#areaOfExpertise').val('');
        $('#freeTextFilter').val('');
        setPositionFilter(0);
        setIndustryFilter(0);
        setInterestFilter(0);
        setExpertiseFilter(0);
        filterRecs(event);
        filterCallback(0,0,0,0,'');
    }

    const handleKeyDownFilter = (event) => {
        if (event.key === 'Enter') {
            event.target.setAttribute('data-id', 'match-filter-btn');
            filterRecs(event);
        }
    }

    function filterCallback(positionFilter, industryFilter, interestFilter, expertiseFilter, freeFilter) {
        console.log('positionFilter  ' + positionFilter);
        console.log('industryFilter  ' + industryFilter);
        console.log('interestFilter  ' + interestFilter);
        console.log('expertiseFilter  ' + expertiseFilter);
        console.log('expertiseFilter  ' + freeFilter);

        let filter = tempUsers;

        if (positionFilter > 0) {
            filter = filter.filter(d => 
                d.positionFilter.indexOf(parseInt(positionFilter)) != -1
            )
        }

        if (industryFilter > 0) {
            filter = filter.filter(d => 
                d.industryFilter.indexOf(parseInt(industryFilter)) != -1
            )
        }

        if (interestFilter > 0) {
            filter = filter.filter(d => 
                d.areaOfIntrestFilter.indexOf(parseInt(interestFilter)) != -1
            )
        }

        if (expertiseFilter > 0) {
            filter = filter.filter(d => 
                d.expertiseFilter.indexOf(parseInt(expertiseFilter)) != -1
            )
        }

        if (freeFilter) {
            filter = filter.filter((d) => {
                let t = freeFilter.toLowerCase();
                let fname = d.first_name?.toLowerCase() ? d.first_name?.toLowerCase() : '';
                let lname = d.last_name?.toLowerCase() ? d.last_name?.toLowerCase() : '';
                let desc = d.description?.toLowerCase() ? d.description?.toLowerCase() : '';
                let cmp = d.company?.toLowerCase() ? d.company?.toLowerCase() : '';
                let fnamelname = (d.first_name?.toLowerCase() + ' ' + d.last_name?.toLowerCase()) ? d.first_name?.toLowerCase() + ' ' + d.last_name?.toLowerCase() : '';
                
                return fname.includes(t) || lname.includes(t) || desc.includes(t) || cmp.includes(t) || fnamelname.includes(t);
            })
        }

        calculatePaginationLinks(filter.length);
        setMatchUsers(filter);
        setPaginatedUsers(filter.slice(0,filterLimit));
    }
    
    

    function switchPage(page) {
        let offset = filterLimit * (page-1);
        setPaginatedUsers(
            matchUsers.slice(offset,offset+filterLimit) 
        );
        setActiveItem(page)
    }

    function paginateRec(event) {
        let page = parseInt(event.target.getAttribute('data-page'));
        switchPage(page)
    }

    function prevPage(event) {
        if (activeItem > 1) {
            switchPage(activeItem-1)
            setActiveItem(activeItem-1);
        }
    }

    function nextPage(event) {
        if (activeItem < paginationOutOfCount) {
            switchPage(activeItem+1)
            setActiveItem(activeItem+1);
        }
    }

    function Paginate() {
        if (paginationLinks.length) {
            return (
                <div className="results-pagination flex-box flex-item box-100 pd-3">
                <button className="tab-previous-page" id="results-previous" onClick={prevPage}><span className="icon-box arrow"><i className="icon"></i></span></button>
                
                <div style={{display:'none'}} className="tab-page bg-white strong currentPage">1</div>
                <span style={{display:'none'}} className="outOfPage">{paginationOutOfCount}</span>

                
                <div className="page-links-container">
                    {paginationLinks.map((item,val) => {
        
                        let activeClass = '';
                        if (item == activeItem) {
                            activeClass = 'active';
                        }
                
                        return (
                            <button key={val} href="#!" className={`match-page-link ${activeClass}`} data-page={item} onClick={paginateRec}>
                                <span className="button-label" data-page={item}>{item}</span>
                                <span className="icon-box"><i className="icon"></i></span>
                            </button>
                        );
                    })} 
                    
                </div>
                <button className="tab-next-page" id="results-next" onClick={nextPage}><span className="icon-box arrow"><i className="icon"></i></span></button>
            </div>
            )
        } else {
            return (
                <div className="results-pagination"></div>
            );
        }
    
    
    }

   
    function startChat(to) {
        setShowoverlay('chat');
        console.log(to);

        let toUser = to;
        var showDetail = true;
        var from = decryptedUserId();
        var to = toUser;

        let message = { to, from, showDetail };
        
        setTimeout(() => {
            var chatFrame = $('.chat-notifications #overlay-iframe');
            chatFrame[0].contentWindow.postMessage(message, '*');    
        }, 1000);

        

        axios.get(process.env.REACT_APP_ENDPOINT + 'server.php', {
            headers: { 
              'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
            },
            auth: {
              username: 'cuatconference',
              password: 'relaunch'
            },
            params: {
              type: 'myContacts',
              user: decryptedUserId(),
              toUser: toUser

            }
          })
        .then(res=>{
           
        })
        .catch(err=>{
             // console.log(err);
        });

    }
    

    return (
    <div>
        <section className="modul user-matchmaking  bg-white" id="filter-nav-section">
            <div className="item-box pd-t5 ">
                <div className="nav-box flex-box wrap">
                    <Matchlinks />
                    <button className="button reset-button" id="reset-filter" onClick={resetFilter}><span onClick={resetFilter} className="button-label">reset all Filters</span><span className="icon-box reset"><i className="icon"></i></span></button>
                </div>
                <div className="matchmaking-filter-box flex-box wrap filters">
                    <div className="matchmaking-filter-hashtag ui-group">
                        <select className="filter-select" data-questionid="6" value-group="hashtag" id="position" onChange={filterRecs}>
                            <option value="">Filter by Position</option>
                            {filterVar.position?.map((item, val) => {
                                return (
                                    <option key={item.uid} value={item.uid}>{item.answer}</option>
                                );
                            })}
                        </select>
                        <span className="icon-box select"><i className="icon"></i></span>
                    </div>
                    <div className="matchmaking-filter-top-match ui-group">
                        <select className="filter-select" data-questionid="5" value-group="top-match" id="industry" onChange={filterRecs}>
                            <option value="">Filter by Industry</option>
                            {filterVar.industry?.map((item, val) => {
                                return (
                                    <option key={item.uid} value={item.uid}>{item.answer}</option>
                                );
                            })}
                        </select>
                        <span className="icon-box select"><i className="icon"></i></span>
                    </div>
                    <div className="matchmaking-filter-f2 ui-group">
                        <select className="filter-select" data-questionid="4" value-group="f2" id="areaOfInterest" onChange={filterRecs}>
                            <option value="">Filter by Area Of Expertise</option>
                            {filterVar.interest?.map((item, val) => {
                                return (
                                    <option key={item.uid} value={item.uid}>{item.name}</option>
                                );
                            })}
                        </select>
                        <span className="icon-box select"><i className="icon"></i></span>
                    </div>
                    <div className="matchmaking-filter-f1 ui-group">
                        <select className="filter-select" data-questionid="4" value-group="f1" id="areaOfExpertise" onChange={filterRecs}>
                            <option value="">Filter by Area Of Interest</option>
                            {filterVar.expertise?.map((item, val) => {
                                return (
                                    <option key={item.uid} value={item.uid}>{item.answer}</option>
                                );
                            })}
                        </select>
                        <span className="icon-box select"><i className="icon"></i></span>
                    </div>
                </div>
                <div className="free-text-search flex-box">
                    <input type="text" id="freeTextFilter" onKeyDown={handleKeyDownFilter} placeholder="Search for anybody or anything" className="text-search"/>
                    <button className="button" data-id="match-filter-btn" id="match-filter-btn" type="button" onClick={filterRecs}>
                        <span className="button-label" data-id="match-filter-btn">Search</span>
                        <span className="icon-box search" data-id="match-filter-btn"><i className="icon"></i></span>
                    </button>
                </div>
            </div>
        </section>

        <section className="modul user-matchmaking bg-white">
            <div className="item-box pd-t0">
                <div className="flex-box wrap pd-t3 pd-b2">
                    <div className="flex-item filter-result-counter">
                        <strong>RESULTS: <span id="filter-result-counter">{matchUsers?.length}</span></strong>
                    </div>
                </div>
            </div>
        </section>

        <section className="modul user-matchmaking bg-4">
            <div className="item-box">

                <Paginate />
                
                <div className="matchmaking-results-box">
                    <form action="" method="post">
                        <div id="ajaxResponse" className="match-results">
                        {!paginatedUsers.length ? (<div class="pd-5 matchmaking-results-box">
                            <div id="holmes-placeholder"><h3>— No results. —</h3></div>
                        </div>) : '' }
                        
                        {paginatedUsers.map((item,val) => {

                            let bookmarkActive = '';
                            if (bookmarkContext.bookmark?.indexOf(parseInt(item.uid)) != -1){
                                bookmarkActive = 'active';
                            }
                            
                            let imageLink = process.env.REACT_APP_SERVER_ENDPOINT + '' + item.image


                            let matchClass = 'match';
                            if(item.score >= 200 ) {
                                matchClass = 'top-match';
                            } else if(item.score >= 100  && item.score < 200) {
                                matchClass = 'good-match';
                            }

                            return (
                                    <div key={item.uid} className={`matchmaking-results bg-white flex-box ${matchClass}`}>
                                    
                                    <div className="flex-item user-image">
                                        <figure className="image-box">
                                            <img src={imageLink}/>
                                        </figure>
                                    </div>
                                    
                                    <div className="flex-item flex-box user-information">
                                        <div className="user-information-item">
                                            <div className="user-nameset">
                                            <strong>{item.first_name} {item.last_name}</strong>
                                            {item.company}
                                            </div>
                                            <div className="user-status-freetext">
                                            <div>
                                                <strong>{item.userStatus?.anstext}</strong>
                                            </div>
                                            </div>
                                            <div className="user-status flex-box">
                                                {item.onlineStatus == 1
                                                    ? <span className="online-state">Online &amp; @IPSW</span>
                                                    : ''
                                                }
                                                {item.onlineStatus == 2 
                                                    ? <span class="icon-at-home"></span>
                                                    : ''
                                                }
                                                {item.speaker == 1
                                                    ? <span className="speaker">Speaker</span>
                                                    : ''
                                                }
                                                {item?.usergroup?.includes('1') == 1
                                                    ? <span class="exhibitor">Supporter & Exhibitor</span>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="flex-item user-action txt-right">
                                        <div className={`score-box ${matchClass}`}>
                                            <span className={matchClass}>{matchClass}</span><span className="icon-box"><i className="icon"></i></span>
                                        </div>
                                        <div className="user-action-item">
                                                                                            
                                           
                                            <button className={`button bookmark ${bookmarkActive}`} data-id={item.uid} onClick={toggleBookmark}>
                                                <span className="button-label" data-id={item.uid}>Bookmark</span>
                                                <span className="icon-box bookmarks" data-id={item.uid}><i className="icon"></i></span>
                                            </button>
                                            <Link to={'/userinfo/' + item.uid} className="button show-profile" >
                                                <span className="button-label">Show Profile</span><span className="icon-box"><i className="icon"></i></span>
                                            </Link>
                                            {/* <a data-id={item.uid} className="button show-profile" href=""><span className="button-label">Show Profile</span><span className="icon-box"><i className="icon"></i></span></a> */}
                                            <a onClick={() => startChat(item.uid)} className="button call-to-action" href="#!"><span className="button-label" >Start Chat</span><span className="icon-box"><i className="icon"></i></span></a>
                                        </div>
                                    </div>
                                    </div>
                                );
                            })}
                        </div>
                    </form>
                </div>
                
                <Paginate />
                
            </div>
        </section>



    </div>
    );
}