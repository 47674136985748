import { useState, useEffect } from "react"
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/lazy';
import 'swiper/css/navigation';
import "swiper/css/bundle";
import 'swiper/css/virtual';

import { Navigation, Autoplay, Lazy, Virtual } from 'swiper';
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";


export default function ThanksToSponsersSlider () {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const [ Sponsors, setSponsors ] = useState([]);
  
  useEffect(() => {
    let isSubscribed = true;
    if (isSubscribed) {
      axios.get(process.env.REACT_APP_ENDPOINT + 'lib/storage/content/sponsorlogos.json', {
        headers: { 
          'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
        },
        auth: {
          username: 'cuatconference',
          password: 'relaunch'
        }
        })
      .then(res=>{
        setSponsors(res.data);
      })
      .catch(err=>{
        console.log(err);
      })
    }

    return () => (isSubscribed = false)
	}, []);

  return (
    <div className="item-box">
    {Sponsors.length ? (
        <>
        <header className="modul-header txt-center">
          <h3>Thanks to our Sponsors</h3>
        </header>
        <div className="slider-center-box">
          <Swiper
            preloadImages={false}
            modules={[Navigation, Autoplay, Lazy]}
            lazy={true}
            spaceBetween={10}
            slidesPerView={2}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is <= 320px
              560: {
                slidesPerView: 3,
                slidesPerGroup: 1,
              },
              960: {
                slidesPerView: 5,
                slidesPerGroup: 1,
              },
              1080: {
                slidesPerView: 6,
                slidesPerGroup: 1,
              },
              1280: {
                slidesPerView: 7,
                slidesPerGroup: 1,
              },
            }}
            className="swiper-container"
          > 
            {Sponsors?.map((logoObj, index) => (
              <SwiperSlide key={index} virtualIndex={index}>
                <figure className="image-box exhibitor-category silver">
                  <img data-src={process.env.REACT_APP_LOCAL_IMAGES_PATH + logoObj.path + logoObj.file} className="swiper-lazy" />
                </figure>
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
              </SwiperSlide>
            ))}
          </Swiper>
          {params.get('arrows') ? (
            <>
            <div className="swiper-button-prev prev-3">
            <span className="icon-prev"></span>
          </div>
          <div className="swiper-button-next next-3">
            <span className="icon-next"></span>
          </div>
          </>
          ) : (
            <></>
          )}
      </div>
      </>
      ) : ''}
    </div>
  )
}
