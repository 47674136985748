import axios from "axios";
import { CryptoJS }  from 'crypto-js';
import { toast } from "react-toastify";
var FormData = require('form-data');

export function hashString(user) {
    var hash = CryptoJS.SHA256(user);
    return hash.toString();
}

/**
 * Send request to the server 
 * 
 * @param {string} endpoint - Server - will point to the process.env.REACT_APP_SERVER_ENDPOINT
 * @param {string} endpoint - React - will point to  the process.env.REACT_APP_ENDPOINT
 * @param {string} endpoint - XHR - will point to  the process.env.REACT_APP_ENDPOINT
 * @param {*} url 
 * @param {string} method - GET POST PUT
 * @param {*} body 
 * @param {*} withCredentials 
 * @param {*} callback 
 */
export function doRequest(endpoint, url, method, body, withCredentials, callback) {
    let baseurl = process.env.REACT_APP_SERVER_ENDPOINT;
    let headers = { Authorization: process.env.REACT_APP_HTACCESS_AUTHORIZATION }

    if(endpoint == 'typeNum' && method == "typeNum-POST") 
    {
      baseurl = process.env.REACT_APP_SERVER_ENDPOINT;
      headers = {
        "Username": process.env.REACT_APP_HTACCESS_USERNAME,
        "Password": 'Cu53:8W$qD',
      }
      var bodyFormData = new FormData();
      for (const property in body) {
        bodyFormData.append('tx_exhibitor_companyfilter['+ property +']', body[property]);
      }
      axios
        .post( baseurl + url,bodyFormData,
          {
            headers: headers
          }
        )
        .then((res) => {
            callback( null,res.data);
        })
        .catch((err) => {
            callback( err,null);
        });
    }

    if(endpoint == 'typeNum' && method == "typeNum-GET") 
    {
      baseurl = process.env.REACT_APP_SERVER_ENDPOINT;
      headers = {
        "Username": process.env.REACT_APP_HTACCESS_USERNAME,
        "Password": 'Cu53:8W$qD',
      }
      
      var bodyFormData = new FormData();
      for (const property in body) {
        bodyFormData.append('tx_user_leadandanalytics['+ property +']', body[property]);
      }
      
      axios
        .post( baseurl + url,
          bodyFormData,
          {
            headers: headers
          }
        )
        .then((res) => {
            callback( null,res.data);
        })
        .catch((err) => {
            callback( err,null);
        });
    }
    

    if(endpoint == 'Server')
    {
      baseurl = process.env.REACT_APP_SERVER_ENDPOINT;
      headers = {
        "Username": process.env.REACT_APP_HTACCESS_USERNAME,
        "Password": 'Cu53:8W$qD',
        "Content-Type" : 'multipart/form-data'
      }
    }

    if(endpoint == 'React')
    {
       baseurl = process.env.REACT_APP_ENDPOINT;
    }
    
    if(method == 'XHR') {
      var xhr = new XMLHttpRequest();
      
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          callback( null,xhr.response);
        }
      }

      xhr.open("POST", baseurl + url);
      xhr.setRequestHeader("Username", process.env.REACT_APP_HTACCESS_USERNAME);
      xhr.setRequestHeader("Password", 'Cu53:8W$qD');
      xhr.send(body);
    }

    if(method == 'POST') {
      
      axios
        .post( baseurl + url, 
          {
            params: {
              ...body,
              v: process.env.REACT_APP_V,
            },
          },
          {
            headers: headers
          }
        )
        .then((res) => {
            callback( null,res.data);
        })
        .catch((err) => {
            callback( err,null);
        });

    }


    if(method == 'GET') {

      axios
        .get( baseurl + url, 
          {
            auth: {
              username: "cuatconference",
              password: "relaunch",
            },
            params: {
              ...body,
              v: process.env.REACT_APP_V,
              random: Date.now(),
            },
          },
          {
            headers : headers
          }
        )
        .then((res) => {
            callback( null,res.data);
        })
        .catch((err) => {
            console.log(err);
            callback( err,null);
        });

    }


    
}