import React, { useEffect } from 'react'
import Footer from './Footer'
import Header from './Header'
import $ from 'jquery'

export default function Faq() {

  function jumpToSection(section) {
    let getIdOf = section.split('#')[1];
    console.log('section: '+section);
    console.log('getidof: '+getIdOf);
    let sectionElem = document.getElementById(getIdOf);
    console.log('id to jump: '+sectionElem);

    if (sectionElem) {
      console.log('jump to: '+sectionElem);
        sectionElem.scrollIntoView();
    }
  }

  useEffect(() => {
    document.body.classList.add('backend');
    document.body.classList.add('bd-user-profile');

    const search = window.location.hash;
    console.log('hash: '+search);
    if(search) {
      jumpToSection(search);
    }
    

    if(window.location.hash){
      $(window.location.hash).addClass('active')
    }
    
    return () => {
      document.body.classList.remove('backend');
      document.body.classList.remove('bd-user-profile');
    }
  }, [])
  

  return (
    <div class="base-box scroll-box" id="page-top">
      <Header hideOptions={true} />

<div class="base-item cf">
<main class="main-content">
<section class="modul backend-form">
<div class="item-box pd-t12 pd-b5">

<div class="flex-box wrap">
<div class="faq-box">
<h2>FAQ for Visitors</h2>
<h5 id="complete-profile" class="accordion-title">
<span class="count">1.</span> How do I complete my profile? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial on how to complete your profile here</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_1-Complete_your_profile_01.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="live-streaming" class="accordion-title">
<span class="count">2.</span> Where do I see what is currently being streamed live? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial on how to get to the live streams here</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_Part2_LiveonStage_01.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="personal-agenda" class="accordion-title">
<span class="count">3.</span> How can I set up my personal Agenda? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial on how to set up your personal agenda&nbsp;here</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_Part3_ProgrammandMyAgenda_01.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="content-supporters-exhibitors" class="accordion-title">
<span class="count">4.</span> Where can I see all the content of the supporters and exhibitors? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial of the newsroom&nbsp;here</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_Part4_Newsroom_01.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="content-interests" class="accordion-title">
<span class="count">5.</span> How do I find the content that interests me? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial on how to find my topics&nbsp;here</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_Part5_SearchforTopics_01.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="find-supporters-exhibitors" class="accordion-title">
<span class="count">6.</span> How can I find supporters and exhibitors? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial on supporters &amp; exhibitors&nbsp;here</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_Part6_Exhibitors_01.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="meet-visitors-exhibitors" class="accordion-title">
<span class="count">7.</span> How can I meet other visitors and exhibitors online? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial on how to us the matchmaker&nbsp;here</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211115_User_Tutorial_Part1_Matchmaker_02.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="guided-tour-platform" class="accordion-title">
<span class="count">8.</span> Is there a Guided Tour of the platform? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial on how to use the platform in general here</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211113_User_Tutorial_2-GuidedPlatformTour-01.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="" class="accordion-title">
<span class="count">9.</span> What does the digital IP Service World platform offer me as a visitor? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Virtually <strong>all content, lectures and the exhibition at IP Service World take place digitally as well as live</strong>. The platform provides high quality recommendations on lectures, exhibitors and content as well as networking opportunities between all participants.</p><ul> <li>Our filter function ensures that you as a visitor find precisely the topics, products and services that interest you.</li> <li>Our matchmaking tool helps you as a visitor to exchange ideas and network with visitors, exhibitors and speakers who are right for you.</li> <li>Text and video chat, including arranging appointments, make your networking easier. The system also shows whether someone is currently connected live from Munich or remotely from wherever.</li> </ul>
</div>
<h5 id="" class="accordion-title">
<span class="count">10.</span> How do I obtain access to the platform? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Everyone who has registered for IP Service World has access to the platform. No ticket yet? Then click <a href="https://www.ipserviceworld.com/registration/?__hstc=162172195.cdc0d210d18ef878a06570038cf7145e.1667540822868.1668416473328.1668425554709.23&amp;__hssc=162172195.7.1668425554709&amp;__hsfp=1458987039" target="_blank" rel="noreferrer">here</a>.</p>
<p>After your registration you will receive a link to your email address. You can use this link to register on the platform. Log in with the data sent from your registration confirmation and create your profile.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">11.</span> What technical requirements do I have to fulfil? Do I need a special programme or an app? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Have you already done the system check? Our&nbsp;<a href="https://jitsi.live-ipserviceworld.com/system-check/" target="_blank" rel="noreferrer">system check</a> is located at the bottom in the footer area. There you can check whether your computer fulfils all the technical requirements.</p>
<p>The platform is browser-based and device-independent. It works on all common devices. It can therefore also be used by visitors and exhibitors on site and thus offers a host of new possibilities in addition to proven functionalities.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">12.</span> Which parts of the programme can I watch in the live stream? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>All lecture panels as well as the keynotes will be offered as a live stream.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">13.</span> Can I join the discussion and ask questions during the lectures? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Simply post your question / comment in the live stream chat. The moderator will then give it to the speaker at the end of the lecture, if there is still enough time. If the question cannot be answered during the lecture, please send a message directly to the speaker via the Matchmaker searcher!</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">14.</span> Why do I need the Matchmaker? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>The Matchmaker is the brain of the digital platform. It works in a similar way to a business dating platform. The digital IPSW is an intelligent networking tool that visitors can use to find each other and exhibitors or partners and their respective product ranges, depending on their interest profile. A top match, good match or match is created, depending on how precisely interests and offers match. In addition to networking all visitors and exhibitors, including arranging appointments, direct digital exchange is also possible via text and video chats.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">15.</span> How do I find the exhibitor I am looking for and the content I want? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>All lecture panels and keynotes, all exhibitors and the content from the company spaces can be filtered using a search content tool. This means that everyone can put together their own personal programme and individual content according to their interests. Automated reminder e-mails direct visitors to the lectures they have selected in their personal agenda.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">16.</span> What is a company space? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>In his company space, the exhibitor presents himself and his offers, for example by white papers or articles. Get to know the experts and make video chat appointments with the digital stand personnel!</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">17.</span> How long is the content of the digital platform available? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>The platform will be available during the two days of the event on 22 and 23 November 2021. The live streams are only available during the actual lecture time.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">18.</span> The video call does not work! <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Have you already done the system check? Our&nbsp;<a href="https://jitsi.live-ipserviceworld.com/system-check/" target="_blank" rel="noreferrer">system check</a> is located at the bottom in the footer area. There you can check whether your computer fulfils all the technical requirements.</p>
</div>
<h5 id="" class="accordion-title active">
<span class="count">19.</span> The live stream does not work! <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>If the live stream does not work, please contact our support via the chat.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">20.</span> I cannot upload my picture when entering my profile! <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Does your profile picture meet the requirements? Check whether your image meets the given specifications.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">21.</span> Do you have another problem? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Use our chat. Feel free to send a message to our support team with a brief description of the problem via the chat.</p>
</div>
</div>
</div>

<div class="flex-box wrap">
<div class="faq-box">
<h2>FAQ for Exhibitors</h2>
<h5 id="exhibitor-tutorial" class="accordion-title">
<span class="count">1.</span> How to edit my Company Space? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial on how to edit your Company Space&nbsp;here</p>
<div class="video-box"><video controls="true">
  <source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211019_Tutorial-IPSW-Company%20Space%20Admin_fin.mp4" type="video/mp4" /></video>
  </div>
</div>
<h5 id="" class="accordion-title">
<span class="count">2.</span> Do I have the chance to preview my exhibitor profile? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Yes, absolutely. At the bottom of the “Edit Exhibitor Data” page you can find the button “Preview Company Space”. Do not forget to save your updates!</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">3.</span> Is it possible to publish my company space without uploading a whitepaper/article/picture? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>It is possible to publish your company space without uploading content on all given channels. Nevertheless, we recommend to upload as much content as possible to create benefits for your visitors.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">4.</span> The Whitepaper/Brochure/Article topics do not reflect the topic of my content. What do I do? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Classifying the various content offers makes it easier for visitors to search for their specific topic of interest. Therefore, it is helpful to choose the topic closest to your content.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">5.</span> Do I have a file size restriction, when uploading my whitepaper? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>When uploading your whitepaper, you have a file restriction: 10 MB and it has to be a PDF.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">6.</span> Login Process <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>For your login process you do not need a password. You just type in your email address. After you submitted your email address you will receive an access-link in your inbox. Make sure you also check your <strong>spam folder</strong>. By clicking on the link, you will enter the IP Service World platform. You can repeat this procedure for every login.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">7.</span> I did not receive a login email. <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>There are three possible reasons why you did not receive a login email:</p><ol> <li>The login email was sent to your spam folder. Please, check your spam folder!</li> <li>Your email is not the one that was used to register. Therefore, it is not stored in the backend and you have no authorization. Consult with your supervisor.</li> <li>The email you submitted was misspelled. Please, try again.</li> </ol>
</div>
<h5 id="" class="accordion-title">
<span class="count">8.</span> Do I have to log out? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>You can stay as long as you want on the platform. If you want to lock out you can just close the tab. To log back in, you simply repeat the login process.</p>

</div>
<h5 id="" class="accordion-title">
<span class="count">9.</span> What is an “Exhibitor Company Space Admin”? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>The “Exhibitor Company Space Admin” is able to edit the company space page (incl.: uploading whitepapers/articles). This Admin has extensive rights and is able to create new “Digital Company Contact”. He/She will receive an email with the login information after he/she has been created. An “Company Space Admin” can also create additional “Company Space Admins”.</p>
</div>
<h5 id="" class="accordion-title">
<span class="count">10.</span> What is a “Digital Company Contact”? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>The “Digital Company Contact” is the equivalent to the stand personnel at a fair. To be available for interacting with potential clients it might make more sense to not register the pyhsical stand staff onsite as digital stand staff as well.</p>
<p>The “Digital Company Contact” can be booked for video calls by visitors and can chat with visitors. He/she can define the timeslots they want to be available for calls. The number of “Digital Company Contacts” is limited to two per company space. Every “Digital Company Contact” will receive an email with information about the video chat functionality.</p>
</div>
<h5 id="digital-company-contact" class="accordion-title">
<span class="count">11.</span> I have been assigned “Digital Company Contact”. What do I need to do in order to set up this functionality in our Company Space? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial for "Digital Company Contacts"</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211102_Tutorial_digt%20Company%20Contact_IPSW_1.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="video-appointments-tutorial" class="accordion-title">
<span class="count">12.</span> How can I set up my calendar for video appointments? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>Please watch the tutorial for "Digital Company Contacts"</p>
<div class="video-box"><video controls="true"><source src="https://api.live-ipserviceworld.com/typo3conf/ext/tsbasetemplate/Resources/Public/Uploads/211102_Tutorial_digt%20Company%20Contact_IPSW_2.mp4" type="video/mp4" /></video></div>
</div>
<h5 id="" class="accordion-title">
<span class="count">13.</span> How do I visit “Leads &amp; Analytics”? <span class="icon-arrow-plus"></span>
</h5>
<div class="accordion-content">
<p>At the moment the platform is only accessible for exhibitors. Therefore, no leads can be generated and “Leads &amp; Analytics” is locked until the event starts.</p>
</div>
</div>
</div>

</div>
</section>
</main>
</div>
<script type="text/javascript">
{/* if(location.hash){
    $(location.hash).addClass('active')
} */}
</script>

<div class="overlay scroll-overlay" id="system-overlay">
<div class="item-box">
<button class="close close-overlay">
<svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg>
</button>
<div class="overlay-container">
</div>
</div>
</div>
  <Footer />
</div>
  )
}
