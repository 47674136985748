import React, { useContext, useState } from "react";
import overlayContext from "../Context/overlayContext";

import { doRequest } from "../utils/doRequest";



export default function Feedback() {
  const { showOverlay, setShowoverlay } = useContext(overlayContext);
  const [showSuccess , setShowSuccess ] = useState(false);
  const [ gender, setGender ] = useState('');
  const [ firstname ,setFirstName ] = useState('');
  const [ lastname ,setLastName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ phone, setPhone] = useState('');
  const [ message , setMessage ] = useState('');
  const [ privacyPolicy , setPrivacyPolicy ] = useState(false);
  const [ errors , setErrors ] = useState({
    firstname: false,
    lastname: false,
    email: false,
    // phone: false,
    message: false,
    privacyPolicy: false,
    firstTime: true
  });

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }
  

  function handleSubmit() {
    let clickerror = {
      firstname: (firstname.length == 0) ? true : false,
      lastname: (lastname.length == 0) ? true : false,
      email: (email.length == 0 || !validateEmail(email)) ? true : false,
      // phone: (phone.length == 0) ? true : false,
      message: (message.length == 0) ? true : false,
      privacyPolicy: !privacyPolicy,
      firstTime: false
    };
    setErrors(clickerror);

    let sercall = false;
    if ((errors.firstname == false && errors.lastname == false && errors.email == false && errors.message == false && errors.privacyPolicy == false)) {
      sercall = true;
    }
    if (sercall && errors.firstTime == false) {
      doRequest('Server', '/api/v1/Frontend/user/feedback','POST', 
      {
        "fields": {
            "gender" : gender,
            "firstname": firstname,
            "lastname": lastname,
            "email": email,
            "phone": phone,
            "message": message
        }
      }, true, (err, data) => {
        if(data.succ) {
          setShowSuccess(true);
          setTimeout(() => {
            setShowoverlay('');
          }, 3000);
          // toast.success('User Updated Successfully', {hideProgressBar: false  });
        }
      })
    }
  }

  if(showSuccess) {
    return (<>
    <section className="modul feedback-form pd-5">
      <div className="item-box">
        <div className="form-box">
            <h3 className="no-margin">Feedback Form</h3>
            <p>Thanks for your feedback</p>
            <p>This form will be auto closed..</p>
        </div>
      </div>
    </section>
    </>)
  }

  return (
    <section className="modul feedback-form pd-5">
      <div className="item-box">
        <div className="form-box">
            <h3 className="no-margin">Feedback Form</h3>
            <p>
              <strong>Contact Us.</strong>
            </p>
            <div className="form-row flex-box">
              <div className="field-item standard-radio">
                <input
                  type="radio"
                  id="male"
                  value="Mr"
                  checked={gender === 'Mr'}
                  onChange={(e) => setGender(e.target.value)}
                />
                <label className="radio-label" htmlFor="male">
                  Mr.
                </label>
              </div>
              <div className="field-item standard-radio">
                <input
                  type="radio"
                  id="female"
                  checked={gender === 'Mrs'}
                  onChange={(e) => setGender(e.target.value)}
                  value="Mrs"
                />
                <label className="radio-label" htmlFor="female">
                  Mrs.
                </label>
              </div>
            </div>
            <div className="form-row flex-box">
              <div className="field-item txt-left">
                <input
                  type="text"
                  id="firstname"
                  placeholder=" "
                  value={firstname}
                  className="bg-4"
                  onChange={(e) => {setFirstName(e.target.value); errors.firstname = (e.target.value == 0) ? true : false;setErrors(errors);}}
                />
                <label className="field-label" htmlFor="firstname">
                  * Firstname
                </label>
              </div>
              <div className="field-item txt-left">
                <input
                  type="text"
                  id="lastname"
                  placeholder=" "
                  value={lastname}
                  className="bg-4"
                  onChange={(e) => {setLastName(e.target.value); errors.lastname = (e.target.value == 0) ? true : false;setErrors(errors);}}
                />
                <label className="field-label" htmlFor="lastname">
                  * Lastname
                </label>
              </div>
            </div>
            <div className="form-row flex-box">
              <div className="field-item txt-left">
                {errors.firstname && <h5 className="cl-error no-margin">Please fill out this field</h5>}
              </div>
              <div className="field-item txt-left">
                {errors.lastname && <h5 className="cl-error">Please fill out this field</h5>}
              </div>
            </div>
            <div className="form-row flex-box">
              <div className="field-item txt-left">
                <input
                  type="email"
                  id="email"
                  placeholder=" "
                  value={email}
                  className="bg-4"
                  onChange={(e) => {setEmail(e.target.value); errors.email = (e.target.value == 0  || !validateEmail(e.target.value)) ? true : false;setErrors(errors);}}
                />
                <label className="field-label" htmlFor="email">
                  * E-Mail
                </label>
              </div>
              <div className="field-item txt-left">
                <input
                  type="text"
                  id="phone"
                  value={phone}
                  placeholder=" "
                  className="standard-field bg-4"
                  onChange={(e) => setPhone(e.target.value)}
                />
                <label className="field-label" htmlFor="phone">
                  Phone
                </label>
              </div>
            </div>
            <div className="form-row flex-box">
              <div className="field-item txt-left">
                {errors.email && <h5 className="cl-error no-margin">Please fill out this field with a valid email address</h5>}
              </div>
              <div className="field-item txt-left">
              </div>
            </div>
            <div className="form-row flex-box">
              <div className="field-item txt-left box-100">
                <textarea
                  placeholder="* Your message here"
                  className="standard-field"
                  id="message"
                  value={message}
                  onChange={(e) => {setMessage(e.target.value); errors.message = (e.target.value == 0) ? true : false;setErrors(errors);}}
                ></textarea>
                <label className="field-label" htmlFor="message">
                  * Your message here
                </label>
              </div>
            </div>
            <div className="form-row flex-box">
              <div className="field-item txt-left">
                {errors.message && <h5 className="cl-error no-margin">Please fill out this field</h5>}
              </div>
              <div className="field-item txt-left">
              </div>
            </div>
            <div className="form-row flex-box">
              <div className="field-item standard-checkbox box-100">
                <input
                  type="checkbox"
                  id="acknowledge"
                  className="feedback-checkbox"
                  name="tx_user_feedback[acknowledge]"
                  value="acknowledge"
                  onChange={(e) => {setPrivacyPolicy(e.target.checked); errors.privacyPolicy = !e.target.checked;setErrors(errors);}}
                />
                <label className="checkbox-label" htmlFor="acknowledge">
                  I agree to the terms of the{" "}
                  <a
                    href="https://www.ipserviceworld.com/data-privacy-statement/"
                    target="_blank"
                  >
                    <span>Data Privacy</span>
                  </a>
                  *
                </label>
              </div>
            </div>
            <div className="form-row flex-box">
              <div className="field-item txt-left">
                {errors.privacyPolicy && <h5 className="cl-error no-margin">Please check "Date Privacy" checkbox</h5>}
              </div>
              <div className="field-item txt-left">
              </div>
            </div>
            <div className="button-box txt-right">
              <button
                onClick={handleSubmit}
                className="button call-to-action"
              >
                <span className="button-label">Submit</span>
                <span className="icon-arrow-right"></span>
              </button>
            </div>
        </div>
      </div>
    </section>
  );
}
