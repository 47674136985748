import { Link } from "react-router-dom";

export default function Hero() {
    return (
		<section className="hero-content full-size flex-box stretch bg-4">
		<div className="bg-image live-header">
			<figure className="image-box">
				<img srcSet="/images/bg-live.jpg 760w, /images/bg-live.jpg 1080w" sizes="(max-width: 1600px) 100vw, 1600px" src="/images/bg-live.jpg" />
			</figure>
		</div>
			<div className="item-box live-header">
				<div className="content-box txt-center pd-12">
					{/* <figure className="image-box max-w">
						<img src="/images/intro-icon.png" />
					</figure> */}
					<h1 className="cl-white " style={{'fontSize': '4rem'}}>{process.env.REACT_APP_INTRO_TEXT}</h1>
					<h1 className="cl-white " style={{'fontSize': '4rem'}}>{process.env.REACT_APP_INTRO_TEXT_SUB}</h1>
					<h1 className="cl-white " style={{'fontSize': '4rem'}}>{process.env.REACT_APP_INTRO_TEXT_SUB_1}</h1>
					<h1 className="cl-white " style={{'fontSize': '4rem'}}>You are welcome to view the content of our partners until 2nd December</h1>
					
					<Link to={'/matchmaking'} className="button call-to-action matchmaking-link" >Join Now!</Link>
				</div>
			</div>
		</section>
    );
}