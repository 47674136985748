import React, { useContext, useEffect } from 'react'
import Footer from '../Components/Footer';
import Header from '../Components/Header'
import SmallHero from '../Components/SmallHero';
import Hero from '../Live/Hero';
import TabItem from './TabItem';
import CompaanyProfile from "./CompanyProfile";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../Context/AuthContext';

export default function ExhibitorEditor() {
  
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    document.body.classList.add('backend');
    document.body.classList.add('bd-exhibitor-editor');
        
    return () => {
      document.body.classList.remove('backend');
      document.body.classList.remove('bd-exhibitor-editor');
    }
  }, [])

  useEffect(() => {
    if (!authContext.isAuth ) { return (navigate("/user-login")); }    
  }, [authContext.isAuth])
  

  return (
    <div className="base-box fixed" id="page-top">
      <Header hideOptions={true} />
      <SmallHero />
      <main className="main-content">
        <div className="tx-exhibitor">
          <section className="modul backend-form bg-white">
            <TabItem />
          </section>
          <section className="modul backend-form bg-4 position-static">
            <CompaanyProfile />
          </section>
        </div>
      </main>
      <Footer />
    </div>
  )
}
