import * as React from "react";
import {
  Routes,
  Route,
  Outlet,
  BrowserRouter as Router,
} from "react-router-dom";

import AuthContextProvider, {AuthContext} from "./Context/AuthContext";
import BookmarkContextProvider,{BookmarkContext} from "./Context/BookmarkContext";
import LoadingContextProvider, { LoadingContext } from "./Context/LoadingContext";

import { OverlaysProvider } from "./Context/overlayContext";

import LivePage from "./Live/LivePage";
import ExhibitorLogin from "./Login/ExhibitorLogin";
import UserLogin from "./Login/UserLogin";
import UserEditor from "./UserEditor";
import ExhibitorEditor from "./ExhibitorEditor";

import EditorProfessional from './UserEditor/Professional';
import EditorExhibitor from './UserEditor/Exhibitor';
import EditorMatchmaking from './UserEditor/Matchmaking';
import EditorSummary from './UserEditor/Summary';


import Matchmaking from "./Matchmaking/Matchmaking";
import MyContacts from "./Matchmaking/MyContacts";
import Bookmarks from "./Matchmaking/Bookmarks";

import UserInfo from './Live/UserInfo';
import StreamVideo from "./Live/StreamVideo";
import CompanyDetail from "./Live/CompanyDetail";

import PublicPage from "./Login/PublicPage";
import Faq from "./Components/Faq"

import RegistrationPage from "./Login/RegistrationPage";
import Overlays from "./Components/Overlays";

import ProfileContextProvider from "./Context/ProfileContext";
import TopicsContextProvider from "./Context/TopicsContext";
import UnAuthorizedPage from "./Login/UnAuthorizedPage";

import Chat from "./Chat/Chat";
import WithoutLiveAccess from "./Login/WithoutLiveAccess";
import LeadStatistics from "./ExhibitorEditor/LeadStatistics";
import { BeatLoader, ClockLoader } from "react-spinners";
import SearchTopics from "./Live/SearchTopics";
import FeedbackPage from "./Components/FeedbackPage";


function Routers() {
  return (
    <div>
      <Routes>
        <Route element={<Layout />}>
          <Route exact path="/" element={<PublicPage />} />
          <Route exact path="/user-login" element={<UserLogin />} />
          <Route exact path="/exhibitor-login" element={<ExhibitorLogin />} />

          <Route exact path="/live" element={<LivePage />} />
          <Route exact path="/matchmaking" element={<Matchmaking />} />
          <Route exact path="/userinfo/:userId" element={<UserInfo />} />
          <Route exact path="/bookmarks" element={<Bookmarks />} />
          <Route exact path="/my-contacts" element={<MyContacts />} />
          <Route exact path="/company/:cmpName" element={<CompanyDetail />} />
          <Route exact path="/Chat" element={<Chat />} />
          <Route exact path="/stream-video" element={<StreamVideo />} />
          <Route exact path="/search-topics" element={<SearchTopics />} />
          
          <Route exact path="/exhibitor-editor" element={<ExhibitorEditor />} />
          <Route exact path="/lead-statistics" element={<LeadStatistics />} />
          <Route exact path="/exhibitor-preview/:cmpName" element={<CompanyDetail editOption={true} />} />

          <Route exact path="/user-editor" element={<UserEditor />} />
          <Route exact path="/user-editor/personal" element={<UserEditor />} />
          <Route exact path="/user-editor/professional" element={<EditorProfessional />} />
          <Route exact path="/user-editor/exhibitor" element={<EditorExhibitor />} />
          <Route exact path="/user-editor/matchmaking" element={<EditorMatchmaking />} />
          <Route exact path="/user-editor/summary" element={<EditorSummary />} />
          
          <Route exact path="/registration" element={<RegistrationPage />} />
          <Route exact path="/feedback" element={<FeedbackPage />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route path="/*" element={<UserLogin />} />

        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  const [showOverlay, setShowoverlay] = React.useState('');
  const [overlayData, setOverlayData] = React.useState('');
  const { loading } = React.useContext(LoadingContext);

  const style = { position: 'fixed',top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const containerStyles = { height: '100%', width: '100%', zIndex: 9999, position: "fixed", background: 'rgba(0,0,0,0.5)'};

  return (
    <>
        <OverlaysProvider value={{ showOverlay, setShowoverlay, overlayData, setOverlayData }}>
            {loading && <div style={containerStyles} className="loading"><BeatLoader color="#6cb5f9" style={style} /></div>}
            <Outlet />
          <Overlays />
        </OverlaysProvider>
    </>
  );
}

export default function App() {
  return (
    <ProfileContextProvider>
      <AuthContextProvider>
        <TopicsContextProvider>
          <BookmarkContextProvider>
            <LoadingContextProvider>
              <Router>
                <Routers />
              </Router>
            </LoadingContextProvider>
          </BookmarkContextProvider>
        </TopicsContextProvider>
      </AuthContextProvider>
    </ProfileContextProvider>
  );
}