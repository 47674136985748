import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import SmallHero from '../Components/SmallHero'
import { doRequest } from '../utils/doRequest'
import parse from "html-react-parser";
import $ from 'jquery'
import { LoadingContext } from '../Context/LoadingContext'
import { decryptedUserId } from '../utils'
import { MyAgendaProvider } from './Context/myAgendaContext'
import axios from 'axios'

export default function SearchTopics() {

    const { setLoading } = useContext(LoadingContext);
    const [userAgenda, setUserAgenda ] = useState([]);
    let [messageCount, setMessageCount] = useState(0);
    const [userId, setUserId] = useState(0);

    let decryptedData = '';

    if (localStorage.getItem('contextVar')) {
        var CryptoJS = require("crypto-js");
        var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
        var bytes  = CryptoJS.AES.decrypt(localStorage.getItem('contextVar')?.toString(), secretKey);
        if (bytes.toString(CryptoJS.enc.Utf8)) {
            decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            //console.log('decryptedData : '+ decryptedData)
        }
    }
    var chatUrl = process.env.REACT_APP_CHAT_ENDPOINT + "Chat.php";
    let getMessagesFromChat = function () {
        var currUser = $('#currUser').val();
        var myKeyVals = {
            fetchMessages : 1,
            to: currUser
        }

        var saveData = $.ajax({
            type: "POST",
            url: chatUrl,
            data: myKeyVals,
            dataType: "text",
            dataType : 'json',
            success: function(resultData){
                // console.log(resultData);
                let totalCount = resultData.filter(function(value) { return value.m_from == currUser && value.is_read == 0 }).length;

                // console.log('totalCount ' + totalCount )
                // if (parseInt(totalCount) > 0) {
                //     setMessageCount(totalCount)
                // }
                
                // if (typeof window.top.$ === 'function') {
                    let p = window.top;
                    $('.matchmaking-counter', window.parent.document).html(totalCount);
                    let currentRequests = parseInt($('#currentRequests', window.parent.document).val());

                    if (totalCount > currentRequests && $('.search-notification').val() == '') {
                        $('#currentRequests', window.parent.document).val(totalCount);
                        setMessageCount(totalCount)

                        // if($("#notification-detial").css('display') == 'none') {
                        //     p.$('.chat-notifications #overlay-iframe').attr('src', p.$('.chat-notifications #overlay-iframe').attr('src'));
                        // }

                    }

                // }
                //  console.log('message Count ' + messageCount );


                $('#notification-results .notification-item').each(function() {
                    var to = $(this).attr('data-to');
                    var from = $(this).attr('data-from');

                    var length = resultData.filter(function(value) { return value.m_from == to && value.m_to == from && value.is_read == 0 }).length

                    $(this).find('.button-count').html(length>0?length:'');
                    var allMessages = resultData.filter(function(value) {
                            //return value.m_from == to && value.is_read == 0
                            return (value.m_from == to && value.m_to == from) || (value.m_from == from && value.m_to == to)
                    });

                    var time = 0;
                    var message = '';
                    var msgTime = '';
                        if (allMessages.length) {
                        var message = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['message'];

                        var time = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['tstamp'];


                        var msgTime = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['time'];
                    }


                    

                    $(this).find('.button-box').html(message);
                    $(this).find('.time-box').html(msgTime);
                    $(this).attr('data-time', time);
                });

                var sortedHtml = $('#notification-results .notification-item')
                .sort((a,b) => $(b).data("time") - $(a).data("time"))
                $("#notification-results").html(sortedHtml)
            }
        });
    }

    useEffect(() => {
        document.body.classList.add("live");
        document.body.classList.add("bd-user-profile");

        let isSubscribed = true;
        if (isSubscribed) {
            setUserId(decryptedUserId());
            getMessagesFromChat();
            setInterval(getMessagesFromChat, 5000);
        }
      
        return () => {
          document.body.classList.remove("live");
          document.body.classList.remove("bd-user-profile");
          isSubscribed = false;
        }
      }, [])

    useEffect(() => {
        if (userId) {
            axios
            .get( process.env.REACT_APP_ENDPOINT + "lib/storage/users/" + userId + "/myagenda.json", {
                headers: {
                Authorization: "Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh",
                },
                auth: {
                username: "cuatconference",
                password: "relaunch",
                },
                params: {
                v: process.env.REACT_APP_V,
                },
            })
            .then((res) => {
                if (res.data.eventID) {
                setUserAgenda(res.data.eventID);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }, [userId]);
    
    const {state} = useLocation();
    const [news, setNews] = useState([]);
    const [isUpdate, setIsUpdate] = useState(false);
    // const topicsIds = JSON.parse(localStorage.getItem('searchTopicKey')) || []; 
    const [topicsIds, setTopicsIds] = useState([]);
    const topicsNames = JSON.parse(localStorage.getItem('searchTopicName')) || [];

    function getSearchTopicKey() {
        return JSON.parse(localStorage.getItem('searchTopicKey')) || []; 
    }

    // useEffect(() => {
    //     function handleChangeStorage() {
    //         setIsUpdate(!isUpdate)
    //         setTopicsIds(getSearchTopicKey());
    //     }
    
    //     window.addEventListener('storage', handleChangeStorage);
    //     return () => window.removeEventListener('storage', handleChangeStorage);
    // }, []);

    useEffect(()=>{
        setIsUpdate(!isUpdate)
        setTopicsIds(getSearchTopicKey());
        // console.log("changed localStorage",window.localStorage.getItem("searchTopicKey"))

    },[window.localStorage.getItem("searchTopicKey")])

      
    useEffect(() => {
        console.log("changed")
		let isSubscribed = true;
      setLoading(true);
      doRequest(
        "React",
        "/lib/storage/content/news.json",
        "GET",
        {},
        true,
        (err, data) => {
          setLoading(false)
          if (isSubscribed) {
            const selectedNews = new Set();
            console.log(topicsIds);
            data.ourjsonContents.map((news) => {
                topicsIds.map((id) => {
                    if(news.expertisesUids.includes(parseInt(id))) {
                        selectedNews.add(news);
                    }
                })
            })
            setNews(selectedNews);
          }
        }
		);

        // setTimeout(() => {
        //     $('.masonry').isotope( 'reloadItems' ).isotope();
        // }, 1000);
	
		return () => {
		  isSubscribed = false;
		};
	}, [topicsIds,isUpdate]);

    useEffect(() => {
        $('.masonry').isotope( 'reloadItems' ).isotope();
    }, [news])
    

    function Article ({item}) {
        return (
            <div className="masonry-item narrow-item company-live-ajax bg-10" >
                <div className="box-title">
                    <Link to={`/company/${item.company.uid}`} className="button">
                        <span className="button-label">Article</span>
                        <span className="icon-box read"><i className="icon"></i></span>
                        <input
                            type="hidden"
                            className="activity-title"
                            value="{item.title}"
                        />
                    </Link>
                </div>
                <figure className="image-box"></figure>
                <div className="text-box">
                    <h4>{item.title}</h4>
                    {parse(item.articleintro)}
                </div>
                <div className="product-overview-box">
                    {item.expertise && item.expertise.map((topic, index) => (
                        <Fragment key={index}><span> # {topic}</span> <br /></Fragment>
                    ) )}
                </div>
            </div>
        )
    }

    function WhitePapper ({item}) {
        return (
            <div className={`flex-item masonry-item narrow-item bg-9 ${item.class} `} >
                <div className="box-title">
                    <Link to={`/company/${item.company.uid}`} className="button whitepaper-opener link">
                        <span className="button-label">Whitepaper Brochures
                        </span>
                        <span className="icon-box whitepaper"><i className="icon"></i>
                        </span>
                        <input
                            type="hidden"
                            className="activity-title"
                            value="Dummy whitepaper"
                        />
                    </Link>
                </div>

                <div className="text-box">
                    <h4>{item.pdftitle}</h4>
                    {item.pdfdescription && parse(item.pdfdescription)}
                </div>

                <div className="product-overview-box">
                    {item.expertise && item.expertise.map((topic, index) => (
                        <Fragment key={index} ><span key={index}> # {topic} </span> <br /></Fragment>
                    ))}
                </div>
            </div>
        )
    }

    function NewsItem({item}) {

        if(item.type === 'whitepaper')
            return <WhitePapper key={Math.random()} item={item} />;
        
        if(item.type === 'article')
            return <Article key={Math.random()} item={item} />;


        return( <h1>Loading....</h1> )
    }

  return (
    <div className="base-box fixed" id="page-top">
    <MyAgendaProvider value={{ userAgenda, setUserAgenda }}>
        <input type="hidden" id="currUser" value={decryptedData} />
        <Header />
        <div className="base-item cf">
        <SmallHero />

        <main className="main-content">
                <section className="modul news-masonry-box pd-4 bg-white" id="search-result">
                    <div className="item-box">
                        <h5 className="txt-uppercase">Search Result</h5>
                        <p id="topicSearched">Topic Searched : 
                            {topicsNames && Array.from(topicsNames).map((topicItem, index) => (
                                <span>{topicItem}{Array.from(topicsNames).length !== index+1 ? ', ' : ''}</span>
                            ))}
                        </p>
                    <div id="newsroom-container">
                    <div className="masonry flex-box wrap cl-white">
                        {news && Array.from(news).map((item, index) => (
                                <NewsItem key={'random'+ index} item={item} />
                        ))}
                    </div>
                </div>
                </div>
                </section>
        </main>
        </div>
        <Footer />
    </MyAgendaProvider>
  </div>
  )
}
