import { Link } from "react-router-dom";
import { isLogin } from "../utils";

export default function Footer ({isExpended}) {
    return (
        <footer id="colophon" className="site-footer clear pd-3 1" role="contentinfo">
            {isExpended ? (
                <>
                <a href="#page-top" className="totop"><span className="icon-box arrow"><i className="icon"></i></span></a>
                <section className="footer-meta-box bg-1">
                    <div className="bg-image">
                        <figure className="image-box">
                            <img srcSet="/images/footer-bg.jpg 760w, /images/footer-bg.jpg 1080w" sizes="(max-width: 1600px) 100vw, 1600px" src="/images/footer-bg.jpg" />
                        </figure>
                    </div>
                    <div className="item-box pd-b5 pd-t8">
                        <div className="flex-box top-right footer-content-box">
                            <div className="flex-item">
                                <p><strong>Organizer</strong></p>
                                <img src="/images/management-circle-logo.svg" />
                                <p>Education for the best. Since 1989, executives from all areas of the company have placed their trust in the high standard of our seminars, conferences and congresses. Benefit from an optimal connection of knowledge transfer and networking.</p>
                            </div>
                        </div>
                        <div className="social_media-box flex-box">
                            <a href="https://www.linkedin.com/showcase/ip-service-world" className="linkedin" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#ffffff"><path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zM8.951 9.404H6.165V17.5H8.95V9.404zm6.841-.192c-1.324 0-1.993.629-2.385 1.156l-.127.181V9.403h-2.786l.01.484c.006.636.007 1.748.005 2.93l-.015 4.683h2.786v-4.522c0-.242.018-.484.092-.657.202-.483.66-.984 1.43-.984.955 0 1.367.666 1.408 1.662l.003.168V17.5H19v-4.643c0-2.487-1.375-3.645-3.208-3.645zM7.576 5.5C6.623 5.5 6 6.105 6 6.899c0 .73.536 1.325 1.378 1.392l.18.006c.971 0 1.577-.621 1.577-1.398C9.116 6.105 8.53 5.5 7.576 5.5z"></path></svg>
                            </a>
                        </div>
                    </div>
                </section>
                </>
            ) : ''}
            <section className="footer-box">
                <div className="item-box flex-box">
                    <span className="copyright">© Management Circle AG 2017 - <script type="text/javascript">document.write(new Date().getFullYear());</script>2022</span>
                    <nav className="footer-nav">
                        {!isLogin() ? <Link to="/exhibitor-login"><span>Exhibitor Login</span></Link> : '' }
                        <a href="https://jitsi.live-ipserviceworld.com/system-check/" target="_blank"><span>System Check</span></a>
                        <a href="https://www.ipserviceworld.com/legal-notice/" target="_blank"><span>Legal Notice</span></a>
                        <a href="https://www.ipserviceworld.com/data-privacy-statement/" target="_blank"><span>Data Privacy</span></a>
                        <Link to="/faq" target="_blank"><span>FAQ</span></Link>
                    </nav>
                </div>
            </section>
        </footer>
    )
}