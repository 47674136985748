import React, {createContext, useEffect, useState} from "react";


export const BookmarkContext = createContext({
    bookmark: [],
    addBookmark: () => {},
    removeBookmark: () => {}
});



const BookmarkContextProvider = props => {
    const [bookmark, setBookmark] = useState([]);

    return (
        <BookmarkContext.Provider value={{bookmark: bookmark, setBookmark: setBookmark}}>
            {props.children}
        </BookmarkContext.Provider>
    )
}

export default BookmarkContextProvider;