import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Header from "../Components/Header";
import StickyLinks from "./StickyLinks";
import { doRequest } from "../utils/doRequest";
import { AuthContext } from "../Context/AuthContext";
import $ from 'jquery';
import { cryptedUserId, decryptedUserId, getCookie } from "../utils";
import overlayContext from "../Context/overlayContext";
import NextLiveStreamUpOnStage from './NextLiveStreamUpOnStage';
import { MyAgendaProvider } from "./Context/myAgendaContext";

export default function CompanyDetail({editOption}) {
  const [userAgenda, setUserAgenda ] = useState([]);
  let [messageCount, setMessageCount] = useState(0);
  const [userId, setUserId] = useState(0);

  let decryptedData = '';

  if (localStorage.getItem('contextVar')) {
      var CryptoJS = require("crypto-js");
      var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
      var bytes  = CryptoJS.AES.decrypt(localStorage.getItem('contextVar')?.toString(), secretKey);
      if (bytes.toString(CryptoJS.enc.Utf8)) {
          decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          //console.log('decryptedData : '+ decryptedData)
      }
  }
  var chatUrl = process.env.REACT_APP_CHAT_ENDPOINT + "Chat.php";
  let getMessagesFromChat = function () {
    var currUser = $('#currUser').val();
    var myKeyVals = {
        fetchMessages : 1,
        to: currUser
    }

    var saveData = $.ajax({
        type: "POST",
        url: chatUrl,
        data: myKeyVals,
        dataType: "text",
        dataType : 'json',
        success: function(resultData){
            // console.log(resultData);
            let totalCount = resultData.filter(function(value) { return value.m_from == currUser && value.is_read == 0 }).length;

            // console.log('totalCount ' + totalCount )
            // if (parseInt(totalCount) > 0) {
            //     setMessageCount(totalCount)
            // }
            
            // if (typeof window.top.$ === 'function') {
                let p = window.top;
                $('.matchmaking-counter', window.parent.document).html(totalCount);
                let currentRequests = parseInt($('#currentRequests', window.parent.document).val());

                if (totalCount > currentRequests && $('.search-notification').val() == '') {
                    $('#currentRequests', window.parent.document).val(totalCount);
                    setMessageCount(totalCount)

                    // if($("#notification-detial").css('display') == 'none') {
                    //     p.$('.chat-notifications #overlay-iframe').attr('src', p.$('.chat-notifications #overlay-iframe').attr('src'));
                    // }

                }

            // }
            //  console.log('message Count ' + messageCount );


            $('#notification-results .notification-item').each(function() {
                var to = $(this).attr('data-to');
                var from = $(this).attr('data-from');

                var length = resultData.filter(function(value) { return value.m_from == to && value.m_to == from && value.is_read == 0 }).length

                $(this).find('.button-count').html(length>0?length:'');
                var allMessages = resultData.filter(function(value) {
                        //return value.m_from == to && value.is_read == 0
                        return (value.m_from == to && value.m_to == from) || (value.m_from == from && value.m_to == to)
                });

                var time = 0;
                var message = '';
                var msgTime = '';
                    if (allMessages.length) {
                    var message = allMessages.sort(
                        function(a, b) {
                            return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                        }
                    )[0]['message'];

                    var time = allMessages.sort(
                        function(a, b) {
                            return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                        }
                    )[0]['tstamp'];


                    var msgTime = allMessages.sort(
                        function(a, b) {
                            return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                        }
                    )[0]['time'];
                }


                

                $(this).find('.button-box').html(message);
                $(this).find('.time-box').html(msgTime);
                $(this).attr('data-time', time);
            });

            var sortedHtml = $('#notification-results .notification-item')
            .sort((a,b) => $(b).data("time") - $(a).data("time"))
            $("#notification-results").html(sortedHtml)
        }
    });
  }

  function ShowTime({time}) {
    var t = new Date(time * 1000);
    var formatted = ('0' + t.getHours()).slice(-2) + ':' + ('0' + t.getMinutes()).slice(-2);
    return (formatted);
  }
  
  function jumpToSection(section) {
    let getIdOf = section.split('#')[1];
    console.log('section: '+section);
    console.log('getidof: '+getIdOf);
    let sectionElem = document.getElementById(getIdOf);
    console.log('id to jump: '+sectionElem);

    if (sectionElem) {
      console.log('jump to: '+sectionElem);
        sectionElem.scrollIntoView();
    }
  }

  const { cmpName } = useParams();
  const authContext = useContext(AuthContext);
  const { setShowoverlay, setOverlayData, overlayData } = useContext(overlayContext);

  const [companyDetails, setCompanyDetail] = useState();
  const navigate = useNavigate();

  const [showmoreArticle ,setShowmoreArticle ] = useState(false);

  useEffect(() => {
    document.body.classList.add('live');
    document.body.classList.add('bd-company');

    let isSubscribed = true;
    if (isSubscribed) {
        setUserId(decryptedUserId());
        getMessagesFromChat();
        setInterval(getMessagesFromChat, 5000);
    }

    const hash = window.location.hash;
    console.log('hash: '+hash);
    if(hash) {
      jumpToSection(hash);
    }

    if(window.location.hash){
      $(window.location.hash).addClass('active')
    }

    doRequest('React', 'lib/storage/company/'+ cmpName +'.json', 'GET', {}, true, (err,data) => {
      if(err) {
        console.log('error getting company detail data:');
        console.log(err);
      }
      console.log('company data:');
      console.log(data);
      setCompanyDetail(data)
      $('.masonry').isotope( 'reloadItems' ).isotope();
    })

    return () => {
      document.body.classList.remove('live');
      document.body.classList.remove('bd-company');
      isSubscribed = false;
    }  

  }, [])

  useEffect(() => {
    if (userId) {
      axios
        .get( process.env.REACT_APP_ENDPOINT + "lib/storage/users/" + userId + "/myagenda.json", {
          headers: {
            Authorization: "Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh",
          },
          auth: {
            username: "cuatconference",
            password: "relaunch",
          },
          params: {
            v: process.env.REACT_APP_V,
          },
        })
        .then((res) => {
          if (res.data.eventID) {
            setUserAgenda(res.data.eventID);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId]);

  useEffect(() => {
    $('.masonry').isotope( 'reloadItems' ).isotope();
  });

  useEffect(() => {
    // if (!authContext.isAuth ) { return (navigate("/user-login")); }   
  }, [true])

  useEffect(() => {

    if(overlayData.whitepaper == true) {
      reportAnalyticsOfarticle(overlayData.company, overlayData.item.type, overlayData.item.pdftitle, 0 );
      doRequest('typeNum', '/?type=234234' , 'typeNum-POST', {
            company : overlayData.company,
            number: overlayData.item.number,
            datatype: overlayData.item.type
      },'', (err, data) => {
        $('#'+overlayData.item.type+'-overlay').html(data);
        $('#'+overlayData.item.type+'-overlay').addClass('active');
        $('html').addClass('overlay-active');
        $(document).on('click', '.close-overlay', function(e){
          $(this).parents('.overlay').eq(0).removeClass('active');
          $('html').removeClass('overlay-active');
          setOverlayData({'whitepaper': 'false'});
        });
      })
    }

    if (overlayData.article == true) {
      doRequest('typeNum', '/?type=1625139860' , 'typeNum-GET', {
        cryptedUserId : cryptedUserId(),
        company_id : overlayData.company_id,
        activity: overlayData.activity,
        title: overlayData.title,
        eaagent: overlayData.eaagent
      },'', (err, data) => {
        
        setShowmoreArticle(true);
    
      })
    }

  },[overlayData]);

  

 
  function Whitepaper({item}) {
    return (
        <div className={`narrow-item cl-white p-2 bg-10 ${item.class}`} onClick={() => sendAnalytics(cmpName,item)} >

              <div className="box-title">
                  <button className="button whitepaper-opener" data-id="26" data-number="2" data-type="whitepaper">
                    <span>Whitepaper Brochures</span>
                    <input type="hidden" className="activity-title" value="Der Hybrid Seller hilft in dem Markenartikler" />
                  </button>
              </div>
              

              <div className="text-box">
                    <h4>{item.pdftitle}</h4>
                    {item.pdfdescription && parse(item.pdfdescription)}
              </div>
              {console.log(item)}
              <div className="product-overview-box">
                <ul className="product-list">
                  {item.pdftopic.length ? item.pdftopic.map((topic, index) => (
                        <li>{topic}</li>
                  )) : ''}
                  
                </ul>
              </div>
      </div>
    )
  }

  function reportAnalyticsOfarticle(company_id, activity, title,eaagent) {
    if(getCookie('consent') != 1){
      setOverlayData({'check': 'article' , 'company_id': company_id , 'activity': activity, 'title': title, 'eaagent': eaagent });
      setShowoverlay('Conset');
    } else {
      setShowmoreArticle(false)
      setOverlayData({'article': true , 'company_id': company_id , 'activity': activity, 'title': title, 'eaagent': eaagent });
    }
  }
  
  function sendAnalytics(company,item) {
    console.log('Report analytics');
    if(getCookie('consent') != 1){
      setOverlayData({'check': 'whitepaper' , 'company': company , 'item': item });
      setShowoverlay('Conset');
    } else {
      setOverlayData({'whitepaper': true , 'company': company , 'item': item });
    }
  }

  return (
    <>
        <main className="main-content">
        <MyAgendaProvider value={{ userAgenda, setUserAgenda }}>
          <input type="hidden" id="currUser" value={decryptedData} />
          <Header />
          <StickyLinks />

          {companyDetails && (
          <section id="matchPlaceHolder" className="company-page">
            <div className="tx-exhibitor">
              <div className="base-item company-page" data-company-id="27">
                <section className="hero-content flex-box stretch bg-4">
                  <div className="bg-image">
                    <figure className="image-box">
                      {(companyDetails.company.headerimage) ? (
                        <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + companyDetails.company.headerimage} />
                      ):(
                        <img src={process.env.REACT_APP_COMPANY_HEADER_DEFAULT_IMAGE} />
                      )}
                    </figure>
                  </div>
                  <div className="item-box flex-box bottom-left">
                    {editOption && <Link className="button" to={'/exhibitor-editor'} >Back to Editor</Link>}
                    <div className="flex-box intro-box bg-black cl-white">
                      <div className={`member-badge txt-uppercase cl-white platin-partner`}>
                        <strong>{companyDetails?.company?.additionalcategory}</strong>
                        {/* <span className={`icon-box ${companyDetails.company.category}`}>
                          <i className="icon"></i>
                        </span> */}
                      </div>
                      <figure className="image-box">
                        {(companyDetails.company.logo) ? (
                          <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + companyDetails.company.logo} />
                        ): (
                          <img src='/images/missing-logo.png' />
                        )}
                      </figure>
                      <div className="text-box">
                        <p className="no-margin">
                          <strong>{companyDetails.company.companyname}</strong>
                          <br />
                          {companyDetails.company.companyaddress}
                          <br />
                          {companyDetails.company.companypostal} {companyDetails.company.companycity}
                          <br />
                          {companyDetails.company.companycountry}
                          <br />
                          <a href={`tel:${companyDetails.company.companyphone}`}>{companyDetails.company.companyphone}</a>
                          <br />
                          <a href={companyDetails.company.companywebsite} target="_new">
                          {companyDetails.company.companywebsite}
                          </a>
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
                <main className="main-content">
                {companyDetails.expertisesAmount > 0 ? (
                    <section className="modul product-categories-box pd-t8 pd-b4">
                      <div className="item-box">
                        <ul className="product-range flex-box wrap center-center">
                          {companyDetails.expertises.map((admin, index) => (
                            admin.checked === true ? <li>{admin.name}</li> : ''
                          ))}
                        </ul>
                      </div>
                    </section>
                  ):''}

                  {companyDetails.company.aboutcompany && companyDetails.company.aboutcompany.length ? (
                    <section className="modul product-categories-box pd-4">
                      <div className="item-box txt-center small-box">
                        <div className="text-box">
                            {parse(companyDetails.company.aboutcompany)}
                        </div>
                      </div>
                    </section>
                  ):''}

                  {companyDetails.company.videochatagent && companyDetails.company.videochatagent.length ? (
                    <section className="modul staff-box pd-4 bg-black">
                      <div className="item-box cl-white">
                        <h5 className="txt-uppercase">Get in touch with our staff</h5>
                        <div className="col-box col-2">
                            {companyDetails.company.videochatagent.slice(0, 2).map((admin, index) => (
                              <div className="col-item flex-box bg-9">
                                <figure className="image-box">
                                  {admin.image ? (
                                    <img src={`${process.env.REACT_APP_SERVER_ENDPOINT_TYPO3}${admin.image}`} />
                                  ) : (
                                    <img src="/images/icon-user.svg" />
                                  ) }
                                </figure>
                                <div className="flex-item">
                                  <div className="text-box flex-box flex-column">
                                    <h5 className="no-maring">{admin.firstName}&nbsp;<br />{admin.lastName}</h5>
                                    <p className="no-margin">{admin.title}</p>
                                    {admin.description ? parse(admin.description): ''}
                                    {admin.activatematch ? (
                                      <Link className="button show-profile" to={`/userinfo/${admin.uid}`} >
                                        <span className="button-label">Get in touch</span><span className="icon-box"><i className="icon"></i></span>
                                      </Link>
                                    ) : ''}
                                    
                                    {/* <a data-id="14" className="button show-profile" href="/user-info?tx_user_userinfo%5Buser%5D=14&amp;cHash=6a429fbc2851003900a00c63932133e4"></a> */}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </section>
                  ):''}
                  
                  {companyDetails.company.articletitle && companyDetails.company.articletitle.length ? (
                    <section id="articlecontent" className="modul article-content-box pd-4 bg-9">
                      <div className={`item-box ${showmoreArticle? 'active' : ''} `}>
                        <div className="col-box col-2">
                          {companyDetails.company.articleimage && (
                            <div className="col-item">
                              <figure className="image-box">
                                <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + companyDetails.company.articleimage} />
                              </figure>
                            </div>
                          )}
                        
                        <div className="col-item">
                          <div className="text-box cl-white">
                            <h3>{companyDetails.company.articletitle}</h3>
                            {parse(companyDetails.company.articleintro)}
                            <br />
                            <button className="button">
                              {showmoreArticle ? (
                                <strong className="close" onClick={() => setShowmoreArticle(false)}>show less...</strong>
                                ) : (
                                <strong 
                                onClick={() => reportAnalyticsOfarticle(cmpName,'article',companyDetails?.company?.articletitle, 0 )} 
                                className="open">show more...</strong>
                              )}
                              <span className="icon-box">
                                <i className="icon"></i>
                              </span>
                            </button>
                          </div>
                          </div>
                        </div>
                        {showmoreArticle ? (
                          <div className="exhibitor-content">
                            {parse(companyDetails.company.articledescription)}
                          </div>) : ''}
                      </div>
                    </section>
                  ):''}
                  
                  {/* {companyDetails.livestreams ? (
                  <>
                  <section class="modul live-stream-box pd-5  bg-4">
                    <div className="item-box">
                      <h5 class="txt-uppercase">Live Performance</h5>
                      <div class="col-box col-3 ls-teaser-box cl-white">
                      {companyDetails.livestreams.map((stream, index) => (
                        <>
                        <div class="col-item tradeshow cl-white">
                          <div class="content-box">
                          <p>
                          {stream.stage == 1 && (
                            <strong>Munich</strong>
                          )}
                          
                          {stream.stage == 2 && (
                            <strong>Paris</strong>
                          )}

                          {stream.stage == 3 && (
                            <strong>London</strong>
                          )}
                          </p>
                          <h3><ShowTime time={stream.starttimestamp} /></h3>
                          <p><strong>{stream.title}</strong></p>
                          
                            <p class="no-margin">
                              <strong>
                                {stream.speakers.map((speak) => (<>{speak.firstname}{speak.firstname ? ',': '' } {speak.lastname} {speak.position}</>))}
                            <br/>
                            </strong>
                            </p>

                          <p>{parse(stream.description)}</p>

                          </div>
                        </div>
                        </>
                        ))}
                      </div>
                    </div>
                  </section>
                  </>
                ) : (
                  ''
                )
                } */}

                  {companyDetails.ourContent ? (
                    <section id="newscontent" className="modul exhibitor-masonry-box pd-4 bg-black">
                      <div className="item-box">
                        <h5 className="txt-uppercase cl-white">Our Content</h5>
                          <div className="masonry">
                            {companyDetails.ourContent.map((content) => {
                              if (content.type == 'whitepaper') {
                                return <Whitepaper item={content} />;
                              }
                            })}
                          </div>
                      </div>
                    </section>
                  ):''}
                </main>
              </div>

              <footer
                id="colophon"
                className="site-footer clear bg-black"
                role="contentinfo"
              >
                <a href="#page-top" className="totop">
                  <span className="icon-box arrow">
                    <i className="icon"></i>
                  </span>
                </a>
                <section className="footer-meta-box bg-1">
                  <div className="bg-image">
                    <figure className="image-box">
                      {(companyDetails.company.headerimage) ? (
                        <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + companyDetails.company.headerimage} />
                      ):(
                        <img src={process.env.REACT_APP_COMPANY_FOOTER_DEFAULT_IMAGE} />
                      )}  
                    </figure>
                  </div>
                  <div className="item-box pd-b5 pd-t8">
                    <div className="flex-box top-left footer-content-box">
                      <div className="flex-item box-40">
                        <p className="no-margin cl-white">
                          <strong>{companyDetails.company.companyname}</strong>
                          <br />
                          {companyDetails.company.companyaddress}
                          <br />
                          {companyDetails.company.companypostal} {companyDetails.company.companycity}
                          <br />
                          {companyDetails.company.companycountry}
                          <br />
                          <a href={`tel:${companyDetails.company.companyphone}`}>{companyDetails.company.companyphone}</a>
                          <br />
                          <a href={companyDetails.company.companywebsite} target="_new">
                          {companyDetails.company.companywebsite}
                          </a>
                          <br />
                        </p>
                        <nav className="footer-nav company-nav">
                          <a target="_blank" href="https://system-check.live-ipserviceworld.com/">
                            <span>System Check</span>
                          </a>
                          {console.log(companyDetails.company)}
                          {companyDetails.company.companyimpressumlink ? (<a target="_blank" href={companyDetails.company.companyimpressumlink}>
                            <span>Legal Notice</span>
                          </a>) : ''}
                          
                          <a href="https://www.ipserviceworld.com/data-privacy-statement/" target="_blank">
                            <span>Data Privacy</span>
                          </a>
                        </nav>
                      </div>
                      <div className="flex-item box-60">
                        <div className="iframe-box">
                          <iframe
                            src={companyDetails.company.googlemaplink}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                            width="600"
                            height="450"
                            frameBorder="0"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </footer>

              <div
                className="overlay flex-box center-center live-stream"
                id="live-stream"
              >
                <div className="item-box tab-box">
                  <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
                    <span className="icon-box shrink">
                      <i className="icon"></i>
                    </span>
                    <span className="icon-box enlarge">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button className="close close-overlay" id="close-stream-box">
                    <span className="icon-box close">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button className="position-button position-top" id="position-top">
                    <span className="icon-box arrow">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button
                    className="position-button position-bottom"
                    id="position-bottom"
                  >
                    <span className="icon-box arrow">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button className="position-button position-left" id="position-left">
                    <span className="icon-box arrow">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button
                    className="position-button position-right"
                    id="position-right"
                  >
                    <span className="icon-box arrow">
                      <i className="icon"></i>
                    </span>
                  </button>

                  <div className="overlay-container pd-8">
                    <div className="iframe-box">
                      {/* <iframe
                        id="overlay-iframe"
                        scrolling="no"
                        src="/stream-video"
                        frameborder="0"
                      ></iframe> */}
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="overlay flex-box center-center stream-archive"
                id="stream-archive"
              >
                <div className="item-box">
                  <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
                    <span className="icon-box shrink">
                      <i className="icon"></i>
                    </span>
                    <span className="icon-box enlarge">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button className="close close-overlay" id="close-stream-box">
                    <span className="icon-box close">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button className="position-button position-top" id="position-top">
                    <span className="icon-box arrow">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button
                    className="position-button position-bottom"
                    id="position-bottom"
                  >
                    <span className="icon-box arrow">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button className="position-button position-left" id="position-left">
                    <span className="icon-box arrow">
                      <i className="icon"></i>
                    </span>
                  </button>
                  <button
                    className="position-button position-right"
                    id="position-right"
                  >
                    <span className="icon-box arrow">
                      <i className="icon"></i>
                    </span>
                  </button>

                  <div className="overlay-container pd-8">
                    <div className="iframe-box">
                      {/* <iframe
                        id="overlay-iframe"
                        scrolling="no"
                        src="/stream-video"
                        frameborder="0"
                        width="100%"
                        height="100%"
                      ></iframe> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overlay scroll-overlay" id="whitepaper-overlay"></div>
          </section>
          )}
        </MyAgendaProvider>
        </main>
    </>
  );
}
