import React, { useContext, useEffect, useState } from "react";
import SmallHero from "../Components/SmallHero";
import StepNavigation from "./StepNavigation";
import { ProfileContext } from "../Context/ProfileContext";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { doRequest } from "../utils/doRequest";
import { cryptedUserId, decryptedUserId } from "../utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { LoadingContext } from "../Context/LoadingContext";
import { AuthContext } from "../Context/AuthContext";

const editorConfiguration = {
	toolbar: [
	  "bold",
	  // 'italic'
	],
  };

export default function Matchmaking() {
  const authContext = useContext(AuthContext);

  const { setLoading } = useContext(LoadingContext);
  const { profile } = useContext(ProfileContext);

	const [activateMatchmaking, setActivateMatchmaking ] = useState(false);
	const [description, setDescription] = useState("");
  const [userImage, setUserImage] = useState();
	const [showMenu, setShowMenu] = useState('');
  const [showphone, setShowPhone] = useState();
  const [showemail, setShowMail] = useState();
  const navigate = useNavigate();
  

	const [matchQuestions, setMatchQuestions] = useState([]);
  const [dataTitle, setDataTitle] = useState('');
  const [selectedFile, setSelectedFile] = React.useState(null);

  
  useEffect(() => {
    document.body.classList.add("backend");
    document.body.classList.add("bd-user-profile");
  
    return () => {
      document.body.classList.remove("backend");
      document.body.classList.remove("bd-user-profile");
    }
  }, [])
  
  useEffect(() => {
    if (!authContext.isAuth ) { return (navigate("/user-login")); }    
  }, [authContext.isAuth])

  useEffect(() => {
    if(profile.activatematch || profile.description || profile.image ) {
      setActivateMatchmaking(profile.activatematch);
      setDescription(profile.description);
      setUserImage(profile.image);
      setShowPhone(profile.showphone);
      setShowMail(profile.showemail); 
    }
  },[profile])

  const [ enableExhibitortab , setEnableExhibitortab ] = useState(false);

  useEffect(() => {
    if(profile.usergroup) {
      let usrgrp = profile.usergroup.split(',');
      if(usrgrp.includes('3') || usrgrp.includes('1')){
        setEnableExhibitortab(true)
      }
    }
    
  }, [])

  
	useEffect(() => {
		let isSubscribed = true;
      setLoading(true);
      doRequest(
        "React",
        "/lib/storage/users/"+ decryptedUserId() +"/matchQuestions.json",
        "GET",
        {},
        true,
        (err, data) => {
          setLoading(false)
          if (isSubscribed) {
            setMatchQuestions(data);
          }
        }
		);
	
		return () => {
		  isSubscribed = false;
		};
	  }, []);

	function toggle(value) {
		if (showMenu != value) {
		  setShowMenu(value);
		} else {
		  setShowMenu("");
		}
	}

  function handleFileInputChagne(e) {
    e.preventDefault();
    setSelectedFile(e.target.files[0]);
    setDataTitle("Dateiname: " + e.target.value.split( '\\' ).pop())
  }

  function handleQuestion(e) {
    
    const newState = matchQuestions.map((question,queIndex) => {
      let answers = Object.values(question.answers).map((answer) => { 
        if(parseInt(question.uid) == parseInt(e.target.getAttribute('question'))) {
          if(e.target.value.split('-')[1] == '0' && answer.uid != e.target.value && e.target.checked ) {
            return { ...answer , checked: false }; 
          }
        }
        
        if(answer.uid == e.target.value) {
            return { ...answer, checked : e.target.checked };
        } else {
      
          if(parseInt(question.uid) == parseInt(e.target.getAttribute('question')) && isNaN(answer.uid)) {
            return { ...answer, checked: false };  
          }

          return { ...answer };
        }
      })
      
      return { ...question,  answers : answers }

    });

    setMatchQuestions(newState);

  }

  function InputType({ questiontype, answer, question }) {
    
    if(answer.uid == question.uid + '-0') {
      return (
        <div className="field-item standard-radio">
           <input
            type="checkbox"
            id={`answer-${question.uid}0`}
            value={answer.uid}
            question={question.uid}
            checked={answer.checked}
            onChange={handleQuestion}
          />
          <label htmlFor={`answer-${question.uid}0`} className="hashtag-label">Not a relevant criterium for me</label>
        </div>
      )
    } else {
      return (
        <>
          <input
            type="checkbox"
            id={`answer-${question.uid}${answer.uid}`}
            value={answer.uid}
            question={question.uid}
            checked={answer.checked}
            onChange={handleQuestion}
          />
          <label htmlFor={`answer-${question.uid}${answer.uid}`}>
            {answer.answer}
            {answer.description ? (
              <span className="description">{answer.description}</span>
            ) : (
              ""
            )}
          </label>
        </>
      );
    }
  }

  function handleNext(e) {
    e.preventDefault();
    const answer = matchQuestions.map((question) => {
        return { [question.uid]: Object.values(question.answers).filter((answer) => answer.checked == true).map(({uid}) => uid) };
    });

    var data = new FormData();
    data.append("selectedFile", selectedFile);
    data.append("isjson", true);
    let params = {
      "userid": cryptedUserId(),
      "step": 4,
      "fields" : {
        "activate-m": activateMatchmaking,
        "show-phone": showphone ? 1:0,
        "show-email": showemail?1:0,
        "description" : description,
        "answer" : answer,
      }
    };

    data.append("fields", JSON.stringify(params));
    setLoading(true)
    doRequest('Server','/api/v1/Frontend/user/updateUser','XHR', 
        data
      , true, (err, data) => {
        console.log(data);
        if(data) {
          setLoading(false);
          doRequest('Server', '/api/v1/Frontend/user/updateMatch','POST', {}, true, (err,data) => console.log(err));
          return navigate('/user-editor/summary');
        }
    })
  }

  function handlePrev(e) {
    e.preventDefault();
    const answer = matchQuestions.map((question) => {
        return { [question.uid]: Object.values(question.answers).filter((answer) => answer.checked == true).map(({uid}) => uid) };
    });

    var data = new FormData();
    data.append("selectedFile", selectedFile);
    data.append("isjson", true);
    let params = {
      "userid": cryptedUserId(),
      "step": 4,
      "fields" : {
        "activate-m": activateMatchmaking,
        "show-phone": showphone ? 1:0,
        "show-email": showemail?1:0,
        "description" : description,
        "answer" : answer,
      }
    };

    data.append("fields", JSON.stringify(params));
    setLoading(true);
    doRequest('Server','/api/v1/Frontend/user/updateUser','XHR', 
        data
      , true, (err, data) => {
        if(data) {
          setLoading(false)
          doRequest('Server', '/api/v1/Frontend/user/updateMatch','POST', {}, true, (err,data) => console.log(err));
          if(enableExhibitortab) {
            return navigate('/user-editor/exhibitor');
          } else {
            return navigate('/user-editor/professional');
          }
        }
    })
  }


  function handleImageRemove(e) {
    e.preventDefault();
    if (window.confirm('Are you sure?')) {
      setLoading(true)
      doRequest('Server', '/api/v1/Frontend/user/updateUser','POST', 
      {
        "userid": cryptedUserId(),
        "step": 4,
        "remove-file": 'file'
      }, true, (err, data) => {
        
        if(data) {
          setLoading(false)
          setUserImage('');
        }
      }) 

    }
    console.log('Remove');
  }


  return (
    <div className="base-box scroll-box" id="page-top">
      <Header hideOptions={true} />

      <div className="base-item">
        <SmallHero />

        <main className="main-content">
          <StepNavigation />

          <section className="modul backend-form pd-5">
            <div className="item-box">
              <div className="tab-content txt-center">
                <div className="form-box">
                
                  <div className="intro-box">
                    <h3 className="no-margin">Your Profile - Matchmaking</h3>
                    <p className="infotext">
                      „Matchmaking“ allows you to connect and network with
                      interesting people at IP Service World.
                    </p>
                  </div>

                    <div
                      className={activateMatchmaking ? "matchmaking-activate active" : "matchmaking-activate"}
                      id="activate-matchmaking"
                    >
                      <div className="field-item standard-radio small-box">
                        <input
                          type="checkbox"
                          name="tx_user_edituser[activate-m]"
                          id="activate-m"
                          value={activateMatchmaking}
                          // onClick={(e) => setActivateMatchmaking(e.target.checked)}
                          checked={activateMatchmaking}
                          onChange={(e) => setActivateMatchmaking(e.target.checked)}
                        />
                        <label
                          htmlFor="activate-m"
                          className="checkbox-label"
                          id="activate-m-label"
                        >
                          Activate matchmaking in order to see other attendees and
                          exhibitors with profiles that match your criteria
                        </label>
                        <div className="no-matchmaking">
                          <div className="button-box form-row flex-box">
                            <div className="field-item left-item">
                              <button
                                className="button previous show-loader"
                                onClick={handlePrev}
                                name="tx_user_edituser[saveStep4]"
                                value="prev"
                              >
                                <span className="button-label">Previous</span>
                                <span className="icon-box">
                                  <i className="icon"></i>
                                </span>
                              </button>
                            </div>
                            <div className="field-item right-item">
                              <button
                                className="button call-to-action next show-loader"
                                onClick={handleNext}
                              >
                                <span className="button-label">
                                  Save &amp; complete
                                </span>
                                <span className="icon-box">
                                  <i className="icon"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="matchmaking-box">
                      <div className="form-row flex-box wrap">
                        <div className="field-item txt-left box-50">
                          <div className="field-item-wrapper">
                            <label className="block-label">
                              Upload your profile image.{" "}
                              <span className="description">
                                Hint: Networking with photo is more successful.
                              </span>
                            </label>
                            <div className="upload-container">
                              <div className="image-preview">
                                <figure className=" user-image-preview">
                                  {userImage ? <img src={process.env.REACT_APP_SERVER_ENDPOINT + userImage} /> : <img src="/images/icon-user.svg" />}
                                </figure>
                              </div>
                              <input
                                data-multiple-caption="{$count} files selected"
                                id="file"
                                type="file"
                                name="tx_user_edituser[file]"
                                onChange={handleFileInputChagne}
                              />
                              <label htmlFor="file" className="label-fileupload">
                                <strong>
                                  Profile Image Upload
                                  <div className="icon-box upload">
                                    <i className="icon"></i>
                                  </div>
                                </strong>
                                <span className="ext-data-title" id="data-title" >{dataTitle}</span>
                                <span className="icon-box checkmark">
                                  <i className="icon"></i>
                                </span>
                                <span className="data-title"></span>
                              </label>
                            </div>
                          </div>
                          {userImage ? (
                            <a
                            className="remove-user-file delete delete-item"
                            onClick={handleImageRemove}
                          >
                            <span className="label">Remove profile image</span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                              <path d="M4 10v20c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V10H4zm6 18H8V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zM26.5 4H20V1.5c0-.825-.675-1.5-1.5-1.5h-7c-.825 0-1.5.675-1.5 1.5V4H3.5C2.675 4 2 4.675 2 5.5V8h26V5.5c0-.825-.675-1.5-1.5-1.5zM18 4h-6V2.025h6V4z"></path>
                            </svg>
                          </a>
                          ) : ''}

                          <div className="field-item-wrapper text-editor-box">
                            <label className="block-label" htmlFor="editor">
                              Write something meaningful about yourself.{" "}
                              <span className="description">
                                What‘s your job? What are you looking for? Where
                                do you come from? What's your favorite hobby? The
                                content of this field is searched in the
                                matchmaking search. This Text can be in German or
                                in English{" "}
                              </span>
                            </label>
                            <CKEditor
                              id="editor"
                              editor={ClassicEditor}
                              config={editorConfiguration}
                              data={description?description: ''}
                              onBlur={(event, editor) => {
                                setDescription(editor.getData());
                              }}
                            />
                            <div id="word-count"></div>
                          </div>
                        </div>
                        <div className="field-item txt-left box-50">
                          <div className="flex-box flex-column field-bottom">
                          <p className="info-text">
                            <strong>
                              What kind of people would you like to meet?{" "}
                            </strong>
                            <span className="description">
                              „Not a relevant criterium“ means this selection
                              criterium is disregarded
                            </span>
                          </p>

                        {matchQuestions && matchQuestions.map( ( question,index ) => (
                          <>{question.matchtext ? (
                            <div key={index} className="field-item-wrapper">
                              <label 
                              className={
                                showMenu == question.uid
                                  ? "block-label selector active"
                                  : "block-label selector"
                                }
                              onClick={() => toggle(question.uid)}>
                                <span>
                                  {question.matchtext}
                                  <span className="description">Multiple choices allowed</span>
                                  <span className="icon-box"><i className="icon"></i></span>
                                </span>
                              </label>
                              <div className="form-row flex-box hashtag-box wrap">
                              {Object.values(question.answers).length && Object.values(question.answers).map((answer, index) => (
                                  <div key={index} className="field-item standard-radio">
                                      <InputType
                                        questiontype={question.questiontype}
                                        answer={answer}
                                        question={question}
                                      />
                                  </div>
                              ))}
                            </div>
                          </div>
                          ) : ''}</>
                        ) )}

                        </div>
                        </div>
                      </div>

                      <div className="form-row flex-box wrap">
                        <div className="field-item txt-left box-50">
                          <div className="form-row standard-radio">
                            <input
                              type="checkbox"
                              name="tx_user_edituser[show-phone]"
                              id="show-phone"
                              value={showphone}
                              checked={showphone}
                              onChange={(e) => setShowPhone(e.target.checked)}
                            />
                            <label htmlFor="show-phone" className="checkbox-label">
                              Please show my phone number and my email in my matchmaking profile
                            </label>
                          </div>
                        </div>
                        <div className="field-item"></div>
                      </div>

                      <div className="button-box form-row flex-box">
                        <div className="field-item left-item">
                          <button
                            className="button previous show-loader"
                            name="tx_user_edituser[saveStep4]"
                            value="prev"
                            onClick={handlePrev}
                          >
                            <span className="button-label">Previous</span>
                            <span className="icon-box">
                              <i className="icon"></i>
                            </span>
                          </button>
                        </div>
                        <div className="field-item right-item">
                          <button
                            className="button call-to-action next show-loader"
                            name="tx_user_edituser[saveStep4]"
                            value="next"
                            onClick={handleNext}
                          >
                            <span className="button-label">Save &amp; complete</span>
                            <span className="icon-box">
                              <i className="icon"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}
