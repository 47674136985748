import { useContext } from "react";
import overlayContext from "../Context/overlayContext";

export default function StickyLinks () {
    const { setShowoverlay } = useContext(overlayContext);
    
    return (
        <div className="static-links">
            <button className="static-link button" id="feedback-opener" onClick={() => setShowoverlay('feedback')} >
                <span className="static-link-label">Feedback</span>
                <span className="icon-box feedback"><i className="icon"></i></span>
            </button>
            <button className="static-link button" id="tutorial-opener" onClick={() => setShowoverlay('tutorial')} >
                <span className="static-link-label">Help</span>
                <span className="icon-box play-movie"><i className="icon"></i></span>
            </button>
            <button className="static-link button" id="hallplan-opener" onClick={() => setShowoverlay('hallplan')}>
                <span className="static-link-label">Hall Plan</span>
                <span className="icon-box locator"><i className="icon"></i></span>
            </button>
        </div>
    )
}