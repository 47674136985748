import { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import { doRequest } from "../utils/doRequest";
import overlayContext from "../Context/overlayContext";

const axios = require('axios');

export default function NextLiveStreamUpOnStage () {
    const { setShowoverlay, setOverlayData } = useContext(overlayContext);
    const [ liveStreams , setLiveStream ] = useState([]);
    
    // $(function () {
    //     setInterval(function () {
    //       $.ajax({
    //         url: "/?type=456456",
    //         type: "POST",
    //         success: function (res) {
    //           $(".stage-stream").html(res);
    //         },
    //       });
    //     }, 10000);
    // });
    

    useEffect(() => {
        let isSubscribed = true;

        doRequest(
          'React',
          'lib/storage/content/livestreams.json',
          'GET', 
          {},
          false,
          (err,data) => {
              if (isSubscribed) {
                setLiveStream(data);
              }
          }
        );
        if (isSubscribed) {
          setInterval(function () {
            doRequest(
              'React',
              'lib/storage/content/livestreams.json',
              'GET', 
              {},
              false,
              (err,data) => {
                setLiveStream(data);    
                }
              );
            }, 10000);  
        }
        
        return () => (isSubscribed = false)
    },[]);
    
    function StreamName({stream}) {

      let stageName = '';

      if (stream.stage == 1) {stageName = process.env.REACT_APP_PROGRAM_TAB_1}
      if (stream.stage == 2) {stageName = process.env.REACT_APP_PROGRAM_TAB_2}
      if (stream.stage == 3) {stageName = process.env.REACT_APP_PROGRAM_TAB_3}

      return stageName;
    }

    if(!liveStreams.length) {
      return (<section key="livestream-container" className="modul events-box next-on-stage pd-4" id="livestream-container"></section>);
    }

    function ShowTime({time}) {
      // var t = new Date(time * 1000);
      // var am_pm = t.getHours() >= 12 ? "PM" : "AM";
      // var formatted = ('0' + t.getHours()).slice(-2) + ':' + ('0' + t.getMinutes()).slice(-2);
      // return (formatted + ' ' + am_pm);
      var date = new Date(time * 1000);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    }

    return (
        <section key="livestream-container" className="modul events-box next-on-stage pd-4" id="livestream-container">
            <div className="item-box">
                <h5 className="txt-uppercase">NEXT LIVE STREAM UP ON STAGE</h5>
                <div className="events flex-box wrap cl-white stage-stream">

                { liveStreams && liveStreams?.map((stream) => (
                    <div key={stream.stage} className={`flex-item wide-item reduced tradeshow`}>
                    <div className="event-item">
                      <figure className="image-box">
                        <img src={`${process.env.REACT_APP_SERVER_ENDPOINT}${stream.previewimage}`} />
                      </figure>
                      <div className="events-info">
                        <div className="stage-box"><StreamName stream={stream} /></div>
                        
                        <div className="time-box"><strong><ShowTime time={stream.starttimestamp} /></strong></div>
                        <div className="stream-title-box">
                          <h4>{stream.title}<br /></h4>
                        </div>
                        {(stream.companyId.speakers) ? (
                          <>
                          <div className="speaker-box">
                            <p className="no-margin">
                            {stream.companyId.speakers.map((speaker,index) => (
                              <strong>{speaker.firstname} {speaker?.middlename} {speaker.lastname}
                              {(speaker.position) ? (
                                <>, {speaker.position}</>
                              ) : (
                                <></>
                              )}
                              <br/></strong>
                            ))}
                            </p>
                          </div>
                          </>
                          ) : (
                          <>
                          </>
                          )
                        }
                        <div className="product-overview-box">
                          <ul className="product-list">
                          {stream && stream.topic && stream.topic.map((topicName) => (
                              <li>{topicName.name}</li>
                          ))}
                          </ul>
                        </div>
                        {stream.longtext ? (
                          <button className="open-info-box" data-id="55">
                          <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41">
                            <g transform="translate(4.022 3.96)">
                                <clipPath id="a">
                                  <path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path>
                                </clipPath>
                                  <g clip-path="url(#a)">
                                  <circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle>
                                <path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path>
                              </g>
                            </g>
                          </svg>
                        </button>
                        ) : ''}
                        
                        <div className="events-info-box">
                        <button className="close-info-box" data-id="55">
                          <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41">
                            <g transform="translate(4.022 3.96)">
                              <clipPath id="a">
                                <path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path>
                              </clipPath>
                              <g clip-path="url(#a)">
                                <circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle>
                                <path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path>
                              </g>
                            </g>
                          </svg>
                        </button>
                          <h3>{stream.title}</h3>
                          {parse(stream.longtext)}
                        </div>
                      </div>
                      <div className="button-box">
                        <button
                          className="button call-to-action live-stream-opener recent-stream"
                          data-company-id={stream?.companyId?.companyid}
                          data-uid={stream.uid} 
                          onClick={() => {
                            setOverlayData({
                              stream: stream.uid, 
                              streamUrl: stream.streamurl, 
                              companyId : stream?.companyId?.companyid, 
                              stage: stream.stage,
                              title: stream.title
                            }); setShowoverlay('livestream')}}
                          >
                            <span className="button-label">Start Live Stream</span>
                            <span className="icon-box"><i className="icon"></i>
                            </span>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

                </div>
            </div>

            </section>

    )
}