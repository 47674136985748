import { useContext, useEffect } from "react";
import overlayContext from "../Context/overlayContext";
import StreamVideo from "../Live/StreamVideo";
import { decryptedUserId, getCookie, setCookie } from "../utils";
import { doRequest } from "../utils/doRequest";
import Feedback from "./Feedback";
import StickyVideo from "./StickyVideo";

export default function Overlays () { 
    const { showOverlay, setShowoverlay, overlayData, setOverlayData } = useContext(overlayContext);

    function closeOverlay() {
        setShowoverlay('');
    }
    function openLivestream() {
        console.log('openlivestream');
        setShowoverlay('');
        setTimeout(() => {
            setShowoverlay('livestream');
        }, 100);
    }

    function closeOverlayAndRejectConsent() {
        doRequest(
            'Server',
            '/api/v1/admin/user/userConsent',
            'POST', 
            {
                localId: process.env.REACT_APP_LOCAL_ID,
                setConsent : 1,
                uid: decryptedUserId(),
                consent: 2
            },
            false,
            (err,data) => {
                setCookie('consent', data.third_party_consent, 365);
                closeOverlay();
            }
        );
    }

    function closeOverlayAndAcceptConsent(showOverlayOF) {
        doRequest(
            'Server',
            '/api/v1/admin/user/userConsent',
            'POST', 
            {
                localId: process.env.REACT_APP_LOCAL_ID,
                setConsent : true,
                uid: decryptedUserId(),
                consent: 1
            },
            false,
            (err,data) => {
                setCookie('consent', data.third_party_consent, 365);

                if(overlayData.check == 'whitepaper') {
                    setOverlayData({whitepaper: true , ...overlayData});
                }
                if(overlayData.check == 'article' ) {
                    setOverlayData({article: true , ...overlayData});
                }

                if(showOverlayOF == 'livestream') {
                    openLivestream();
                } else {
                    closeOverlay();
                }
            }
        );
    }

    function ShowTutorialsOverlay () {
        return (
            <div className="overlay active tutorial scroll-overlay" id="tutorial-overlay">
                <div className="item-box">
                        <button onClick={closeOverlay} className="close close-overlay">
                            <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg>
                        </button>
                        <div className="overlay-container pd-8">
                            {/* <div className="iframe-box"> */}
                                    {/* <iframe id="overlay-iframe" scrolling="no" src="/sticky-video" frameBorder="0"></iframe> */}
                                    <StickyVideo />
                            {/* </div> */}
                        </div>
                </div>
            </div>
        )
    }

    function ShowHallPlanOverlay() {
        return(
            <div className="overlay hallplan scroll-overlay active" id="hallplan-overlay">
                <div className="item-box">
                    <button onClick={closeOverlay} className="close close-overlay">
                        <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg>
                    </button>
                    <div className="overlay-container whitepaper-content cl-white pd-t12 pd-b5 floorplan-download">
                        <div className="overlay-title flexbox center-left"></div>
                        <div className="col-box col-2">
                            <div className="col-item">
                                <h3>Floorplan IP Service world</h3>
                                <p>Download the floorplan of the IP Service World trade show</p>
                            </div>
                            <div className="col-item flex-box bottom-center">
                                <a target="_blank" href="https://api.live-ipserviceworld.com/fileadmin/user_upload/page-tree/ipsw/Floorplan_IP_Service_World_2022.pdf" className="button call-to-action">Download</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function ShowFeedbackOverlay() {
        return(
            <div className="overlay feedback active" id="feedback-overlay">
                <div className="item-box">
                    <button onClick={closeOverlay} className="close close-overlay">
                        <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg>
                    </button>
                    <div className="overlay-container pd-8">
                        {/* <div className="iframe-box"> */}
                                {/* <iframe id="overlay-iframe" src="/feedback" frameBorder="0"></iframe> */}
                                <Feedback />
                        {/* </div> */}
                    </div>
                </div>
            </div>
        )
    }

    function ShowLiveStreamOverlay() {
        console.log(getCookie('consent'));
        console.log('Show live steram overlay called');
        if(getCookie('consent') != 1){
            return(<ConsetOverlay showOverlayOF="livestream" />)
        }
        
        return(
            <div
            className="overlay live-stream scroll-overlay active"
            id="livestream-overlay"
            >
            <div className="item-box">
                <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
                <svg
                    id="shrink"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    stroke-miterlimit="10"
                    clip-rule="evenodd"
                    viewBox="0 0 41 41"
                >
                    <g transform="translate(4.022 3.96)">
                    <clipPath id="a">
                        <path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z"></path>
                    </clipPath>
                    <g clip-path="url(#a)">
                        <circle
                        cx="15"
                        cy="15"
                        r="15"
                        fill="none"
                        stroke="#fff"
                        transform="rotate(135 15.28 15.738)"
                        ></circle>
                    </g>
                    </g>
                    <path
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    d="M-8.21 9H9.21"
                    transform="matrix(-.48635 .00028 -.00052 -.8882 26.522 26.716)"
                    ></path>
                    <path
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    d="M-8.21 9H9.21"
                    transform="matrix(-.00033 -.48634 .8882 -.0006 13.281 14.97)"
                    ></path>
                    <path
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    d="M-8.21 9H9.21"
                    transform="matrix(-.39344 .3873 -.62309 -.63297 31.283 19.96)"
                    ></path>
                    <g>
                    <path
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        d="M-8.21 9H9.21"
                        transform="matrix(.48627 -.00827 .0151 .88806 14.059 12.877)"
                    ></path>
                    <path
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        d="M-8.21 9H9.21"
                        transform="matrix(.00832 .48627 -.88806 .01519 27.49 24.403)"
                    ></path>
                    <path
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        d="M-8.21 9H9.21"
                        transform="matrix(.38703 -.3937 .6334 .62265 9.41 19.71)"
                    ></path>
                    </g>
                </svg>
                <svg
                    id="enlarge"
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    stroke-miterlimit="10"
                    clip-rule="evenodd"
                    viewBox="0 0 41 41"
                >
                    <g transform="translate(4.022 3.96)">
                    <clipPath id="a">
                        <path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z"></path>
                    </clipPath>
                    <g clip-path="url(#a)">
                        <circle
                        cx="15"
                        cy="15"
                        r="15"
                        fill="none"
                        stroke="#fff"
                        transform="rotate(135 15.28 15.738)"
                        ></circle>
                    </g>
                    </g>
                    <path
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    d="M-8.21 9H9.21"
                    transform="matrix(-.48635 .00028 -.00052 -.8882 16.522 36.716)"
                    ></path>
                    <path
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    d="M-8.21 9H9.21"
                    transform="matrix(-.00033 -.48634 .8882 -.0006 3.281 24.97)"
                    ></path>
                    <path
                    fill="none"
                    stroke="#fff"
                    stroke-linecap="square"
                    d="M-8.21 9H9.21"
                    transform="matrix(-.39344 .3873 -.62309 -.63297 21.283 29.96)"
                    ></path>
                    <g>
                    <path
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        d="M-8.21 9H9.21"
                        transform="matrix(.48627 -.00827 .0151 .88806 23.059 3.877)"
                    ></path>
                    <path
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        d="M-8.21 9H9.21"
                        transform="matrix(.00832 .48627 -.88806 .01519 36.49 15.403)"
                    ></path>
                    <path
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        d="M-8.21 9H9.21"
                        transform="matrix(.38703 -.3937 .6334 .62265 18.41 10.71)"
                    ></path>
                    </g>
                </svg>
                </button>
                <button onClick={closeOverlay} className="close close-overlay" id="close-stream-box">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill-rule="evenodd"
                    stroke-miterlimit="10"
                    clip-rule="evenodd"
                    viewBox="0 0 41 41"
                >
                    <g transform="translate(4.022 3.96)">
                    <clipPath id="a">
                        <path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clipRule="nonzero"></path>
                    </clipPath>
                    <g clip-path="url(#a)">
                        <circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)" ></circle>
                        <path
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"
                        ></path>
                    </g>
                    </g>
                </svg>
                </button>
                <button className="position-button position-top" id="position-top">
                <span></span>
                </button>
                <button
                className="position-button position-bottom"
                id="position-bottom"
                >
                <span></span>
                </button>
                <button className="position-button position-left" id="position-left">
                <span></span>
                </button>
                <button className="position-button position-right" id="position-right">
                <span></span>
                </button>
                <div className="overlay-container pd-8">
                    <StreamVideo />
                </div>
            </div>
            </div>
        );
    }

    function EasyAppointmentOverlay() {
        return (
          <div class="overlay easyappointment active" id="easyappointment-overlay">
            <div class="item-box">
              <button onClick={closeOverlay} class="close close-overlay">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill-rule="evenodd"
                  stroke-miterlimit="10"
                  clip-rule="evenodd"
                  viewBox="0 0 41 41"
                >
                  <g transform="translate(4.022 3.96)">
                    <clipPath id="a">
                      <path
                        d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z"
                        clip-rule="nonzero"
                      ></path>
                    </clipPath>
                    <g clip-path="url(#a)">
                      <circle
                        cx="15"
                        cy="15"
                        r="15"
                        fill="none"
                        stroke="#fff"
                        transform="rotate(135 15.28 15.738)"
                      ></circle>
                      <path
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="square"
                        d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"
                      ></path>
                    </g>
                  </g>
                </svg>
              </button>
              <div class="overlay-container pd-8">
                <div class="iframe-box">
                  <iframe id="overlay-iframe" src={overlayData.src} frameborder="0"></iframe>
                </div>
              </div>
            </div>
          </div>
        );
    }

    function FirstViewOverlay() {
        return (
            <div className="overlay active tutorial scroll-overlay" id="tutorial-overlay">
                <div className="item-box">
                        <button onClick={closeOverlay} className="close close-overlay">
                            <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg>
                        </button>
                        <div className="overlay-container pd-8">
                        <div className="overlay-iframe">
                            <div className="video-box">
                                <div className="inner">
                                <div className="ce-textpic ce-center ce-above">
                                    <div className="ce-gallery" data-ce-columns="1" data-ce-images="1">
                                    <div className="ce-outer">
                                        <div className="ce-inner">
                                        <div className="ce-row">
                                            <div className="ce-column">
                                            <figure className="video">
                                                <div className="video-embed">
                                                <video
                                                    width="1500"
                                                    controls
                                                    no-cookie
                                                    className="video-embed-item"
                                                >
                                                    <source
                                                    src={process.env.REACT_APP_SERVER_ENDPOINT + overlayData.src}
                                                    type="video/mp4"
                                                    />
                                                </video>
                                                </div>
                                            </figure>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>

                                <script
                                async=""
                                type="text/javascript"
                                id="hs-script-loader"
                                defer=""
                                src="//js-eu1.hs-scripts.com/25140199.js"
                                ></script>
                            </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    }

    function ConsetOverlay({showOverlayOF}) {
        return (
        <div class="overlay scroll-overlay active" id="cookie-overlay">
            <div class="item-box">
                <button onClick={closeOverlayAndRejectConsent} class="close close-overlay">
                    <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg>
                </button>
                <div class="overlay-container">
                    <div class="div">
                        <h4>Yes, I agree to the exchange of my data with the exhibitors of the event.</h4>
                        <p> As soon as I enter the virtual trade show area, I agree to my data (name, job title, company, e-mail) being passed on to the exhibitors at the event for the purpose of them sending me promotional information on the services they offer. This consent only applies to the companies whose content I have used. </p>
                        <p> Note from the organiser: Your privacy is very important to us. Therefore, we would like to inform you that when you interact with our partners, your business contact information will be shared with the partner. This is for the purpose of informing you about products or services. Please keep this in mind when communicating with or accessing content from our event partners. Your contact information will only be shared if you have agreed to the exchange with our partners. </p>
                        <p>
                            <a href="https://www.ipserviceworld.com/data-privacy-statement/" target="_blank"><span> Privacy Policy </span></a>
                        </p>
                        <button id="accept-cookie" onClick={() => closeOverlayAndAcceptConsent(showOverlayOF)}>Yes, I agree</button>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    return (
        <>
        {showOverlay && showOverlay == 'feedback' ? <ShowFeedbackOverlay />: null}
        {showOverlay && showOverlay == 'hallplan' ? <ShowHallPlanOverlay />: null}
        {showOverlay && showOverlay == 'tutorial' ? <ShowTutorialsOverlay />: null}
        {showOverlay && showOverlay == 'livestream' ? <ShowLiveStreamOverlay /> : null}
        {showOverlay && showOverlay == 'EA' ? <EasyAppointmentOverlay /> : null}
        {showOverlay && showOverlay == 'FirstView' ? <FirstViewOverlay /> : null}
        {showOverlay && showOverlay == 'Conset' ? <ConsetOverlay showOverlayOF={''} /> : null}
        
        
        
        {/* <!-- Overlay Archived Video --> */}
        <div className="overlay flex-box center-center stream-archive" id="stream-archive">
            <div className="item-box tab-box">
            <button className="shrink-enlarge se-overlay" id="shrink-enlarge">
                    <span className="icon-box shrink"><i className="icon"></i></span>
                    <span className="icon-box enlarge"><i className="icon"></i></span>
            </button>
            <button className="close close-overlay" id="close-stream-box">
                <span className="icon-box close"><i className="icon"></i></span>
            </button>
            <button className="position-button position-top" id="position-top"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-bottom" id="position-bottom"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-left" id="position-left"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-right" id="position-right"><span className="icon-box arrow"><i className="icon"></i></span></button>
            <div className="overlay-container pd-8">
                <div className="iframe-box">
                    {/* <iframe id="overlay-iframe" scrolling="no" src="/stream-video" frameBorder="0" width="100%" height="100%"></iframe> */}
                </div>
            </div>
            </div>
        </div>
        {/* <!-- Overlay User Leads --> */}
        <div className="overlay flex-box center-center user-leads" id="user-leads">
            <div className="item-box tab-box">
            <button className="close close-overlay" id="close-stream-box">
                <span className="icon-box close"><i className="icon"></i></span>
            </button>
            <button className="position-button position-top" id="position-top"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-bottom" id="position-bottom"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-left" id="position-left"><span className="icon-box arrow"><i className="icon"></i></span></button>
        <button className="position-button position-right" id="position-right"><span className="icon-box arrow"><i className="icon"></i></span></button>

            <div className="overlay-container cl-white pd-t12 pd-b5">
                <div className="overlay-title flex-box center-left"><strong>User Statistics</strong></div>
                <div className="col-box col-3">
                    <div className="col-item user-stats-container">
                    </div>
                </div>
            </div>
            </div>
        </div>
        
        </>
    )
}