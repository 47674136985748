import Header from "../Components/Header";
import SmallHero from "../Components/SmallHero";
import Footer from "../Components/Footer";
import MatchFilter from "./MatchFilter";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { MyAgendaProvider } from "../Live/Context/myAgendaContext";
import { decryptedUserId } from "../utils";
import $ from 'jquery';
import axios from "axios";

export default function Matchmaking () {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [userAgenda, setUserAgenda ] = useState([]);
    let [messageCount, setMessageCount] = useState(0);
    const [userId, setUserId] = useState(0);

    let decryptedData = '';

    if (localStorage.getItem('contextVar')) {
        var CryptoJS = require("crypto-js");
        var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
        var bytes  = CryptoJS.AES.decrypt(localStorage.getItem('contextVar')?.toString(), secretKey);
        if (bytes.toString(CryptoJS.enc.Utf8)) {
            decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            //console.log('decryptedData : '+ decryptedData)
        }
    }
    var chatUrl = process.env.REACT_APP_CHAT_ENDPOINT + "Chat.php";
    let getMessagesFromChat = function () {
        var currUser = $('#currUser').val();
        var myKeyVals = {
            fetchMessages : 1,
            to: currUser
        }

        var saveData = $.ajax({
            type: "POST",
            url: chatUrl,
            data: myKeyVals,
            dataType: "text",
            dataType : 'json',
            success: function(resultData){
                // console.log(resultData);
                let totalCount = resultData.filter(function(value) { return value.m_from == currUser && value.is_read == 0 }).length;

                // console.log('totalCount ' + totalCount )
                // if (parseInt(totalCount) > 0) {
                //     setMessageCount(totalCount)
                // }
                
                // if (typeof window.top.$ === 'function') {
                    let p = window.top;
                    $('.matchmaking-counter', window.parent.document).html(totalCount);
                    let currentRequests = parseInt($('#currentRequests', window.parent.document).val());

                    if (totalCount > currentRequests && $('.search-notification').val() == '') {
                        $('#currentRequests', window.parent.document).val(totalCount);
                        setMessageCount(totalCount)

                        // if($("#notification-detial").css('display') == 'none') {
                        //     p.$('.chat-notifications #overlay-iframe').attr('src', p.$('.chat-notifications #overlay-iframe').attr('src'));
                        // }

                    }

                // }
                //  console.log('message Count ' + messageCount );


                $('#notification-results .notification-item').each(function() {
                    var to = $(this).attr('data-to');
                    var from = $(this).attr('data-from');

                    var length = resultData.filter(function(value) { return value.m_from == to && value.m_to == from && value.is_read == 0 }).length

                    $(this).find('.button-count').html(length>0?length:'');
                    var allMessages = resultData.filter(function(value) {
                            //return value.m_from == to && value.is_read == 0
                            return (value.m_from == to && value.m_to == from) || (value.m_from == from && value.m_to == to)
                    });

                    var time = 0;
                    var message = '';
                    var msgTime = '';
                        if (allMessages.length) {
                        var message = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['message'];

                        var time = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['tstamp'];


                        var msgTime = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['time'];
                    }


                    

                    $(this).find('.button-box').html(message);
                    $(this).find('.time-box').html(msgTime);
                    $(this).attr('data-time', time);
                });

                var sortedHtml = $('#notification-results .notification-item')
                .sort((a,b) => $(b).data("time") - $(a).data("time"))
                $("#notification-results").html(sortedHtml)
            }
        });
    }

    useEffect(() => {
        document.body.classList.add('live');
        let isSubscribed = true;
        if (isSubscribed) {
            setUserId(decryptedUserId());
            getMessagesFromChat();
            setInterval(getMessagesFromChat, 5000);
        }
        
      return () => {
        document.body.classList.remove('live');
        isSubscribed = false;
      }
    }, [])

    useEffect(() => {
      if (userId) {
        axios
          .get( process.env.REACT_APP_ENDPOINT + "lib/storage/users/" + userId + "/myagenda.json", {
            headers: {
              Authorization: "Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh",
            },
            auth: {
              username: "cuatconference",
              password: "relaunch",
            },
            params: {
              v: process.env.REACT_APP_V,
            },
          })
          .then((res) => {
            if (res.data.eventID) {
              setUserAgenda(res.data.eventID);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, [userId]);

    useEffect(() => {
        if (!authContext.isAuth ) { return (navigate("/user-login")); }    
      }, [authContext.isAuth])

    return (
        <div className="base-box fixed" id="page-top">
          <MyAgendaProvider value={{ userAgenda, setUserAgenda }}>
            <input type="hidden" id="currUser" value={decryptedData} />
            <Header />
            <div className="base-item" >
                <SmallHero />
            </div>
            <main className="main-content">
                <MatchFilter type="matchmaking" />
            </main>
            <Footer />
          </MyAgendaProvider>
        </div>
    );
}
