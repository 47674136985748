import React from 'react'
import { Link } from 'react-router-dom'

export default function TabItem() {
    return (
        <div className="item-box">
            <div className="tab-box link-box flex-box wrap">
                <div className="tab-item">
                    <Link to={'/exhibitor-editor'}>Company Profile</Link>
                </div>
                <div className="tab-item">
                    <Link to={'/lead-statistics'}>Lead and Analytics</Link>
                </div>
            </div>
        </div>
    )
}
