import React, { useContext, useEffect, useState } from 'react'
import Footer from '../Components/Footer';
import Header from '../Components/Header'
import SmallHero from '../Components/SmallHero';
import TabItem from './TabItem';
import { AuthContext } from '../Context/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { cryptedUserId } from '../utils';
import { LoadingContext } from '../Context/LoadingContext';
import { doRequest } from '../utils/doRequest';

export default function LeadStatistics() {

   const navigate = useNavigate();
   const authContext = useContext(AuthContext);
   const { setLoading } = useContext(LoadingContext);

   const [leadStatisticsTable, setLeadStatisticsTable] = useState('');

  useEffect(() => {
      document.body.classList.add('backend');
      document.body.classList.add('bd-exhibitor-editor');

      setLoading(true);
      doRequest('Server', 'api/v1/Frontend/user/leadsExportFile','POST', 
      {
         "userid": cryptedUserId(),
         "fileType": 'html'
      }, true, (err, data) => {
         setLoading(false);
         setLeadStatisticsTable(data);
      });

      return () => {
         document.body.classList.remove('backend');
         document.body.classList.remove('bd-exhibitor-editor');
      }
  }, [])

  useEffect(() => {
   if (!authContext.isAuth ) { return (navigate("/user-login")); }    
 }, [authContext.isAuth])


 function exportLeads(fileType) {
   setLoading(true);
   doRequest('Server', 'api/v1/Frontend/user/leadsExportFile','POST', 
   {
     "userid": cryptedUserId(),
     "fileType": fileType
   }, true, (err, data) => {
      if (fileType == 'pdf') {

         const fileName = "Leads.pdf";
         
         var binary = atob(data.pdfFileBase64.replace(/\s/g, ''));
         var len = binary.length;
         var buffer = new ArrayBuffer(len);
         var view = new Uint8Array(buffer);
         for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
         }

         var blob = new Blob([view], { type: "application/pdf" });
         
         if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveBlob(blob, fileName);
         else
         {
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
         }
      } else if (fileType == 'csv') {
         const fileName = "Leads.csv";
         var blob = new Blob([data.csvFileBase64]);
         if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveBlob(blob, fileName);
         else
         {
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
            a.download = fileName;
            document.body.appendChild(a);
            a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
         }
      }
      setLoading(false);
   })
 }
  

  return (
    <div className="base-box fixed" id="page-top">
      <Header hideOptions={true} />
      <SmallHero />
      <main className="main-content">
        <div className="tx-exhibitor">
          <section className="modul backend-form bg-white">
            <TabItem />
          </section>
          <section className="modul backend-form">
         <div className="item-box pd-5 ">
            <div className="tab-content" id="leads-analytics">
               <div className="form-box">
                  <h3>Leads &amp; Analytics</h3>
                  <div className="col-box col-2">
                     <div className="col-item flex-box box-20">
                        <h4 className="margin-top-center">Leads</h4>
                     </div>
                     <div className="col-item">
                        <Link className="button download" onClick={() => exportLeads('pdf')} href="/lead-statistics?tx_exhibitor_leads%5Bpdf%5D=1&amp;cHash=d43a4f7a86aa58383b75f99d292c9a03">Download as PDF</Link>
                        <Link className="button download" onClick={() => exportLeads('csv')} href="/lead-statistics?tx_exhibitor_leads%5BgenerateCsv%5D=1&amp;cHash=cae47759ce095d1c14d3298fbc259423">Download as CSV</Link>
                     </div>
                  </div>
                  <div dangerouslySetInnerHTML={{__html: leadStatisticsTable.html}}></div>
               </div>
            </div>
         </div>
      </section>
        </div>
      </main>
      <Footer />
    </div>
  )
}
