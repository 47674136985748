import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { doRequest } from '../utils/doRequest'
import SmallHero from '../Components/SmallHero'
import $ from 'jquery'

import parse from "html-react-parser";

import { decryptedUserId } from '../utils';
import Matchlinks from '../Matchmaking/Matchlinks'
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import { AuthContext } from '../Context/AuthContext';
import overlayContext from '../Context/overlayContext'
import { BookmarkContext } from '../Context/BookmarkContext'
import { LoadingContext } from '../Context/LoadingContext'

export default function UserInfo() {
    const authContext = useContext(AuthContext);
    const { showOverlay, setShowoverlay, setOverlayData } = useContext(overlayContext); 
    const navigate = useNavigate();
    const bookmarkContext = useContext(BookmarkContext);
    const { setLoading } = useContext(LoadingContext);

    let { userId } = useParams();
    let isSubscribed = true;

    const [ match, setMatch ] = useState([]);
    const [ profile, setProfile ] = useState([]);
    const [ loggedInUserId, setLoggedinUserId ] = useState(); 
    var tempBookmark = [];
    
 
    useEffect(() => {
        document.body.classList.add('live');
        document.body.classList.add('bd-matchmaking');
        setLoggedinUserId(decryptedUserId());
        setLoading(true);
        doRequest('React', 'server.php', 'GET', { type: 'profileMatchDetials', user: decryptedUserId(), getUserDetialsOf: userId }, true, (err,data) => {
            setLoading(false);
            if(isSubscribed) {
                setMatch(data.response[0]);
                // setProfile(data.response.profile);
            }
        })
        return () => {
            document.body.classList.remove('live');
            document.body.classList.remove('bd-matchmaking');
            isSubscribed = false;
        }
    },[])

    useEffect(() => {
        if (!authContext.isAuth ) { return (navigate("/user-login")); }    
    }, [authContext.isAuth])

    function startChat(to) {
        setShowoverlay('chat');
        console.log(to);
        let toUser = to;
        var showDetail = true;
        var from = decryptedUserId();
        var to = toUser;

        let message = { to, from, showDetail };
        
        setTimeout(() => {
            var chatFrame = $('.chat-notifications #overlay-iframe');
            chatFrame[0].contentWindow.postMessage(message, '*');    
        }, 1000);

        doRequest('React', 'server.php', 'GET', {
            type: 'myContacts',
            user: decryptedUserId(),
            toUser: toUser
        }, '', (err,data) => {console.log(data)} );

    }

    function showEasyappointment() {
        setShowoverlay('EA');
    }

    function toggleBookmark(event) {
        event.preventDefault();
        let bookmarkId = parseInt(event.currentTarget.getAttribute('data-id'));
        
        let operation = 'add';
        if (bookmarkContext.bookmark.indexOf(bookmarkId) == -1) {
            bookmarkContext.setBookmark(bookmarkUsers => [...bookmarkUsers, bookmarkId]);
            // setBookmarkUsers(bookmarkUsers => [...bookmarkUsers, bookmarkId]);
        } else {
            tempBookmark = bookmarkContext.bookmark.filter(function(item) {
                return item !== bookmarkId
            });
            // setBookmarkUsers(tempBookmark);
            bookmarkContext.setBookmark(tempBookmark);
            operation = 'remove';
        }
        
        doRequest('React', 'server.php', 'GET', { type: 'addBookmarkUser', user: decryptedUserId(),toUser: bookmarkId,operation: operation}, true, (err,data) => {
            console.log()
        })
       
        
    }
    let bookmarkActive = '';
    if (bookmarkContext.bookmark?.indexOf(parseInt(match.uid)) != -1){
        bookmarkActive = 'active';
    }

    let matchClass = 'match';

    if(match.score >= 200 ) {
        matchClass = 'top-match';
    } else if(match.score >= 100  && match?.score < 200) {
        matchClass = 'good-match';
    }

  return (
    <div className="base-box fixed" id="page-top">
        <Header />
        <div className="base-item" >
            <SmallHero />
        </div>
        <main className="main-content">
            <section className="modul user-matchmaking  bg-white" id="filter-nav-section">
                <div className="item-box pd-t5 ">
                    <div className="nav-box flex-box wrap">
                        <Matchlinks />
                    </div>
                </div>
            </section>
            {profile && match && (
            <section className="modul user-detail bg-4">
                <div className="item-box pd-5">
                    <div className="small-box txt-center pd-b7">
                        <h3 className="no-margin txtx-center">Get in Touch</h3>
                        <p className="info-text">Found an interesting match? Then there is just one more thing to do: Click on the orange button to start your chat. We wish you a good conversation!</p>
                        <div className="button-box small-box pd-5">
                        <button className="button call-to-action" onClick={() => startChat(match.uid)} data-id={profile.uid} ><span className="button-label">Start Chat</span><span className="icon-box"><i className="icon"></i></span></button>
                        {match.video_company_id ? (
                            <button onClick={() => { 
                                setOverlayData({ src: `${process.env.REACT_APP_EA_ENDPOINT}?provider=${match.providerId}&userId=${loggedInUserId}` });
                                showEasyappointment()
                            }} class="button appointment">Make a Video Chat Appointment</button>
                        ) : ''}
                        
                        </div>
                    </div>
                        <div className="flex-box wrap user-detail-box bg-white top-match">
                            <div className="flex-item user-image">
                                <figure className="image-box">
                                    <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + match.image} />
                                </figure>
                                <button className={`button bookmark ${bookmarkActive}`} data-id={match.uid} onClick={toggleBookmark}>
                                    <span className="button-label" data-id={match.uid}>Bookmark</span>
                                    <span className="icon-box bookmarks" data-id={match.uid}><i className="icon"></i></span>
                                </button>
                            </div>

                            <div className="flex-item flex-box user-information">
                                <div className="user-information-item">
                                    <div className="user-nameset">
                                        <div className="user-nameset-info"><strong>{match.title}  {match.first_name} {match.last_name}</strong></div>
                                        <div className="user-nameset-info">{profile &&  profile.position}</div>
                                        <div className="user-nameset-info"><span className="content-label">Email: </span>{match.email}</div>
                                    </div>
                                    <div className="form-row flex-box hashtag-box wrap">
                                            {match.expertises && match.expertises.map((expertise, index) => {
                                                if (expertise.checked) {
                                                    return (
                                                        <div className="field-item">
                                                            <label className="hashtag-label">
                                                                <span key={index}>{expertise.name}</span>
                                                            </label>
                                                        </div>
                                                    );
                                                }
                                            })}
                                    </div>
                                    <div className="text-box">
                                        <h6>About Me</h6>
                                        {match.description && parse(match.description)}
                                    </div>
                                    {console.log(match)}
                                    <strong>{match.userStatus?.anstext}</strong>
                                    <div className="user-status">
                                        {match.onlineStatus == 1
                                            ? <span className="online-state">Online &amp; @IPSW</span>
                                            : ''
                                        }
                                        {match.onlineStatus == 2 
                                            ? <span class="icon-at-home"></span>
                                            : ''
                                        }
                                        {match.speaker == 1
                                            ? <span className="speaker">Speaker</span>
                                            : ''
                                        }
                                        {match?.usergroup?.split(',').includes('1') == true
                                            ? <span class="exhibitor">Supporter & Exhibitor</span>
                                            : ''
                                        }
                                        
                                    </div>
                                </div>
                            </div>
                        
                            
                            <div className={`score-box ${matchClass}`}>
                                <span className={matchClass}>{matchClass}</span><span className="icon-box"><i className="icon"></i></span>
                            </div>
                        </div>
                    <div className="button-box small-box pd-5">
                        <button className="button call-to-action" onClick={() => startChat(match.uid)} data-id={profile.uid} ><span className="button-label">Start Chat</span><span className="icon-box"><i className="icon"></i></span></button>
                        {match.video_company_id ? (
                            <button onClick={() => { 
                                setOverlayData({ src: `${process.env.REACT_APP_EA_ENDPOINT}?provider=${match.providerId}&userId=${loggedInUserId}` });
                                showEasyappointment()
                            }} class="button appointment">Make a Video Chat Appointment</button>
                        ) : ''}
                    </div>
                </div>
            </section>
            )}
        </main>
        <Footer isExpended={true} />
        </div>
  )
}
