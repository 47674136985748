import Exhibitor from "./Exhibitor";

import Hero from "./Hero";
import Newsroom from "./Newsroom.js";
import FindYourBisnessPartner from './FindYourBuisnessPartner';
import SponsorsSection from './SponsorsSection';
import MediaPartner from './MediaPartner';
import FirstView from './FirstView';
import NextLiveStreamUpOnStage from './NextLiveStreamUpOnStage';
import StickyLinks from "./StickyLinks";
import AgendaSection from './AgendaSection';
import ProgramSection from "./ProgramSection";

import { MyAgendaProvider } from './Context/myAgendaContext';
import { ProgramProvider } from "./Context/programContext";

import {  useContext, useEffect, useState } from "react";
import { doRequest } from "../utils/doRequest";
import { decryptedUserId, isLogin, setCookie } from '../utils';

import BeatLoader from "react-spinners/BeatLoader";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import overlayContext from "../Context/overlayContext";
import $ from 'jquery'

export default function LivePage ({setIsLoggedInUser, isLoggedInUser}) {

    const [userAgenda, setUserAgenda ] = useState([]);
    const [programs, setProgram ] = useState([]);
    const [userInfo, setUserInfo] = useState();

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const {setShowoverlay} = useContext(overlayContext);

    let [messageCount, setMessageCount] = useState(0);

    let decryptedData = '';

    if (localStorage.getItem('contextVar')) {
        var CryptoJS = require("crypto-js");
        var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
        var bytes  = CryptoJS.AES.decrypt(localStorage.getItem('contextVar')?.toString(), secretKey);
        if (bytes.toString(CryptoJS.enc.Utf8)) {
            decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            //console.log('decryptedData : '+ decryptedData)
        }
    }

    var chatUrl = process.env.REACT_APP_CHAT_ENDPOINT + "Chat.php";
    let getMessagesFromChat = function () {
        var currUser = $('#currUser').val();
        var myKeyVals = {
            fetchMessages : 1,
            to: currUser
        }

        var saveData = $.ajax({
            type: "POST",
            url: chatUrl,
            data: myKeyVals,
            dataType: "text",
            dataType : 'json',
            success: function(resultData){
                // console.log(resultData);
                let totalCount = resultData.filter(function(value) { return value.m_from == currUser && value.is_read == 0 }).length;

                // console.log('totalCount ' + totalCount )
                // if (parseInt(totalCount) > 0) {
                //     setMessageCount(totalCount)
                // }
                
                // if (typeof window.top.$ === 'function') {
                    let p = window.top;
                    $('.matchmaking-counter', window.parent.document).html(totalCount);
                    let currentRequests = parseInt($('#currentRequests', window.parent.document).val());

                    if (totalCount > currentRequests && $('.search-notification').val() == '') {
                        $('#currentRequests', window.parent.document).val(totalCount);
                        setMessageCount(totalCount)

                        // if($("#notification-detial").css('display') == 'none') {
                        //     p.$('.chat-notifications #overlay-iframe').attr('src', p.$('.chat-notifications #overlay-iframe').attr('src'));
                        // }

                    }

                // }
                //  console.log('message Count ' + messageCount );


                $('#notification-results .notification-item').each(function() {
                    var to = $(this).attr('data-to');
                    var from = $(this).attr('data-from');

                    var length = resultData.filter(function(value) { return value.m_from == to && value.m_to == from && value.is_read == 0 }).length

                    $(this).find('.button-count').html(length>0?length:'');
                    var allMessages = resultData.filter(function(value) {
                            //return value.m_from == to && value.is_read == 0
                            return (value.m_from == to && value.m_to == from) || (value.m_from == from && value.m_to == to)
                    });

                    var time = 0;
                    var message = '';
                    var msgTime = '';
                        if (allMessages.length) {
                        var message = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['message'];

                        var time = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['tstamp'];


                        var msgTime = allMessages.sort(
                            function(a, b) {
                                return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                            }
                        )[0]['time'];
                    }


                    

                    $(this).find('.button-box').html(message);
                    $(this).find('.time-box').html(msgTime);
                    $(this).attr('data-time', time);
                });

                var sortedHtml = $('#notification-results .notification-item')
                .sort((a,b) => $(b).data("time") - $(a).data("time"))
                $("#notification-results").html(sortedHtml)
            }
        });
    }

    useEffect(() => {
        document.body.classList.add('live');       

        let isSubscribed = true;
        if (isSubscribed) {
            getMessagesFromChat();
            setInterval(getMessagesFromChat, 5000);
        }

        return () => {
            document.body.classList.remove('live');
        }
    }, [])
    
    useEffect(() => {
        if (!authContext.isAuth || !authContext.hasLiveAccess) { return (navigate("/user-login")); }
    }, [authContext.isAuth, authContext.hasLiveAccess])

    useEffect(()=> {
        let isSubscribed = true;

        if(!userInfo) {
            doRequest('React', '/lib/storage/content/Users.json','GET', {}, false, (err, data) => { 
                const uid = decryptedUserId();
                const user = data.filter(users => users.uid === uid);

                if (isSubscribed) {
                    console.log(user);
                    setUserInfo(user);
                    setLoading(false);

                    if(!user.livepage) {

                        doRequest(
                            'Server',
                            '/api/v1/admin/user/userConsent',
                            'POST', 
                            {
                                localId: process.env.REACT_APP_LOCAL_ID,
                                getConsent : 1,
                                uid: decryptedUserId(),
                            },
                            false,
                            (err,data) => {
                                if(data.status) {
                                    console.log(data.third_party_consent, 'consent')
                                    if(parseInt(data.third_party_consent) == 0) {
                                        setShowoverlay('Conset');
                                    } 
                                    setCookie('consent', data.third_party_consent, 365);
                                }
                            }
                        );

                        doRequest(
                            'React',
                            'server.php',
                            'GET', 
                            {
                                localId: process.env.REACT_APP_LOCAL_ID,
                                user: uid,
                                type: 'livePageVist'
                            },
                            false,
                            (err,data) => {}
                        );
                    }
                }
            });
            
        } 
        return () => (isSubscribed = false)
    },[userInfo])
    
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    if(loading) {
        return (<BeatLoader style={style} />);
    }

    return (
        <div className="base-box fixed" id="page-top">
            <MyAgendaProvider value={{ userAgenda, setUserAgenda }}>
                    <StickyLinks />
                    <input type="hidden" id="currUser" value={decryptedData} />
                    <Header setIsLoggedInUser={setIsLoggedInUser} isLoggedInUser={isLoggedInUser} />
                    <div className="base-item" >
                        <Hero />
                        <main className="main-content">
                            <section id="searchResultPlaceHolder" className="modul news-masonry-box"></section>
                            {userInfo && !userInfo[0]?.firstview && <FirstView />}
                            {/* {userInfo && !userInfo[0]?.third_party_consent && setShowoverlay('Conset')} */}
                            
                            {/* <NextLiveStreamUpOnStage /> */}
                            {/* <ProgramProvider value={{ programs, setProgram }}>
                                <AgendaSection />
                                <ProgramSection />
                            </ProgramProvider> */}
                            <Newsroom />
                            <Exhibitor key={'exhibitor'} />
                            <FindYourBisnessPartner />
                            {/* <SponsorsSection />
                            <MediaPartner /> */}
                        </main>
                    </div>                
            </MyAgendaProvider>
            <Footer isExpended={true} />
            <img style={{position: 'absolute' , top : -100 }} src={`${process.env.REACT_APP_SERVER_ENDPOINT}/simplesamlphp/www/setcookie.php?session=${decryptedUserId()}`}  />
        </div>
    );
}