import * as React from "react";

import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import ThanksToSponsersSlider from '../Components/ThanksToSponsersSlider'

import { isLogin, login } from '../utils';
import { AuthContext } from "../Context/AuthContext";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { doRequest } from "../utils/doRequest";
import WithoutLiveAccess from "./WithoutLiveAccess";

export default function UserLogin() {
  const authContext = useContext(AuthContext);
  const loginHandler = () => { authContext.login(); };
  const [email, setEmail] = React.useState();
  const [userState, setUserState] = React.useState(1);
  const [isLoading, setLoading] = useState(0);
  const [loginError, setLoginError] = useState(0);
  const [errorCode, setErrorCode] = useState(-1);
  const [ withoutAccessPage ,showWithoutAccessPage] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/";

  var CryptoJS = require("crypto-js");
  var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function handleSubmit(e) {

    setErrorCode(-2);

    doRequest('React','server.php','GET', {
       email,
       type: "loginEmail"
    },'', (err, data) => {
      let type = 'success';
      if(err) { if(data.error) {type = 'error'} }
      if(data.error) {type = 'error'}
      console.log(data);
      setErrorCode(parseInt(data.errorCode));
      setLoginError(0);
    });


    e.preventDefault();
    return false;
  }

  const hash = new URLSearchParams(location.search).get("hash");
  const status = new URLSearchParams(location.search).get("status");
  

  useEffect(() => {
    
    if (authContext.isAuth) { 
      showWithoutAccessPage(true);      
    }
    
    if (isLogin()) {
      return <Navigate replace to="/live" />;
    }

    if (hash) {
      
      setLoading(1);
      
      doRequest('Server','api/v1/Frontend/user/login','POST', {
        hash: hash,
        status: status
      },'',(err,data) => {
        
        if (data.success) {
            
            let token = data.response.session;
            let uid = data.response.uid.toString();
            setLoading(0);
            if (uid) {
              doRequest('React','/lib/storage/content/Users.json','GET',{},'',(err,data) => {  
                data.forEach((user) => {
                  if (uid == user.uid) {
                    var ciphertext = CryptoJS.AES.encrypt(uid, secretKey).toString();
                    setCookie("fe_typo_user", token);
                    sessionStorage.setItem("token", token);
                    sessionStorage.setItem("auth", ciphertext);
                    localStorage.setItem("contextVar", ciphertext);
                    localStorage.setItem("fe_typo_user", token);
                    loginHandler();
                    navigate("/live");
                  }
                });

              });

            }
        } else {
          setLoading(0);
          setLoginError(1);
        }

      } );

     
    } else {
      // console.log('no hash set');
    }

    document.body.classList.add('countdown');
    return () => {
      document.body.classList.remove('countdown');
    }

  }, []);

  useEffect(() => {
    if (authContext.isAuth) { showWithoutAccessPage(true) }
  }, [authContext.isAuth])

  if (isLoading === 1) {
    return <div className="App">Loading...</div>;
  }
  
  if(withoutAccessPage) {
    return <WithoutLiveAccess />;
  }

  return (
    <div className="base-box login-home" id="page-top">
      <Header />

      <div className="base-item">
        <section className="hero-content flex-box stretch bg-1">
          <div className="item-box flex-box stretch full-size">
            <div className="bg-image">
              <figure className="image-box">
                <img
                  srcSet="/images/bg-live.jpg 760w, /images/bg-live.jpg 1080w"
                  sizes="(max-width: 1600px) 100vw, 1600px"
                  src="images/bg-live.jpg"
                />
              </figure>
            </div>

            <div className="hero-item-box flex-box wrap center-center ">
              <div className="item-box">
                <section className="modul modul-intro-text pd-t12 pd-b4">
                  <div className="item-box">
                    <div className="text-box txt-center">
                      <h3>Live in Munich – Digitally Worldwide</h3>
                      <p>
                        The 12th edition of IP Service World is both a physical and a digital event.
                        <br />
                        The digital platform of IP Service World will be open for you on November 21st at 8:00 am CET.
                      </p>
                    </div>
                  </div>
                </section>
                <section className="modul modul-start-login pd-t0 pd-b4">
                  <section className="modul modul-start-login pd-t0 pd-b4">
                  <div className="item-box">
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div></div>
                      <div className="form-box bg-4  txt-center">
                        <h3>Login User</h3>
                        <div className="form-row flex-box center-center status-state">
                          <div className="field-item standard-radio">
                            <input
                              type="radio"
                              name="user-online"
                              checked={userState == 1}
                              id="on-site"
                              value="1"
                              onChange={(e) => setUserState(e.target.value)}
                            />
                            <label htmlFor="on-site">{process.env.REACT_APP_LOGIN_LABEL_1}</label>
                          </div>
                          <div className="field-item standard-radio">
                            <input
                              type="radio"
                              name="user-online"
                              id="remote"
                              value="2"
                              checked={userState == 2}
                              onChange={(e) => setUserState(e.target.value)}
                            />
                            <label htmlFor="remote">{process.env.REACT_APP_LOGIN_LABEL_2}</label>
                          </div>
                        </div>

                        <div className="form-row flex-box center-center login-input">
                          <div className="field-item txt-left">
                            <input
                              type="email"
                              className="required-field"
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder=" "
                              required="required"
                            />
                            <label className="field-label" htmlFor="user-email">
                              E-Mail <span className="required-label">*</span>
                            </label>
                          </div>
                          <div className="field-item">
                            <button
                              className="button call-to-action login"
                              name="tx_user_login[submit]"
                              type="submit"
                            >
                              <span className="button-label">Login</span>
                              <span className="icon-box">
                                <i className="icon"></i>
                              </span>
                            </button>
                          </div>
                        </div>

                        {(() => {
                          if (errorCode === 101) {
                            return (
                              <p className="info-text validation-box invalid">
                                This email is not registered. Please register
                                here if you want to take part in the Traderfair.
                                <span className="icon-box">
                                  <i className="icon"></i>
                                </span>
                              </p>
                            );
                          } else if (errorCode === 0) {
                            return (
                              <p className="info-text validation-box valid">
                                Your Login email is sent, please check your
                                Email Account and klick on the Login Link
                                <span className="icon-box">
                                  <i className="icon"></i>
                                </span>
                              </p>
                            );
                          } else {
                            return "";
                          }
                        })()}

                        {loginError === 1 ? (
                          <p className="info-text validation-box invalid">
                            This Login-Link has expired. Request a new one!
                            <span className="icon-box">
                              <i className="icon"></i>
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                        <p className="info-text txt-left small-box">
                          * Please enter your email address and press “Login”.
                          You will then receive an email with the access link.
                          This link can only be used once and it cannot be
                          transferred. For return visits please repeat this
                          process. Incase you do not receive the email please
                          check your spam folder.
                        </p>
                      </div>
                    </form>
                  </div>
                  </section>
                  <section className="modul modul-image-slider big-box pd-t0 pd-b5">
                    <ThanksToSponsersSlider />
                  </section>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}
