import * as React from "react";

import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import ThanksToSponsersSlider from '../Components/ThanksToSponsersSlider'

import { isLogin, login } from '../utils';
import { AuthContext } from "../Context/AuthContext";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { doRequest } from "../utils/doRequest";
import SmallHero from "../Components/SmallHero";

export default function ExhibitorLogin() {

  const authContext = useContext(AuthContext);

  const loginHandler = () => { authContext.login(); };

  const [email, setEmail] = React.useState();
  const [userState, setUserState] = React.useState();
  const [isLoading, setLoading] = useState(0);
  const [loginError, setLoginError] = useState(0);

  const [errorCode, setErrorCode] = useState(-1);

  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/";

  var CryptoJS = require("crypto-js");
  var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function handleSubmit(e) {

    setErrorCode(-2);

    doRequest('React','server.php','GET', {
       email,
       type: "loginEmail"
    },'', (err, data) => {
      let type = 'success';
      if(err) { if(data.error) {type = 'error'} }
      if(data.error) {type = 'error'}
    
      setErrorCode(data.errorCode);
      setLoginError(0);
    });


    e.preventDefault();
    return false;
  }

  const hash = new URLSearchParams(location.search).get("hash");
  const status = new URLSearchParams(location.search).get("status");

  useEffect(() => {
    
    if (isLogin()) {
      return (navigate("/live"));
    }

    if (hash) {
      
      setLoading(1);
      
      doRequest('Server','api/v1/Frontend/user/login','POST', {
        hash: hash,
        status: status
      },'',(err,data) => {
        
        if (data.success) {
            
            let token = data.response.session;
            let uid = data.response.uid.toString();
            setLoading(0);
            if (uid) {
              doRequest('React','/lib/storage/content/Users.json','GET',{},'',(err,data) => {  
                data.forEach((user) => {
                  if (uid == user.uid) {
                    var ciphertext = CryptoJS.AES.encrypt(uid, secretKey).toString();
                    setCookie("fe_typo_user", token);
                    sessionStorage.setItem("token", token);
                    sessionStorage.setItem("auth", ciphertext);
                    localStorage.setItem("contextVar", ciphertext);
                    loginHandler();
                    navigate("/live");
                  }
                });

              });

            }
        } else {
          setLoading(0);
          setLoginError(1);
        }

      } );

     
    } else {
      // console.log('no hash set');
    }

    document.body.classList.add('backend');
    return () => {
      document.body.classList.remove('backend');
    }

  }, []);

  if (isLoading === 1) {
    return <div className="App">Loading...</div>;
  }

  return (
    <div className="base-box login-home" id="page-top">
      <Header />

      <div className="base-item cf">
            <SmallHero />
            <main className="main-content">
                <div className="tx-exhibitor">
                  <section className="modul login-box">
                    <div className="item-box pd-5 flex-box center-center">      
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="form-box txt-center">
                        <div className="block-style">Login Exhibitor</div>
                      <div className="form-row small-box flex-box center-center">
                      <div className="field-item txt-left">
                        <input type="email" id="user-email" className="required-field" onChange={(e) => setEmail(e.target.value)} placeholder=" " required="" />
                        <label className="field-label" for="user-email">E-Mail <span className="required-label">*</span></label>
                        </div>
                        <div className="field-item btn-field-item">
                        <button className="call-to-action" name="tx_exhibitor_loginex[submit]" type="submit"><span className="button-label">Login</span><span className="icon-arrow-right"></span></button>
                      </div>
                    </div>
                      
                        {(() => {
                          if (errorCode === 101) {
                            return (
                              <p className="info-text validation-box invalid">
                                This email is not registered. Please register
                                here if you want to take part in the Traderfair.
                                <span className="icon-box">
                                  <i className="icon"></i>
                                </span>
                              </p>
                            );
                          } else if (errorCode === 0) {
                            return (
                              <p className="info-text validation-box valid">
                                Your Login email is sent, please check your
                                Email Account and klick on the Login Link
                                <span className="icon-box">
                                  <i className="icon"></i>
                                </span>
                              </p>
                            );
                          } else {
                            return "";
                          }
                        })()}

                        {loginError === 1 ? (
                          <p className="info-text validation-box invalid">
                            This Login-Link has expired. Request a new one!
                            <span className="icon-box">
                              <i className="icon"></i>
                            </span>
                          </p>
                        ) : (
                          ""
                        )}

                      <p className="info-text txt-left">Please enter your email address and press “Login”. You will then receive an email with the access link. This link can only be used once and it cannot be transferred. For return visits please repeat this process. Incase you do not receive the email please check your spam folder.</p>
                      </div>
                    </form>
                </div>
              </section>
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}
