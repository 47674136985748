import React, {createContext, useEffect, useState} from "react";
export const ProfileContext = createContext({
    profile: {},
    setProfile: () => {}
});

const ProfileContextProvider = props => {
    const [profile, setProfile] = useState({});

    return (
        <ProfileContext.Provider value={{profile: profile, setProfile: setProfile}}>
            {props.children}
        </ProfileContext.Provider>
    )
}

export default ProfileContextProvider;