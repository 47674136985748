import React, { useContext, useEffect, useRef, useState } from "react";
import { ProfileContext } from "../Context/ProfileContext";
import { doRequest } from "../utils/doRequest";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { cryptedUserId } from "../utils";

import { toast } from "react-toastify";
import { redirect, useNavigate } from "react-router-dom";
import { LoadingContext } from "../Context/LoadingContext";

var _URL = window.URL || window.webkitURL;

const getFilesize = async function(file) {
  console.log(file);
  var img1 = new Image();
  img1.src = _URL.createObjectURL(file.files[0]);

  let promise = new Promise((resolve, reject) => {
      img1.onload = function() {
          resolve({width:this.width, height:this.height})
      }
  });

  let result = await promise;
  return result;
}

const editorConfiguration = {
	toolbar: [
	  "bold",
	],
};

export default function CompanyProfile() {
  const { setLoading } = useContext(LoadingContext);
  const { profile, setProfile } = useContext(ProfileContext);
  const [ activeCmp , setActiveCmp ] = useState(0);


  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState("");

  function toggle(value) {
    if (showMenu != value) {
      setShowMenu(value);
    } else {
      setShowMenu("");
    }
  }

  const [compantDetails, setCompanyDetail] = useState();
  const [logoFile , setLogoFile ] = useState();
  
  const [logoTitle, setLogoTitle] = useState('');
  const [headerFile , setHeaderFile] = useState();
  const [headerTitle, setHeaderTitle ] = useState('');
  const [pdfFile1, setPdfFile1] = useState();
  const [pdfFile1Title, setPdfFile1Title ] = useState('');
  const [whitepaper1Title, setwhitepaper1Title] = useState('');
  const [ pdf1Description, setPdf1Description] = useState('');
  

  const [pdfFile2, setPdfFile2] = useState(); 
  const [pdfFile2Title, setPdfFile2Title ] = useState('');

  const [whitepaper2Title, setwhitepaper2Title] = useState('');
  const [ pdf2Description, setPdf2Description] = useState('');

  const [ articleFile, setArticleFile ] = useState();
  const [ articleFileTitle, setArticleFileTitle ] = useState();

  const [ articleTitle, setArticleTitle ] = useState();
  const [ articleInto, setArticleInto ] = useState('');
  const [ article, setArticle ] = useState();

  const [exhibitorAddress ,setExhibitorAddress ] = useState('');
  const [exhibitorPostal , setExhibitorPostal ] = useState('');
  const [exhibitorCity , setExhibitorCity ] = useState('');
  const [exhibitorCountry , setExhibitorCountry ] = useState('');
  const [exhibitorPhone , setExhibitorPhone ] = useState('');
  const [exhibitorWebsite , setExhibitorWebsite ] = useState('');
  const [exhibitorImpressum , setExhibitorImpressum ] = useState('');
  const [exhibitorGooglemaps , setExhibitorGooglemaps ] = useState('');
  const [companyActivities , setCompanyActivities ] = useState('');
  
  const [pdf1topic, setPdf1Topic] = useState([]);
  const [pdf2topic, setPdf2Topic] = useState([]);
  const [articleTopics, setArticleTopic] = useState([]);

  const [ showRoomAdmin, setShowRoomAdmin ] = useState([]);
  const [ showVideoChatAgent, setShowVideoChatAgent ] = useState([]);

  const [showRoomAdminFirstName, setShowRoomAdminFirstName] = useState('');
  const [showRoomAdminLastName, setShowRoomAdminLastName] = useState('');
  const [showRoomAdminEmail, setShowRoomAdminEmail] = useState('');

  const [digitalCompanyContactFirstName, setDigitalCompanyContactFirstName] = useState();
  const [digitalCompanyContactLastName, setDigitalCompanyContactLastName] = useState();
  const [digitalCompanyContactEmail, setDigitalCompanyContactEmail] = useState();
  const [serverError, setError] = useState({});

  useEffect(() => {
    setExhibitorAddress(compantDetails?.company?.companyaddress);
    setExhibitorPostal(compantDetails?.company?.companypostal);
    setExhibitorCity(compantDetails?.company?.companycity);
    setExhibitorCountry(compantDetails?.company?.companycountry);
    setExhibitorPhone(compantDetails?.company?.companyphone);
    setExhibitorWebsite(compantDetails?.company?.companywebsite);
    setExhibitorImpressum(compantDetails?.company?.companyimpressumlink);
    setExhibitorGooglemaps(compantDetails?.company?.googlemaplink)
    setCompanyActivities(compantDetails?.company?.aboutcompany);
    setArticleInto(compantDetails?.company?.articleintro);
    setArticleTitle(compantDetails?.company?.articletitle);
    setArticle(compantDetails?.company?.articledescription);
    setPdf1Topic(compantDetails?.topics?.pdf1Topics);
    setPdf2Topic(compantDetails?.topics?.pdf2Topics);
    setArticleTopic(compantDetails?.topics?.articleTopics);
    
    setShowRoomAdmin(compantDetails?.company?.showroomadmin ?? []);
    setShowVideoChatAgent(compantDetails?.company?.videochatagent ?? []);

    compantDetails?.ourContent.map((content) => {
      if(content.type == 'whitepaper' && content.number == 1) {
        setPdf1Description(content.pdfdescription);
        setwhitepaper1Title(content.pdftitle);
      }
      if(content.type == 'whitepaper' && content.number == 2) {
        setPdf2Description(content.pdfdescription);
        setwhitepaper2Title(content.pdftitle);
      }
    });
  }, [compantDetails])

  useEffect(() => {

    if(profile && profile.usergroup) {
      let usrgrp = profile.usergroup.split(',');
      if(usrgrp.includes('1')) {        
        doRequest('React', 'lib/storage/content/exhibitor.json','GET', {}, true, (err,data) => {
          const company_id = Object.values(data.companies).filter(element => element.exhibitor == profile.uid)[0]['uid'];
          setActiveCmp(company_id);
          doRequest('React', 'lib/storage/company/'+ company_id +'.json', 'GET', {}, true, (err,data) => {
            setCompanyDetail(data);
          })
        });  
      } else if(profile.company_id) {
          setActiveCmp(profile.company_id);
          doRequest('React', 'lib/storage/company/'+ profile.company_id +'.json', 'GET', {}, true, (err,data) => {
            setCompanyDetail(data);
          })
      } else {
        // show below message
        // You don't have access to this page
      }
      
    }

  }, [profile])


  function saveCompanySpace() {

    var data = new FormData();

    if(logoFile) { data.append("logo-file", logoFile); }
    if(headerFile) { data.append("header-file", headerFile); }
    if(pdfFile1) { data.append("pdf-file1", pdfFile1 ); }
    if(pdfFile2) { data.append("pdf-file2", pdfFile2 ); }
    if(articleFile) { data.append("article-file", articleFile ); }

    data.append("isjson", true);
    const pdf1topicVal = pdf1topic.filter((expert) => expert.checked == true ).map(({uid}) => uid);
    const pdf2topicVal = pdf2topic.filter((expert) => expert.checked == true ).map(({uid}) => uid);
    const articeTopicsVal = articleTopics.filter((expert) => expert.checked == true ).map(({uid}) => uid);

    let params = {
      "userid": cryptedUserId(),
      "fields" : {
        "exhibitor-address": exhibitorAddress,
        "exhibitor-postal": exhibitorPostal,
        "exhibitor-city": exhibitorCity,
        "exhibitor-country" : exhibitorCountry,
        "exhibitor-phone" : exhibitorPhone,
        "exhibitor-website" : exhibitorWebsite,
        "exhibitor-impressum" : exhibitorImpressum,
        "exhibitor-googlemaps" : exhibitorGooglemaps,
        "company-activities" : companyActivities,
        "article-title" : articleTitle,
        "article-intro" : articleInto,
        "article" : article,
        "whitepaper1-title" : whitepaper1Title,
        "whitepaper2-title" : whitepaper2Title,
        "pdf1-description" : pdf1Description,
        "pdf2-description" : pdf2Description,
        "whitepaper1-topic" : pdf1topicVal,
        "whitepaper2-topic" : pdf2topicVal,
        "article-topic" : articeTopicsVal
      }
    };

    data.append("fields", JSON.stringify(params));
    setLoading(true);
    doRequest('Server','/api/v1/Frontend/company/updateCompany','XHR', 
        data
      , true, (err, data) => {
        if(data) {
          if(data.length){
            setError(JSON.parse(data));            
          }
          setLoading(false);
          setProfile({...profile, is_online: Date.now()})
        }
    })
  }

  function previewCompanyspace() {
    saveCompanySpace();
    return navigate('/exhibitor-preview/' + activeCmp );
  }                                       

  function removeImage(fileName) {
    if (window.confirm('Are you sure?')) {
      setLoading(true);
      doRequest('Server', 'api/v1/Frontend/company/deleteCompanyImage','POST', 
      {
        "userid": cryptedUserId(),
        "fields": {
          "remove-file" : fileName 
        }
      }, true, (err, data) => {
        setLoading(false);
        setProfile({...profile, is_online: Date.now()})      
      })
    }
  }

  function addShowRoomAdmin() {
    if(showRoomAdminEmail) {
      setLoading(true);
      doRequest('Server', '/api/v1/Frontend/user/manageAgents','POST', 
      {
        "userid": cryptedUserId(),
        "fields": {
          "firstname" : showRoomAdminFirstName,
          "lastname" : showRoomAdminLastName,
          "email" : showRoomAdminEmail, 
          "type" : 2
        }
      }, true, (err, data) => {
        console.log(data);
        setLoading(false);
        if (data == 0) {
            alert('User already exist / Max user limit reached');
            return;
        } else if (data == -1) {
            alert('User is already associated with other company');
            return;
        } else if (data == -4) {
          alert('User is already exhibitor (master admin) of this company');
          return;
        } else if (data == -2) {
            alert('User already exist / Max user limit reached');
            return;
        }
    
        
        setShowRoomAdmin([...showRoomAdmin, {
          "firstname" : showRoomAdminFirstName,
          "lastName" : showRoomAdminLastName,
          "email" : showRoomAdminEmail,
          "uid" : data
        }]);

        setShowRoomAdminFirstName('');
        setShowRoomAdminLastName('');
        setShowRoomAdminEmail('');
      });
    } else {
      alert('Please fill required fields(marked with *)')
    }
  }

  function addDigitalCompanyContact() {
    if(digitalCompanyContactEmail) {  
      setLoading(true);
      doRequest('Server', '/api/v1/Frontend/user/manageAgents','POST', 
      {
        "userid": cryptedUserId(),
        "fields": {
          "firstname" : digitalCompanyContactFirstName,
          "lastname" : digitalCompanyContactLastName,
          "email" : digitalCompanyContactEmail,
          "type" : 3
        }
      }, true, (err, data) => {
        setLoading(false);
        if (data == 0) {
            alert('User already exist / Max user limit reached');
            return;
        } else if (data == -1) {
            alert('User is already associated with other company');
            return;
        } else if (data == -4) {
          alert('User is already exhibitor (master admin) of this company');
          return;
        } else if (data == -2) {
            alert('User already exist / Max user limit reached');
            return;
        }
    

        setShowVideoChatAgent([...showVideoChatAgent, {
          "firstName" : digitalCompanyContactFirstName,
          "lastName" : digitalCompanyContactLastName,
          "email" : digitalCompanyContactEmail,
          "uid" : data
        }]);

        setDigitalCompanyContactFirstName('');
        setDigitalCompanyContactLastName('');
        setDigitalCompanyContactEmail('');
        
      });
    } else {
      alert('Please fill required fields(marked with *)')
    }
  }

  function removeAgent(uid, type) {
    if (window.confirm('Are you sure?')) {
      setLoading(true);
      doRequest('Server', '/api/v1/Frontend/user/removeAgents','POST', 
      {
        "userid": cryptedUserId(),
        "fields": {
          "id" : uid,
          "type" : type
        }
      }, true, (err, data) => {
        setLoading(false);
        if(type == 3) {
          let newVideoChatAgent = showVideoChatAgent.filter(props => props.uid != uid);
          setShowVideoChatAgent(newVideoChatAgent);
        }
        if(type == 2) { 
          let newShowroomAdmin = showRoomAdmin.filter(props => props.uid != uid);
          setShowRoomAdmin(newShowroomAdmin);
        }
      });
    }
  }

  const totalExpertisepdf1topic = () => pdf1topic.filter(props => props.checked).length;
  const totalExpertisepdf2topic = () => pdf2topic.filter(props => props.checked).length;
  const totalExpertiseArticle = () => articleTopics.filter(props => props.checked).length;
  

  function handleTopicChange(e) {
    console.log(e.target.className);
    if(e.target.className == 'whitepaper1-topic') {
      if(totalExpertisepdf1topic() < process.env.REACT_APP_MAX_TOPIC_ALLOWED || !e.target.checked) {
        const newState = pdf1topic.map(obj => {
          if(obj.uid == e.target.value) {
            return { ...obj, checked : e.target.checked };
          }
          return obj;
        })
        setPdf1Topic(newState);
      }
    } else if( e.target.className == 'whitepaper2-topic') {
      
      if(totalExpertisepdf2topic() < process.env.REACT_APP_MAX_TOPIC_ALLOWED || !e.target.checked) {
        const newState = pdf2topic.map(obj => {
          if(obj.uid == e.target.value) {
            return { ...obj, checked : e.target.checked };
          }
          return obj;
        })
        setPdf2Topic(newState);
      }
    } else if( e.target.className == 'article-topic' ) {
      if(totalExpertiseArticle() < process.env.REACT_APP_MAX_TOPIC_ALLOWED || !e.target.checked) {
        const newState = articleTopics.map(obj => {
          if(obj.uid == e.target.value) {
            return { ...obj, checked : e.target.checked };
          }
          return obj;
        })
        setArticleTopic(newState);
      }
    }
  }

  function handleFileInputChagne(e) {
    e.preventDefault();
    
    if(e.target.id == 'logo-file') {


      getFilesize(e.target).then(function(res){
        let fileItem = e.target.files.item(0);
        var fileType = fileItem.name.replace(/.*\./, '').toLowerCase();
        let fsize = fileItem.size;
        let filekb = Math.round(fsize / 1024);
        var logoExtensions = ["jpg", "jpeg"];

        setError(error => ({
          ...error,
          "logo-file":""
        }));

        if (res.width > 500 || res.height > 500) {
            setError(error => ({
              ...error,
              "logo-file":"Max logo size is 500 X 500"
            }));
        } else if (filekb > 150) {
            setError(error => ({
              ...error,
              "logo-file":"Logo size must not exceed 150KB"
            }));

        } else if (logoExtensions.indexOf(fileType) < 0) {
            setError(error => ({
              ...error,
              "logo-file":"Please upload valid logo image"
            }));
        }
      });

      
      setLogoFile(e.target.files[0]);
      setLogoTitle("Dateiname: " + e.target.value.split( '\\' ).pop())
    } else if (e.target.id == 'header-file') {

      getFilesize(e.target).then(function(res){
        let fileItem = e.target.files.item(0);
        var fileType = fileItem.name.replace(/.*\./, '').toLowerCase();
        let fsize = fileItem.size;
        let filekb = Math.round(fsize / 1024);
        let filemb = Math.round(filekb / 1024);
        var logoExtensions = ["jpg", "jpeg"];

        setError(error => ({
          ...error,
          "header-file":""
        }));

        if (filemb > 5) {
            setError(error => ({
              ...error,
              "header-file":"Header image size must not exceed 5MB"
            }));
        } else if (logoExtensions.indexOf(fileType) < 0) {
            setError(error => ({
              ...error,
              "header-file":"Please upload valid header image"
            }));
        }
      });

      setHeaderFile(e.target.files[0]);
      setHeaderTitle("Dateiname: " + e.target.value.split( '\\' ).pop())
    } else if (e.target.id == 'pdf-file1') {
      setPdfFile1(e.target.files[0]);
      setPdfFile1Title("Dateiname: " + e.target.value.split( '\\' ).pop())
    } else if (e.target.id == 'pdf-file2') {
      setPdfFile2(e.target.files[0]);
      setPdfFile2Title("Dateiname: " + e.target.value.split( '\\' ).pop())
    } else if (e.target.id == "article-file") {

      getFilesize(e.target).then(function(res){
        let fileItem = e.target.files.item(0);
        var fileType = fileItem.name.replace(/.*\./, '').toLowerCase();
        let fsize = fileItem.size;
        let filekb = Math.round(fsize / 1024);
        let filemb = Math.round(filekb / 1024);
        var logoExtensions = ["jpg", "jpeg"];

        setError(error => ({
          ...error,
          "article-file":""
        }));
        
        if (filemb > 3) {
            setError(error => ({
              ...error,
              "article-file":"Article file must not be more than 3MB"
            }));

        } else if (logoExtensions.indexOf(fileType) < 0) {
            setError(error => ({
              ...error,
              "article-file":"Please upload valid article image"
            }));
        }
      });

      setArticleFile(e.target.files[0])
      setArticleFileTitle("Dateiname: " + e.target.value.split( '\\' ).pop());
    }
  }

  return (
    <div className="item-box pd-5 position-static" id="frmcompanyprofile">
      <div className="tab-content" id="personal-data-content">
        <div className="form-box txt-center">
            <div
              className="button-box form-row flex-box sticky-box"
              id="sticky-box"
            >
              <div className="field-item left-item">
                <button
                  className="button ghost tab-next previous"
                  name="tx_exhibitor_editexhibitor[preview-exhibitor]"
                  value="preview"
                  onClick={previewCompanyspace}
                >
                  <span className="button-label">Preview Company Space</span>
                </button>
              </div>
              <div className="field-item right-item">
                <button
                  className="button call-to-action show-loader"
                  onClick={() => saveCompanySpace({redirect : true})}
                >
                  <span className="button-label">Save Company Space</span>
                </button>
              </div>
            </div>
            <h3>Company Data</h3>
            <h4 className="no-margin">
              Welcome to the configurator of your IP Service World Company
              Space.{" "}
            </h4>
            <p className="info-text long-info-text" style={{marginBottom: '2rem'}}>
              This is the place where you add admins and contact personnel. Here
              is where you can edit your blogarticle, upload pictures and
              whitepapers. And by using the tab “Leads and Analytics” you can
              monitor the performance of your Company Space.{" "}
            </p>
            <p className="info-text long-info-text" style={{marginBottom: '2rem'}}>
              <strong>Note:</strong> Quality Content that is interesting to your
              visitors will make your Company Space successful because each
              interaction with your content equals a lead.
            </p>
            <p className="info-text long-info-text" style={{marginBottom: '2rem'}}>
              For more info see{" "}
              <a href="/faq#exhibitor-tutorial" target="_blank">
                IP Service World FAQ and exhibitor tutorial.
              </a>
            </p>
          
            {Object.keys(Object.entries(serverError).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {})).length ? (
              <div className="errorDiv" id="run-error">
                <p>Errors</p>
                <ul>
                  {serverError['logo-file'] ? (<li>{serverError['logo-file']}</li>) : '' }
                  {serverError['header-file'] ? (<li>{serverError['header-file']}</li>) : '' }
                  {serverError['article-file'] ? (<li>{serverError['article-file']}</li>) : '' }
                </ul>
              </div>
            ): ''}
            
            <h4 className="accordion-title selector">
              <span>Company Information</span>
              <p className="description no-margin">
                Here you can add your logo, a header Image, your address and a
                short company description.
              </p>
            </h4>
            <div className="accordion-content">
              <div className="form-row flex-box wrap">
                <div className={`field-item txt-left box-50 ${serverError['logo-file']? 'error': ''}`}>
                  <div className="field-item-wrapper">
                    <label className="block-label">
                      Upload your Logo image.{" "}
                      <span className="description">
                        Please upload files only as JPG upload
                        <br /> - max. Size: 500x500px, 150KB
                      </span>
                    </label>
                    <div className="upload-container">
                      <figure className="user-image-preview flex-box center-left">
                      {(compantDetails?.company?.logo) ? (
                        <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + compantDetails.company.logo} />
                      ):(
                        <img src='/images/icon-image-round.svg' />
                      )}
                      </figure>
                      {(compantDetails?.company?.logo) ? (
                        <a
                          className="remove-company-file delete delete-item"
                          onClick={() => removeImage('logo-file')}
                        >
                          Remove logo image
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 32 32"
                          >
                            <path d="M4 10v20c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V10H4zm6 18H8V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zM26.5 4H20V1.5c0-.825-.675-1.5-1.5-1.5h-7c-.825 0-1.5.675-1.5 1.5V4H3.5C2.675 4 2 4.675 2 5.5V8h26V5.5c0-.825-.675-1.5-1.5-1.5zM18 4h-6V2.025h6V4z"></path>
                          </svg>
                        </a>
                      ):(<></>)}
                      <input
                        data-multiple-caption="{$count} files selected"
                        data-error-msg-mime="Only allow jpg"
                        data-error-msg-size="File size not more than 150KB"
                        className="inputfile"
                        type="file"
                        name="tx_exhibitor_editexhibitor[logo-file]"
                        id="logo-file"
                        onChange={handleFileInputChagne}
                      />
                      <label for="logo-file" className="label-fileupload">
                        <strong>Logo Upload</strong>
                        <span className="data-title"></span>
                        <span className="ext-data-title"></span>
                        <span className="ext-data-title" id="data-title" >{logoTitle}</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className={`field-item txt-left box-50 ${serverError['header-file']? 'error': ''}`}>
                  <div className="field-item-wrapper">
                    <label className="block-label">
                      Header Image
                      <span className="description">
                        Please upload files only as JPG,
                        <br /> min. width 1800px, max 3 MB
                      </span>
                    </label>
                    <div className="upload-container">
                      <figure className="user-image-preview flex-box center-left">
                        {(compantDetails?.company?.headerimage) ? (
                          <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + compantDetails.company.headerimage} />
                        ):(
                          <img src='/images/icon-image-round.svg' />
                        )}
                      </figure>
                      {(compantDetails?.company?.headerimage) ? (
                      <a
                        className="remove-company-file delete delete-item"
                        onClick={() => removeImage('header-file')}
                      >
                        Remove header image
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 32 32"
                        >
                          <path d="M4 10v20c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V10H4zm6 18H8V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zM26.5 4H20V1.5c0-.825-.675-1.5-1.5-1.5h-7c-.825 0-1.5.675-1.5 1.5V4H3.5C2.675 4 2 4.675 2 5.5V8h26V5.5c0-.825-.675-1.5-1.5-1.5zM18 4h-6V2.025h6V4z"></path>
                        </svg>
                      </a>
                      ): ''}
                      <input
                        data-multiple-caption="{$count} files selected"
                        data-error-msg-mime="Only allow jpg"
                        data-error-msg-size="File size not more than 150KB"
                        className="inputfile"
                        id="header-file"
                        type="file"
                        onChange={handleFileInputChagne}
                      />
                      <label for="header-file" className="label-fileupload">
                        <strong>Header Image Upload</strong>
                        <span className="data-title"></span>
                        <span className="ext-data-title">{headerTitle}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row col-box wrap col-3">
                <div className="col-item field-item txt-left box-33">
                  <input
                    type="text"
                    id="exhibitor-company-name"
                    placeholder=" "
                    value={compantDetails?.company?.companyname}
                    className="disabled-field bg-4"
                    disabled=""
                  />
                  <label className="field-label" for="exhibitor-company-name">
                    Company Name
                  </label>
                </div>
                <div className="col-item field-item txt-left box-33">
                  <input
                    type="text"
                    id="exhibitor-address"
                    placeholder=" "
                    className="standard-field bg-4"
                    onChange={(e) => setExhibitorAddress(e.target.value)}
                    value={exhibitorAddress}
                  />
                  <label className="field-label" for="exhibitor-address">
                    Street/Number
                  </label>
                </div>
                <div className="col-item field-item txt-left box-33">
                  <input
                    type="text"
                    id="exhibitor-postal"
                    placeholder=" "
                    className="standard-field bg-4"
                    onChange={(e) => setExhibitorPostal(e.target.value)}
                    value={exhibitorPostal}
                  />
                  <label className="field-label" for="exhibitor-postal">
                    Postal Code
                  </label>
                </div>
                <div className="col-item field-item txt-left box-33">
                  <input
                    type="text"
                    id="exhibitor-city"
                    placeholder=" "
                    className="standard-field bg-4"
                    onChange={(e) => setExhibitorCity(e.target.value)}
                    value={exhibitorCity}
                  />
                  <label className="field-label" for="exhibitor-city">
                    City
                  </label>
                </div>
                <div className="col-item field-item txt-left box-33">
                  <input
                    type="text"
                    id="exhibitor-country"
                    placeholder=" "
                    className="standard-field bg-4"
                    onChange={(e) => setExhibitorCountry(e.target.value)}
                    value={exhibitorCountry}
                  />
                  <label className="field-label" for="exhibitor-country">
                    Country
                  </label>
                </div>
                <div className="col-item field-item txt-left box-33">
                  <input
                    type="text"
                    id="exhibitor-phone"
                    placeholder=" "
                    className="standard-field bg-4"
                    onChange={(e) => setExhibitorPhone(e.target.value)}
                    value={exhibitorPhone}
                  />
                  <label className="field-label" for="exhibitor-phone">
                    Phone (+49 XXX XXXXXX)
                  </label>
                </div>
                <div className="col-item field-item txt-left box-33">
                  <input
                    type="text"
                    id="exhibitor-website"
                    placeholder=" "
                    className="standard-field bg-4"
                    onChange={(e) => setExhibitorWebsite(e.target.value)}
                    value={exhibitorWebsite}
                  />
                  <label className="field-label" for="exhibitor-website">
                    Website
                  </label>
                </div>
                <div className="col-item field-item txt-left box-33">
                  <input
                    type="text"
                    id="exhibitor-impressum"
                    placeholder=" "
                    className="standard-field bg-4"
                    onChange={(e) => setExhibitorImpressum(e.target.value)}
                    value={exhibitorImpressum}
                  />
                  <label className="field-label" for="exhibitor-impressum">
                    Company Impressum Link
                  </label>
                </div>
                <div className="col-item field-item txt-left box-33">
                  <input
                    type="text"
                    id="exhibitor-googlemaps"
                    placeholder=" "
                    className="standard-field bg-4"
                    onChange={(e) => setExhibitorGooglemaps(e.target.value)}
                    value={exhibitorGooglemaps}
                  />
                  <label className="field-label" for="exhibitor-googlemaps">
                    Link to Google Maps Location
                  </label>
                  <button className="button info open-tooltip"></button>
                  <div className="tooltip">
                    1. Enter your company address in Google Maps <br />
                    2. Click on the icon "Share"
                    <br />
                    3. Click on the tab "Embed maps"
                    <br />
                    4. Click on "Copy HTML"
                    {/* <iframe....><br />5. Paste the link into the field here</iframe....> */}
                  </div>
                </div>
              </div>
              <div className="form-row flex-box wrap pd-t4">
                <div className="field-item txt-left box-100">
                  <label className="block-label" for="company-activities">
                    What do you want visitors to know about your company?
                    Describe your company‘s offering in a short paragraph.
                    <span className="description">(max. 1500 characters)</span>
                  </label>
                  <CKEditor
                    id="editor"
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={companyActivities?companyActivities:''}
                    onBlur={(event, editor) => {
                      setCompanyActivities(editor.getData());
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="field-item txt-centert box-100">
              <div className="field-item-wrapper">
                <h4 className="accordion-title selector">
                  <span>Exhibitor Company Space Admin</span>
                  <p className="description no-margin">
                    In order to add additional Company Space Admins (who then
                    can also configure the Company Space) please fill the form
                    below. <br />
                    When you add a new Admin User, that person will
                    automatically receive an email with an explanation the login
                    information.
                  </p>
                </h4>
                <div className="accordion-content">
                  <table className="admin-user-table showroom-admin-table">
                    <tbody>
                      <tr>
                        <td className="cl-2 strong title">Master Admin:</td>
                        <td>{profile?.first_name} {profile?.middle_name} {profile?.last_name}, {profile.email}</td>
                        <td>
                          <span className="no-edit"></span>
                        </td>
                      </tr>
                      {showRoomAdmin?.map((agent) => (
                        (profile?.uid != agent.uid)?(
                          <tr>
                          <td className="title">Admin:</td>
                          <td>{agent.firstName} {agent.lastName}, {agent.email}</td>
                          <td>
                            <button
                              type="button"
                              className="delete"
                              data-id={agent.uid}
                              data-type="2"
                              onClick={() => removeAgent(agent.uid, 2)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                              >
                                <path d="M4 10v20c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V10H4zm6 18H8V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zM26.5 4H20V1.5c0-.825-.675-1.5-1.5-1.5h-7c-.825 0-1.5.675-1.5 1.5V4H3.5C2.675 4 2 4.675 2 5.5V8h26V5.5c0-.825-.675-1.5-1.5-1.5zM18 4h-6V2.025h6V4z"></path>
                              </svg>
                            </button>
                          </td>
                        </tr>): ''
                      ))}
                    </tbody>
                  </table>
                  <h6 className="txt-left">Add Company Space Admin</h6>
                  <div className="form-row flex-box wrap auto-size">
                    <div className="field-item txt-left">
                      <input
                        type="text"
                        id="showroom-admin-firstname"
                        placeholder=" "
                        className="standard-field bg-4"
                        name="tx_exhibitor_editexhibitor[showroom-admin-firstname][]"
                        value={showRoomAdminFirstName}
                        onChange={(e) => setShowRoomAdminFirstName(e.target.value)}
                      />
                      <label className="field-label" for="showroom-admin-firstname">
                        Firstname
                      </label>
                    </div>
                    <div className="field-item txt-left">
                      <input
                        type="text"
                        id="showroom-admin-lastname"
                        placeholder=" "
                        className="standard-field bg-4"
                        name="tx_exhibitor_editexhibitor[showroom-admin-lastname][]"
                        value={showRoomAdminLastName}
                        onChange={(e) => setShowRoomAdminLastName(e.target.value)}
                      />
                      <label className="field-label" for="showroom-admin-lastname">
                        Lastname
                      </label>
                    </div>
                    <div className="field-item txt-left">
                      <input
                        type="email"
                        id="showroom-admin-email"
                        className="required-field"
                        name="tx_exhibitor_editexhibitor[showroom-admin-email][]"
                        placeholder=" "
                        value={showRoomAdminEmail}
                        onChange={(e) => setShowRoomAdminEmail(e.target.value)}
                      />
                      <label className="field-label" for="showroom-admin-email">
                        E-Mail <span className="required-label">*</span>
                      </label>
                    </div>
                    <div className="field-item txt-left">
                      <button
                        type="button"
                        className="button add add-showroom-admin"
                        onClick={addShowRoomAdmin}
                        data-type="2"
                      ></button>
                    </div>
                  </div>
                </div>
                <h4 className="accordion-title selector">
                  <span>Digital Company Contact</span>
                  <p className="description no-margin">
                    Digital Company Contacts are staff members who are displayed
                    in your Company Space as contact persons. They can be
                    contacted by chat and video chat. <br />
                    When you add a new Digital Contact Person, that person will
                    automatically receive an email with an explanation and the
                    login information.
                  </p>
                </h4>
                <div className="accordion-content">
                  <table className="admin-user-table video-chat-agent-table">
                    <tbody>
                      {showVideoChatAgent?.map((agent) => (
                        <tr>
                          <td className="title">Video Chat Agent:</td>
                          <td>{agent.firstName} {agent.middleName} {agent.lastName}, {agent.email}</td>
                          <td>
                            <button className="delete" data-id={agent.uid} data-type="3" onClick={() => removeAgent(agent.uid, 3)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 32 32"
                              >
                                <path d="M4 10v20c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V10H4zm6 18H8V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zM26.5 4H20V1.5c0-.825-.675-1.5-1.5-1.5h-7c-.825 0-1.5.675-1.5 1.5V4H3.5C2.675 4 2 4.675 2 5.5V8h26V5.5c0-.825-.675-1.5-1.5-1.5zM18 4h-6V2.025h6V4z"></path>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <h6 className="txt-left">Add Digital Company Contact</h6>
                  <div className="more-video-chat-agent"></div>
                  <div className="form-row flex-box wrap  auto-size">
                    <div className="field-item txt-left">
                      <input
                        type="text"
                        id="video-advisor-firstname"
                        placeholder=" "
                        className="standard-field bg-4"
                        name="tx_exhibitor_editexhibitor[video-advisor-firstname][]"
                        value={digitalCompanyContactFirstName}
                        onChange={(e) => setDigitalCompanyContactFirstName(e.target.value)}
                      />
                      <label className="field-label" for="video-advisor-firstname">
                        Firstname
                      </label>
                    </div>
                    <div className="field-item txt-left">
                      <input
                        type="text"
                        id="video-advisor-lastname"
                        placeholder=" "
                        className="standard-field bg-4"
                        name="tx_exhibitor_editexhibitor[video-advisor-lastname][]"
                        value={digitalCompanyContactLastName}
                        onChange={(e) => setDigitalCompanyContactLastName(e.target.value)}
                      />
                      <label className="field-label" for="video-advisor-lastname">
                        Lastname
                      </label>
                    </div>
                    <div className="field-item txt-left">
                      <input
                        type="email"
                        id="video-advisor-email"
                        className="required-field"
                        name="tx_exhibitor_editexhibitor[video-advisor-email][]"
                        placeholder=" "
                        value={digitalCompanyContactEmail}
                        onChange={(e) => setDigitalCompanyContactEmail(e.target.value)}
                      />
                      <label className="field-label" for="video-advisor-email">
                        E-Mail <span className="required-label">*</span>
                      </label>
                    </div>
                    <div className="field-item txt-left">
                      <button
                        type="button"
                        className="button add add-video-chat-agent"
                        data-type="3"
                        onClick={addDigitalCompanyContact}
                      ></button>
                    </div>
                  </div>
                </div>
                <hr />
                <h3>Company Content</h3>
                <h4 className="accordion-title selector">
                  <span>Whitepapers/Broschures</span>
                  <p className="description no-margin">
                    You can add 2 whitepapers or broschures as PDF download to
                    your Company Space.
                  </p>
                </h4>
                <div className="accordion-content">
                  <div className="col-box col-2 border-spacer">
                    <div className="col-item">
                      <h6 className="txt-left">Whitepaper/Broschures 1</h6>
                      <div className="form-row flex-box center-left auto-size">
                        <div className="field-item txt-left box-100">
                          <input
                            type="hidden"
                            id="exisitng-pdf-1"
                            name="tx_exhibitor_editexhibitor[exisitng-pdf-1]"
                            value="0"
                          />
                          <input
                            data-multiple-caption="{$count} files selected"
                            data-error-msg-mime="Only allow PDF"
                            className="inputfile"
                            id="pdf-file1"
                            type="file"
                            name="tx_exhibitor_editexhibitor[pdf-file1]"
                            onChange={handleFileInputChagne}
                          />
                          <label for="pdf-file1" className="label-fileupload pdf">
                            <strong className="button">PDF 1 upload</strong>
                            <span className="data-title"></span>
                            <span className="ext-data-title">{pdfFile1Title}</span>
                          </label>
                          <span className="description">
                            Max upload filesize is 10MB
                          </span>
                        </div>
                      </div>
                      <div className="form-row flex-box">
                        <div className="field-item txt-left box-100">
                          <input
                            type="text"
                            maxlength="60"
                            id="whitepaper1-title"
                            placeholder=" "
                            className="standard-field bg-4"
                            name="tx_exhibitor_editexhibitor[whitepaper1-title]"
                            value={whitepaper1Title}
                            onChange={(e) => setwhitepaper1Title(e.target.value)}
                          />
                          <label className="field-label" for="whitepaper1-title">
                            Title (max 60 characters)
                          </label>
                        </div>
                      </div>
                      <div className="form-row flex-box">
                        <div className="field-item txt-left box-100">
                          <label className="block-label" for="pdf1-description">
                            Brief description of your whitepaper/broschure
                            content PDF
                            <small className="description">
                              (max. 200 characters)
                            </small>
                          </label>
                          <CKEditor
                            id="editor"
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={pdf1Description?pdf1Description:''}
                            onBlur={(event, editor) => {
                              setPdf1Description(editor.getData());
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-row flex-box wrap">
                        <div className="field-item txt-left box-100">
                          <div className="field-item-wrapper">
                            <label 
                            className={
                              showMenu == 'whitepaper-1'
                                ? "block-label selector relative active"
                                : "block-label selector "
                            }
                            onClick={() => toggle('whitepaper-1')}
                            >
                              <span>
                                Select Topics for this Whitepaper
                                <span className="description">
                                  Select maximum 3 topics
                                </span>
                              </span>
                            </label>
                            <div className="form-row flex-box hashtag-box wrap">
                                {pdf1topic?.map((topic) => (
                                  <div className="field-item exhibitor-pdf-topics-1 standard-radio box-33">
                                    <input
                                    type="checkbox"
                                    className="whitepaper1-topic"
                                    name="tx_exhibitor_editexhibitor[whitepaper1-topic][]"
                                    id={`whitepaper1-topic-${topic.uid}`}
                                    value={topic.uid}
                                    defaultChecked={topic.checked}
                                    checked={topic.checked}
                                    onChange={handleTopicChange}
                                  />
                                  <label
                                    for={`whitepaper1-topic-${topic.uid}`}
                                    className="hashtag-label"
                                  >
                                    {topic.name}
                                  </label>
                                  
                                  </div>
                                ))}
                                
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-item">
                      <h6 className="txt-left">Whitepaper/Broschures 2</h6>
                      <div className="form-row flex-box center-left auto-size">
                        <div className="field-item txt-left box-100">
                          <input
                            type="hidden"
                            id="exisitng-pdf-2"
                            name="tx_exhibitor_editexhibitor[exisitng-pdf-2]"
                            value="0"
                          />
                          <input
                            data-multiple-caption="{$count} files selected"
                            data-error-msg-mime="Only allow PDF"
                            className="inputfile"
                            id="pdf-file2"
                            type="file"
                            name="tx_exhibitor_editexhibitor[pdf-file2]"
                            onChange={handleFileInputChagne}
                          />
                          <label for="pdf-file2" className="label-fileupload pdf">
                            <strong className="button">PDF 2 upload</strong>
                            <span className="data-title"></span>
                            <span className="ext-data-title">{pdfFile2Title}</span>
                          </label>
                          <span className="description">Max upload filesize is 10MB</span>
                        </div>
                      </div>
                      <div className="form-row flex-box">
                        <div className="field-item txt-left box-100">
                          <input
                            type="text"
                            maxlength="60"
                            id="whitepaper2-title"
                            placeholder=" "
                            className="standard-field bg-4"
                            name="tx_exhibitor_editexhibitor[whitepaper2-title]"
                            value={whitepaper2Title}
                            onChange={(e) => setwhitepaper2Title(e.target.value)}
                          />
                          <label className="field-label" for="whitepaper2-title">
                            Title (max 60 characters)
                          </label>
                        </div>
                      </div>
                      <div className="form-row flex-box">
                        <div className="field-item txt-left box-100">
                          <label className="block-label" for="pdf2-description">
                            Brief description of your whitepaper/broschure content PDF
                            <small className="description">(max. 200 characters)</small>
                          </label>
                          <CKEditor
                            id="editor"
                            editor={ClassicEditor}
                            config={editorConfiguration}
                            data={pdf2Description?pdf2Description:''}
                            onBlur={(event, editor) => {
                              setPdf2Description(editor.getData());
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-row flex-box wrap">
                        <div className="field-item txt-left box-100">
                          <div className="field-item-wrapper">
                            <label 
                              className={
                                showMenu == 'whitepaper-2'
                                  ? "block-label selector relative active"
                                  : "block-label selector "
                              }
                              onClick={() => toggle('whitepaper-2')}
                            >
                              <span>
                                Select Topics for this Whitepaper
                                <span className="description">
                                  Select maximum 3 topics
                                </span>
                              </span>
                            </label>
                            <div className="form-row flex-box hashtag-box wrap">

                            {pdf2topic?.map((topic) => (
                                  <div className="field-item exhibitor-pdf-topics-2 standard-radio box-33">
                                    <input
                                    type="checkbox"
                                    className="whitepaper2-topic"
                                    id={`whitepaper2-topic-${topic.uid}`}
                                    value={topic.uid}
                                    defaultChecked={topic.checked}
                                    checked={topic.checked}
                                    onChange={handleTopicChange}
                                  />
                                    <label
                                      for={`whitepaper2-topic-${topic.uid}`}
                                      className="hashtag-label"
                                    >
                                      {topic.name}
                                    </label>                                  
                                  </div>
                                ))}

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <hr />
            <h4 className={`accordion-title ${serverError['article-file']? 'error': ''} selector`}>
              <span>Article</span>
              <p className="description no-margin">
                Write an article and publish it in your Company Space. The
                article will also be visible on the main page of the IP Service
                World platfom for every visitor.
              </p>
            </h4>
            <div className="accordion-content">
              <div className="form-row flex-box wrap">
                <div className="field-item txt-left box-100">
                  <div className="field-item-wrapper max-50">
                    <input
                      maxlength="60"
                      type="text"
                      id="article-title"
                      placeholder=" "
                      className="standard-field bg-4"
                      name="tx_exhibitor_editexhibitor[article-title]"
                      value={articleTitle}
                      onChange={(e) => setArticleTitle(e.target.value)}
                    />
                    <label className="field-label" for="article-title">
                      Article title / headline (max 60 characters)
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-row flex-box wrap pd-2">
                <div className="field-item txt-left box-50">
                  <label className="block-label" for="article-intro">
                    Intro
                    <small className="description">(max. 200 characters)</small>
                  </label>
                  <CKEditor
                    id="editor"
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={articleInto?articleInto:''}
                    onBlur={(event, editor) => {
                      setArticleInto(editor.getData());
                    }}
                  />
                </div>
                
                <div className={`field-item txt-left box-50 ${serverError['article-file']? 'error': ''}`}>
                  <div className="field-item-wrapper">
                    <label className="block-label">
                      Article Image
                      <span className="description">
                        Please upload files only as JPG, min. width 800px, max.
                        3 MB
                      </span>
                    </label>
                    <div className="upload-container">
                      <figure className="user-image-preview flex-box center-left">
                      {(compantDetails?.company?.articleimage) ? (
                        <img src={process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + compantDetails.company.articleimage} />
                      ):(
                        <img src='/images/icon-image-round.svg' />
                      )}
                      </figure>
                      {(compantDetails?.company?.articleimage) ? (
                      <a
                        className="remove-company-file delete delete-item"
                        onClick={() => removeImage('article-file')}
                      >
                        Remove Article Image
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 32 32"
                        >
                          <path d="M4 10v20c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V10H4zm6 18H8V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zm4 0h-2V14h2v14zM26.5 4H20V1.5c0-.825-.675-1.5-1.5-1.5h-7c-.825 0-1.5.675-1.5 1.5V4H3.5C2.675 4 2 4.675 2 5.5V8h26V5.5c0-.825-.675-1.5-1.5-1.5zM18 4h-6V2.025h6V4z"></path>
                        </svg>
                      </a>
                      ): ''}
                      <input
                        data-multiple-caption="{$count} files selected"
                        data-error-msg-mime="Only allow jpg"
                        data-error-msg-size="File size not more than 5MB"
                        className="inputfile"
                        id="article-file"
                        type="file"
                        name="tx_exhibitor_editexhibitor[article-file]"
                        onChange={handleFileInputChagne}
                      />
                      <label for="article-file" className="label-fileupload">
                        <strong>Article Image Upload</strong>
                        <span className="data-title"></span>
                        <span className="ext-data-title">{articleFileTitle}</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row flex-box">
                <div className="field-item txt-left box-100">
                  <label className="block-label" for="article">
                    Article
                  </label>
                  <CKEditor
                    id="editor"
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={article?article:''}
                    onBlur={(event, editor) => {
                      setArticle(editor.getData());
                    }}
                  />
                </div>
              </div>
              <div className="form-row flex-box wrap pd-t2">
                <div className="field-item txt-left box-100">
                  <div className="field-item-wrapper">
                  <label 
                      className={
                        showMenu == 'article'
                          ? "block-label selector relative active max-50"
                          : "block-label selector max-50"
                      }
                      onClick={() => toggle('article')}
                    >
                      <span>
                        Select Topics for this Article
                        <span className="description">Select maximum 3 topics</span>
                      </span>
                    </label>
                    <div className="form-row flex-box hashtag-box wrap">
                        {articleTopics?.map((topic) => (
                          <div className="field-item exhibitor-article-topics standard-radio box-33">
                            <input
                            type="checkbox"
                            id={`article-topic-${topic.uid}`}
                            value={topic.uid}
                            className="article-topic"
                            defaultChecked={topic.checked}
                            checked={topic.checked}
                            onChange={handleTopicChange}
                          />
                            <label
                              for={`article-topic-${topic.uid}`}
                              className="hashtag-label"
                            >
                              {topic.name}
                            </label>                                  
                          </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div className="button-box form-row flex-box">
              <div className="field-item left-item">
                <button
                  className="button ghost tab-next previous"
                  name="tx_exhibitor_editexhibitor[preview-exhibitor]"
                  value="preview"
                  onClick={previewCompanyspace}
                >
                  <span className="button-label"></span>Preview Company Space
                  <span className="icon-arrow-right"></span>
                </button>
              </div>
              <div className="field-item right-item">
                <button
                  className="button tab-next show-loader"
                  onClick={() => saveCompanySpace({redirect : true})}
                >
                  <span className="button-label">Save Company Space</span>
                </button>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
