import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { AES, enc, pad } from 'crypto-js'
import { doRequest } from "./doRequest";
import { useContext } from "react";
import { ProfileContext } from "../Context/ProfileContext";


const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

const TOKEN_KEY = 'contextVar';

export const login = (props) => {
    localStorage.setItem(TOKEN_KEY, 'TestLogin');
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY) && decryptedUserId()) {
        return true;
    }
    return false;
}


export const decryptedUserId = () => {
    if (localStorage.getItem("contextVar")) {
        var bytes = AES.decrypt(
          localStorage.getItem("contextVar").toString(),
          secretKey
        );

        if (bytes.toString(enc.Utf8)) {
          return JSON.parse(bytes.toString(enc.Utf8));
        }
    } else {
        return '';
    }
}

export const cryptedUserId = () => {
    if (localStorage.getItem("contextVar")) {
        var bytes = AES.decrypt(
          localStorage.getItem("contextVar").toString(),
          secretKey
        );

        let key = enc.Hex.parse("0123456789abcdef0123456789abcdef");
        let iv =  enc.Hex.parse("abcdef9876543210abcdef9876543210");
        
        if (bytes.toString(enc.Utf8)) {
            let msg = JSON.parse(bytes.toString(enc.Utf8)).toString();
            return AES.encrypt(msg, key, {iv:iv, padding: pad.ZeroPadding}).toString();
        }
    } else {
        return '';
    }
}

export const getCookie = (cname) => {
        let name = cname + "=";
        let ca = document.cookie.split(";");
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
}

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


export const setUserInfoUtil = () => {
        
    return axios
            .get( process.env.REACT_APP_ENDPOINT +  '/lib/storage/content/Users.json', {
                auth: {
                    username: "cuatconference",
                    password: "relaunch",
                },
            })
            .then((res) => {
                var user  = '';
                if (localStorage.getItem("contextVar")) {
                    var bytes = AES.decrypt(
                      localStorage.getItem("contextVar").toString(),
                      secretKey
                    );
                    
                    if (bytes.toString(enc.Utf8)) {
                      let decryptedData = JSON.parse(bytes.toString(enc.Utf8));
                    
                      res.data.forEach((element) => {
                        if (decryptedData == element.uid) {
                            user = element;
                            if(!element.livepage){
                                axios.get(process.env.REACT_APP_ENDPOINT + 'server.php', {
                                    headers: { 
                                        'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
                                    },
                                    auth: {
                                        username: 'cuatconference',
                                        password: 'relaunch'
                                    },
                                    params: {
                                        localId: process.env.REACT_APP_LOCAL_ID,
                                        user: element.uid,
                                        type: 'livePageVist'
                                    }
                                })
                                .then(() => {})
                                .catch(err=>{
                                    console.log(err);
                                })
                            }
                            
                        }
                      });
                    }
                }
                return user;
            })
            .catch((err) => {
              console.log(err);
            });
}


// axios
//             .get( process.env.REACT_APP_ENDPOINT +  '/lib/storage/content/Users.json', {
//                 auth: {
//                     username: "cuatconference",
//                     password: "relaunch",
//                 },
//             })
//             .then((res) => { 
                
//                 if (localStorage.getItem("contextVar")) {
//                     var bytes = CryptoJS.AES.decrypt(
//                       localStorage.getItem("contextVar").toString(),
//                       secretKey
//                     );
          
//                     if (bytes.toString(CryptoJS.enc.Utf8)) {
//                       let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                
//                       res.data.forEach((element) => {
//                         if (decryptedData == element.uid) {
//                           setUserInfo(element);

//                           if(!element.livepage){
//                                 axios.get(process.env.REACT_APP_ENDPOINT + 'server.php', {
//                                     headers: { 
//                                         'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
//                                     },
//                                     auth: {
//                                         username: 'cuatconference',
//                                         password: 'relaunch'
//                                     },
//                                     params: {
//                                         localId: process.env.REACT_APP_LOCAL_ID,
//                                         user: element.uid,
//                                         type: 'livePageVist'
//                                     }
//                                 })
//                                 .then(() => {})
//                                 .catch(err=>{
//                                     console.log(err);
//                                 })
//                             }

//                         }
//                       });
//                     }
//                   }
//             })
//             .catch((err) => {
//               console.log(err);
//             });
        