import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { Link, redirect, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../Components/Footer';
import Header from '../Components/Header'
import { AuthContext } from '../Context/AuthContext';
import { ProfileContext } from '../Context/ProfileContext';

export default function WithoutLiveAccess() {

    const authContext = useContext(AuthContext);
    const { profile } = useContext(ProfileContext);
    const [ exhibitorAccess, setExhibitorAccess ] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    function HandleLogout() {
        authContext.logout();
        if(location.pathname == "/user-login") {
            return navigate("/");
        } else {
            return navigate("/user-login");
        }
    }

    useEffect(() => {
        document.body.classList.add('countdown');
        return () => {
          document.body.classList.remove('countdown');
        }
    }, [])

    useEffect(() => {
        if(profile.usergroup) {
            let usrgrp = profile.usergroup.split(',');
            if(usrgrp.includes('2') || usrgrp.includes('1')){
                setExhibitorAccess(true);
            };
        }
    }, [profile])


    useEffect(() => {
        console.log(authContext.hasLiveAccess);
        if(authContext.hasLiveAccess) {
            return navigate('/live');
        }
    }, [authContext.hasLiveAccess])
    

    

  return (
    <>
        <div className="base-box login-home fixed" id="page-top">
            <Header hideOptions={true} />
            
            <div className="base-item">
                <section className="hero-content flex-box stretch bg-1">
                    <div className="item-box flex-box stretch full-size">
                        <div className="bg-image">
                        <figure className="image-box">
                            <img srcSet="/images/bg-live.jpg 760w,/images/bg-live.jpg 1080w" sizes="(max-width: 1600px) 100vw, 1600px" src="/typo3conf/ext/tsbasetemplate/Resources/Public/images/bg-live.jpg" />
                        </figure>
                    </div>
                    <div className="hero-item-box flex-box wrap center-center ">
                        <div className="item-box">
                            <section className="modul modul-intro-text pd-t12 pd-b4">
                                <div className="item-box">
                                    <div className="text-box txt-center">
                                        <h3>Live in Munich – Digitally Worldwide </h3>
                                        <p>The 12th edition of IP Service World is both a physical and a digital event.<br />
                                        The digital platform of IP Service World will be open for you on November 21st at 8:00 am CET.</p>
                                    </div>
                                </div>
                            </section>
                            <section className="modul modul-start-login pd-t0 pd-b4">
                                <section className="modul modul-start-login pd-t0 pd-b4">
                                    <div className="item-box">
                                        <div className="form-box bg-4  txt-center">
                                            <div className="form-box txt-center">
                                                <div className="block-style"><span>Login User</span></div>
                                                <div className="form-row flex-box center-center small-box">
                                                <p className="info-text txt-left small-box"></p><h4>You're logged in as {profile?.email}<span></span></h4><p></p>
                                            </div>
                                            <div className="button-box flex-box center-right small-box">
                                            <div className="field-item left-item">
                                                {/* <a href="/user-editor" className="button ghost visitor-link">Profile Edit</a> */}
                                                <Link to="/user-editor" className="button ghost visitor-link">
                                                    Profile Edit
                                                </Link>
                                                {exhibitorAccess ? (
                                                    <Link to="/exhibitor-editor" className="button ghost exhibitor-link">
                                                        Exhibitor Access
                                                    </Link>
                                                ) : ''}
                                                
                                            </div>
                                            <div className="field-item right-item">
                                                <a onClick={HandleLogout} className="button tab-next" >
                                                    <span className="button-label">Logout</span><span className="icon-arrow-right"></span>
                                                </a>
                                            </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </div>
                    </div>
                    </div>

                </section>
            </div>

            <Footer />
        </div>
    </>
  )
}
