import * as React from "react";
import { useState,useEffect } from "react";
import axios from 'axios';
import $ from 'jquery'

import {
    Routes,
    Route,
    Link,
    useNavigate,
    useLocation,
    Navigate,
    Outlet
  } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";


export default function Chat() {

    let [hidden, setHidden] = React.useState('hidden');
    let [messageCount, setMessageCount] = React.useState(0);

    let [chatUsers, setChatUsers] = React.useState([]);

    var CryptoJS = require("crypto-js");
    var secretKey = process.env.REACT_APP_ENCRYPTION_KEY;
    let decryptedData = '';
 

    $(function () {

        var chatUrl = process.env.REACT_APP_CHAT_ENDPOINT + "Chat.php";

        $(document).on("keyup", ".search-notification" , function() {
            if (this.value.length) {
                var that = this;
                $("#notification-results .notification-item").hide().filter(function() {
                    return $(this).html().toLowerCase().indexOf(that.value.toLowerCase()) !== -1;
                }).show();
                $("#notification-results").show();
            } else {
                $("#notification-results .notification-item").show();
            }
        });

        $(document).on('click', "#notification-results .notification-item", function() {
            $('#notification-list').hide();
            $("#notification-detial").show();
            $("#notification-detial .user-image").html($(this).find('.user-image').html());
            $("#notification-detial .user-name").html($(this).find('.user-name').html());

            let from = $(this).attr('data-from');
            let to = $(this).attr('data-to');
            let scroll = true;
            let online = $(this).attr('data-online');
            let username = $(this).find('.user-name').html();

            var chatFrame = $('.rocket-chat-iframe');
            let message = { to, from, scroll, online, username };

           // console.log(message)

            chatFrame[0].contentWindow.postMessage(message, '*');
        });


        $(document).on('click', "#notification-detial #notification-back", function() {
            var chatFrame = $('.rocket-chat-iframe');
            $('#notification-list').show();
            $("#notification-detial").hide();

            let message = { to:0, from:0 };

            

            chatFrame[0].contentWindow.postMessage(message, '*');
        });

        let resetChatStatus = function () {
            var currUser = $('#currUser').val();
            var myKeyVals = {
                resetChatStauts : 1,
                to: currUser
            }

            $.ajax({
                type: "POST",
                url: chatUrl,
                data: myKeyVals,
                dataType: "text",
                dataType : 'json',
                success: function(resultData){

                }
            });
        }

        resetChatStatus();


        let getMessagesFromChat = function () {
            var currUser = $('#currUser').val();
            var myKeyVals = {
                fetchMessages : 1,
                to: currUser
            }

            var saveData = $.ajax({
                type: "POST",
                url: chatUrl,
                data: myKeyVals,
                dataType: "text",
                dataType : 'json',
                success: function(resultData){
                    // console.log(resultData);
                    let totalCount = resultData.filter(function(value) { return value.m_from == currUser && value.is_read == 0 }).length;

                    // console.log('totalCount ' + totalCount )
                    // if (parseInt(totalCount) > 0) {
                    //     setMessageCount(totalCount)
                    // }

                    // if (typeof window.top.$ === 'function') {
                        let p = window.top;
                        $('.matchmaking-counter', window.parent.document).html(totalCount);
                        let currentRequests = parseInt($('#currentRequests', window.parent.document).val());

                        if (totalCount > currentRequests && $('.search-notification').val() == '') {
                            $('#currentRequests', window.parent.document).val(totalCount);
                            setMessageCount(totalCount)

                            // if($("#notification-detial").css('display') == 'none') {
                            //     p.$('.chat-notifications #overlay-iframe').attr('src', p.$('.chat-notifications #overlay-iframe').attr('src'));
                            // }

                        }

                    // }
                  //  console.log('message Count ' + messageCount );


                    $('#notification-results .notification-item').each(function() {
                        var to = $(this).attr('data-to');
                        var from = $(this).attr('data-from');

                        var length = resultData.filter(function(value) { return value.m_from == to && value.m_to == from && value.is_read == 0 }).length

                        $(this).find('.button-count').html(length>0?length:'');
                        var allMessages = resultData.filter(function(value) {
                               //return value.m_from == to && value.is_read == 0
                               return (value.m_from == to && value.m_to == from) || (value.m_from == from && value.m_to == to)
                        });

                        

                        var time = 0;
                        var message = '';
                        var msgTime = '';
                         if (allMessages.length) {
                            var message = allMessages.sort(
                              function(a, b) {
                                 return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                              }
                            )[0]['message'];

                            var time = allMessages.sort(
                              function(a, b) {
                                 return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                              }
                            )[0]['tstamp'];


                            var msgTime = allMessages.sort(
                              function(a, b) {
                                 return parseFloat(b['tstamp']) - parseFloat(a['tstamp']);
                              }
                            )[0]['time'];
                        }


                        

                        $(this).find('.button-box').html(message);
                        $(this).find('.time-box').html(msgTime);
                        $(this).attr('data-time', time);
                    });

                    var sortedHtml = $('#notification-results .notification-item')
                    .sort((a,b) => $(b).data("time") - $(a).data("time"))
                    $("#notification-results").html(sortedHtml)
                }
            });
        }

        getMessagesFromChat();
        setInterval(getMessagesFromChat, 10000);

        window.addEventListener('message', function(event) {
            if(event.data.showDetail){
                var to = event.data.to;
                var from = event.data.from;
                var chatFrame = $('.rocket-chat-iframe');

                $.ajax({
                    url: process.env.REACT_APP_SERVER_ENDPOINT + '?type=8988899',
                    data: {
                        tx_user_contactrequest: {
                            uid:to,
                        }
                    },
                    success: function(content) {

                        let scroll = true;
                        let online = $(content).find('.notification-item').attr('data-online');
                        let username = $(content).find('.user-name').html();

                        $('#notification-list').hide();
                        $("#notification-detial").show();
                        $("#notification-detial .user-image").html($(content).find('.user-image').html());
                        $("#notification-detial .user-name").html($(content).find('.user-name').html());
                        let message = { to, from, scroll, online, username };
                        chatFrame[0].contentWindow.postMessage(message, '*');
                    }
                });
            }
        }, false);


    });

    var bytes  = CryptoJS.AES.decrypt(localStorage.getItem('contextVar').toString(), secretKey);
       
    if (bytes.toString(CryptoJS.enc.Utf8)) {
        decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        //console.log('decryptedData : '+ decryptedData)
    }

    const navigate = useNavigate();

    useEffect(() => {

            document.body.classList.add('chat-active');

            axios.get(process.env.REACT_APP_ENDPOINT + 'server.php', {
                headers: { 
                  'Authorization': 'Basic Y3VhdGNvbmZlcmVuY2U6Y3VhdGNvbmZlcmVuY2VIVEEh'
                },
                auth: {
                  username: 'cuatconference',
                  password: 'relaunch'
                },
                params: {
                  user: decryptedData,
                  type: 'chatUsers'
                }
              })
            .then(res=>{
                console.log(res.data.response);
                setChatUsers(res.data.response)
                //console.log(res.data)
              //console.log(res.data.success)
              
            })
            .catch(err=>{
             // console.log(err);
            });
            //event.stopImmediatePropagation();
  
        return () => {
            
            document.body.classList.remove('chat-active');
        }
        
      }, []);
    



    return (

        <>
                            <input type="hidden" id="currUser" value={decryptedData} />

                            <div id="notification-list">
                                <div className="notification-chat-item">
                                    <input className="search-notification" type="text" placeholder="Search Chat User" />
                                </div>

                                <div id="notification-results">
                                    <>
                                    {chatUsers && chatUsers.map((item, val) => {
                                        console.log(chatUsers, "chat users");
                                        let image = process.env.REACT_APP_SERVER_ENDPOINT + '' + item.image;

                                        return (
                                            <div key={item.uid} className="notification-item" data-from={item.touser} data-to={item.uid} data-online={item.onlineStatus}>
                                            <div className="notification-info flex-box">
                                                <div className="flex-item user-image">
                                                <div className="bg-image">
                                                    <figure className="image-box">
                                                    <img srcSet={`${image} 360w,
                                                    ${image} 560w`} sizes="(max-width: 560px) 100vw, 560px" src={image} />
                                                    </figure>
                                                </div>
                                                </div>
                                                <div className="info-box">
                                                    <strong className="user-name">{item.first_name} {item.last_name}</strong>
                                                    <div className="button-box"></div>
                                                    <div className={`button-count online-${item.onlineStatus}`}></div>
                                                    <span className="time-box"></span>
                                                </div>
                                            </div>
                                            </div>

                                        );
                                    })}
                                    </>
                                </div>
                            </div>


                            <div id="notification-detial" className={hidden}>
                                <div className="flex-box">
                                    <div className="flex-item flex-box center-center">
                                    <button id="notification-back"><span className="icon-box arrow"><i className="icon"></i></span>Overview</button>
                                </div>
                                        <div className="notification-item">
                                            <div className="notification-info flex-box">
                                                <div className="user-image"></div>
                                                <div className="user-name"></div>
                                            </div>
                                        </div>
                                </div>
                            <div className="rocket-chat-iframe-box">
                                <iframe className="rocket-chat-iframe" id="rocket-chat-iframe" 
                                src="https://chat.live-ipserviceworld.com/" frameBorder="0"></iframe>
                            </div>
                            </div>
                    {/* <iframe
                        id="overlay-iframe"
                        src="https://cuatconference-dev.codersunlimited.com/contact-request"
                        frameBorder="0"
                        scroll="noscroll"
                    ></iframe> */}
                    {/* <iframe
                        title="Chat Iframe"
                        id="overlay-iframe"
                        src="/Chat"
                        frameBorder="0"
                        scroll="noscroll"
                    ></iframe> */}
                    </>
	
    );
}