import * as React from "react";
import { Link, redirect, useNavigate } from "react-router-dom";
import { useContext } from "react";
import MyAgendaContext from "../Live/Context/myAgendaContext";
import { AuthContext } from "../Context/AuthContext";

import { decryptedUserId, isLogin } from '../utils';
import { doRequest } from "../utils/doRequest";
import { ProfileContext } from "../Context/ProfileContext";
import { TopicsContext } from "../Context/TopicsContext";

import MatchMakingLogo from '../matchmaking-icon.svg'
import Chat from "../Chat/Chat";
import overlayContext from "../Context/overlayContext";



export default function Header({setIsLoggedInUser, hideOptions, showCounter}) {
    const navigate = useNavigate();

    const { showOverlay, setShowoverlay } = useContext(overlayContext);   
    const authContext = useContext(AuthContext);
    const [active, setActive] = React.useState('');
    
    const { topics, setTopics } = useContext(TopicsContext);
    const { profile, setProfile } = useContext(ProfileContext);

    const { userAgenda } =  useContext(MyAgendaContext);
    const [ chatActive, setChatActive] = React.useState('');

    const [ exhibitorAccess, setExhibitorAccess ] = React.useState(false);

    const localuserSearchTopics = JSON.parse(localStorage.getItem('searchTopicKey')) || [];
    const [userSearchTopics, setUserSearchTopics] = React.useState(localuserSearchTopics);
    
    const localtopicsName = JSON.parse(localStorage.getItem('searchTopicName')) || [];
    const [topicsName, setTopicName] = React.useState(localtopicsName);

    React.useEffect(() => {
        if(profile.usergroup) {
            let usrgrp = profile.usergroup.split(',');
            if(usrgrp.includes('2') || usrgrp.includes('1')){
                setExhibitorAccess(true);
            };
        }
    }, [profile])

    React.useEffect(() => {
        localStorage.setItem('searchTopicKey', JSON.stringify(userSearchTopics));
    }, [userSearchTopics]);

    React.useEffect(() => {
        localStorage.setItem('searchTopicName', JSON.stringify(topicsName));
    }, [topicsName]);


    function HandleLogout() {
        authContext.logout();
    }

    function startChat(to) {
        setShowoverlay('chat');
        let toUser = to;
        var showDetail = true;
        var from = decryptedUserId();
        var to = toUser;

        let message = { to, from, showDetail };
        
        // setTimeout(() => {
        //     // var chatFrame = $('.chat-notifications #overlay-iframe');
        //     // chatFrame[0].contentWindow.postMessage(message, '*');    
        // }, 1000);

        doRequest('React', 'server.php', 'GET', {
            type: 'myContacts',
            user: decryptedUserId(),
            toUser: toUser
        }, '', (err,data) => {console.log(data)} );
    }

    React.useEffect(() => {
        let isSubscribed = true;
            if(isLogin()){
            
                doRequest(
                    'React',
                    'lib/storage/content/Topics.json',
                    'GET', 
                    {},
                    false,
                    (err,data) => {
                        if (isSubscribed) {
                            setTopics(data);
                        }
                    }
                );

                doRequest('React', '/lib/storage/users/' + decryptedUserId() + '/profile.json', 'GET', { }, true, (err,data) => {
                    if (isSubscribed) {
                        setProfile(data);
                    }
                })
            }

        return () => (isSubscribed = false)
    },[])

    // $(document).on('submit', '#topic-search-form', function(e) {
    //     e.preventDefault();

    //     $('#topic-navi, #user-profile').removeClass('active');
    //     $('.match-header').show();
    //     $('.live-header').hide();
    //     $('.hero-content').addClass('small-hero').removeClass('full-size');
    //     $('.main-content section').hide();

    //     $('#saves').show();
    //     var topicsArr = [];
    //     $('.filter-topics-live').each(function(){
    //         if ($(this).prop('checked')) {
    //             topicsArr.push($(this).val());
    //         }
    //     })

    //     $.ajax({
    //         url: process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + '?type=1234512345',
    //         type: 'POST',
    //         data: {
    //             tx_ipswnews_newssearch : {
    //                topic : topicsArr,
    //             }
    //         },
    //         success: function(res) {
    //             console.log(res);
    //             $('#searchResultPlaceHolder').html(res);
    //             $('#searchResultPlaceHolder').show();
    //             $(".bg-image.live-header").show();
    //             $('.hero-content.small-hero').show();
    //             // $('html, body').animate({
    //             //     scrollTop: $('#search-result').offset().top-100
    //             // }, 500);
    //             //$('#searchResultPlaceHolder').remove();
    //             $('#saves').hide();
    //         }
    //     })

    // })

    if(!isLogin() || hideOptions) {
        return (
            <header id="masthead" className="site-header cf" role="banner">
                <div className="branding myclass">
                    <div className="item-box">
                        <Link className="logo" title="IP Service World" to="/"><span></span></Link>
                        <div className="meta-box flex-box center-right">
                            {/* {showCounter ? (
                                <div class="counter d_1" data-date="2022/11/21 08:30:00" >
                                <div className="counter_label">days left:</div>
                                <div className="running wrap" >
                                    <timer className="flex-box">
                                        <span className="days"></span>
                                        <span className="hours"></span>
                                        <span className="minutes"></span>
                                        <span className="seconds"></span>
                                    </timer>
                                    <div className="labels flex-box">
                                        <span>Days</span><span>Hours</span><span>Minutes</span><span>Seconds</span>
                                    </div>
                                    <div className="text no-display"></div>
                                </div>
                            </div>
                            ) : ''} */}
                        </div>
                    </div>
                </div>
            </header>
        )
    }

    function ShowChat () {
        React.useEffect(() => {
            document.body.classList.add('chat-active');
        
          return () => {
            document.body.classList.remove('chat-active');
          }
        }, [])

        function closeOverlay() {
            setShowoverlay('');
        }
        
        return (
            <div
            className="overlay chat-notifications scroll-overlay active"
            id="matchmaking-overlay"
            >
                <div className="item-box">
                    <button onClick={closeOverlay} className="close close-overlay">
                        <svg xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" stroke-miterlimit="10" clip-rule="evenodd" viewBox="0 0 41 41"><g transform="translate(4.022 3.96)"><clipPath id="a"><path d="M-6.627 16.063L16-6.565l22.627 22.628L16 38.69-6.627 16.063z" clip-rule="nonzero"></path></clipPath><g clip-path="url(#a)"><circle cx="15" cy="15" r="15" fill="none" stroke="#fff" transform="rotate(135 15.28 15.738)"></circle><path fill="none" stroke="#fff" stroke-linecap="square" d="M10.195 9.55l12.317 12.318M22.01 9.345L9.99 21.365"></path></g></g></svg>
                    </button>
                    <div className="overlay-container">
                        <div className="iframe-box">
                            <iframe id="overlay-iframe" src="/chat" />
                        </div>
                    </div>
                </div>
            </div>
        )

    }


    const handleChangeSearchTopic = (e) => {
        // Destructuring
        const { value, checked } = e.target;
        
            
        const dataName = e.target.getAttribute('data-name');
        console.log(`${value} is ${checked} AND ${dataName}`);
            
        // Case 1 : The user checks the box
        if (checked) {
            setUserSearchTopics([...userSearchTopics, value]);
            setTopicName([...topicsName, dataName]);
        }
        // Case 2  : The user unchecks the box
        else {
            let newSearchTopic = userSearchTopics.filter((e) => e !== value);
            setUserSearchTopics(newSearchTopic);
            let newSearchTopicName = topicsName.filter((e) => e !== dataName);
            setTopicName(newSearchTopicName);
        }
    };

    function searchTopics() {
        const { topics } = userSearchTopics;
        setActive('');
        return navigate('/search-topics', { state: { topicsIds: localuserSearchTopics } });
    }

    function toggleSearchTopic() {
        setActive(active === '' ? 'active' : '');
    }

    function jumpToSection(section) {
        let sectionElem = document.getElementById(section);
        if (sectionElem) {
            sectionElem.scrollIntoView();
        }
    }

    return (
       
        <header id="masthead" className="site-header cf" key={'header'} role="banner">
        <div className="branding">
            <div className="item-box">
                <Link className="logo" title="IP Service World" to="/live"><span></span></Link>
                <div className="meta-box flex-box center-right">
                    <div className="topic-navi">
                    <button className={`topic-filter button ${active} `} onClick={toggleSearchTopic} id="topic-navi">
                        <span className="topic-label">Search for Topic</span>
                        <span className="icon-box search"><i className="icon"></i></span>
                    </button>
                    <div className="filter-box filters">
                        <div></div>
                        <div className="form-row flex-box wrap">
                        {topics.length && topics?.map((topic) => {
                            return (
                                <div key={topic.uid} className="field-item standard-radio">
                                    <input
                                        type="checkbox"
                                        className="filter-topics-live"
                                        checked={userSearchTopics.includes(`${topic.uid}`) ? true : false}
                                        name="tx_ipswnews_newssearch[topic][]"
                                        id={`topic-${topic.uid}`}
                                        value={topic.uid}
                                        data-name={topic.name}
                                        onChange={handleChangeSearchTopic}
                                    />
                                    <label htmlFor={`topic-${topic.uid}`} className="checkbox-label">{topic.name}</label>
                                </div>
                            )                            
                        })}
                       

                    </div>
                        <div className="button-box">
                            <button
                                onClick={searchTopics}
                                className="button call-to-action"
                                name="tx_ipswnews_newssearch[search]"
                                value="search"
                            >
                                <span>Search</span>
                                <span className="icon-box search">
                                    <i className="icon"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                    </div>

                    <div className="matchmaking-navi-box flex-box center-center">
                        <Link hash="#agenda" to="/live#agenda" onClick={() => jumpToSection('agenda')} className="button agenda">
                            <span className="matchmaking-label">my Agenda</span>
                            <span className="icon-box"><i className="icon agenda-counter"> {userAgenda.length} </i></span>
                        </Link>

                        <button className="matchmaking flex-box center-center" id="notification-opener" onClick={() => {setShowoverlay('chat'); startChat();}}>
                            <span className="matchmaking-icon">
                                <img src={MatchMakingLogo} style={{ maxWidth: '100%' }} />
                            </span>
                            <span className="matchmaking-counter">0</span>
                            <input type="hidden" id="currentRequests" value="0" />
                        </button>
                        <Link className="button call-to-action matchmaking-link" title="Matchmaker" to="/matchmaking">
                            <span className="matchmaking-label">Matchmaker</span>
                            <span className="icon-box"><i className="icon"></i></span>
                        </Link>
                    </div>

                    <div className="user-box">
                        {profile && profile.image ? (
                            <button
                                className="user-profile"
                                id="user-profile"
                                style={{ 'backgroundImage': "url('"+ process.env.REACT_APP_SERVER_ENDPOINT_TYPO3 + profile.image +"')" }}
                                >
                                <span className="user-image icon-box"></span>
                            </button>            
                        ) : (
                            <button
                                className="user-profile"
                                id="user-profile"
                                style={{ 'backgroundImage': "url('/images/icon-user.svg')" }}
                            >
                                <span className="user-image icon-box"></span>
                            </button>        
                        ) }

                    
                    <div className="user-profile-navi">
                        <Link className="my-contacts-link" to='/my-contacts'>
                            My Contacts
                            <span className="icon-box"><i className="icon"></i></span>
                        </Link>

                        <Link hash="#agenda" to="/live#agenda" onClick={() => jumpToSection('agenda')} className="agenda-link">
                            My Agenda
                            <span className="icon-box"><i className="icon"></i>
                            </span>
                        </Link>

                        <Link to="/user-editor">
                            Edit Profile
                            <span className="icon-box"><i className="icon"></i></span>
                        </Link>
                        {exhibitorAccess ? (
                            <Link to='/exhibitor-editor'>
                                Edit Exhibitor Data
                                <span className="icon-box"><i className="icon"></i></span>
                            </Link>
                        ) : ''}
                        
                        <a href="https://jitsi.live-ipserviceworld.com/system-check/" target="_blank" class="system-check">
                            System Check
                            <span className="icon-box"><i className="icon"></i></span>
                        </a>
                        <a href="/Attendee%20list%20IPSW%202022.pdf" target="_blank" class="attendee-list">
                            Attendee list
                            <span className="icon-box"><i className="icon"></i></span>
                        </a>
                        <a href="#!" onClick={HandleLogout}>
                            Logout
                            <span className="icon-box"><i className="icon"></i></span>
                        </a>
                    </div>
                    </div>
                </div>

                {showOverlay && showOverlay == 'chat' ? <ShowChat /> : null}
            </div>
        </div>
        </header>
    );
}