import SmallHero from "../Components/SmallHero";
import MatchFilter from "./MatchFilter";
import Footer from "../Components/Footer";
import Header from "../Components/Header";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

export default function Bookmarks () {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        document.body.classList.add('live');
      return () => {
        document.body.classList.remove('live');
      }
    }, [])

    useEffect(() => {
        if (!authContext.isAuth ) { return (navigate("/user-login")); }    
      }, [authContext.isAuth])

    return (
        <div className="base-box fixed" id="page-top">
        <Header />
        <div className="base-item" >
            <SmallHero />
        </div>
        <main className="main-content">
            <MatchFilter type="bookmark" />
        </main>
        <Footer />
        </div>
    );
}
