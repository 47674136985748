import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import overlayContext from "../Context/overlayContext";
import { cryptedUserId } from "../utils";
import { doRequest } from "../utils/doRequest";

export default function StreamVideo() {
	const { overlayData } = useContext(overlayContext);
	
	const navigate = useNavigate();

	function sendAnalyticsStream(company_id, activity, title,eaagent) {
		console.log('Report analysis');
	
		doRequest('typeNum', '/?type=1625139860' , 'typeNum-GET', {
			cryptedUserId : cryptedUserId(),
			company_id : company_id,
			activity: activity,
			title: title,
			eaagent: eaagent
		  },'', (err, data) => {
			
			console.log(data);
			
		  })
	}
	useEffect(() => {
		sendAnalyticsStream(overlayData.companyId , 'stream' , overlayData.title, 0 )
	}, [overlayData])

	useEffect(() => {
		if (!AuthContext.isAuth ) { return (navigate("/user-login")); }
	}, [])

	

	

    return (
		<div id="stream-video-container">
			<div className="col-box col-2 live-stream-box">
				<div className="col-item box-70">
					{overlayData.streamUrl ? (
						<div className="iframe-box" style={{"height":"100%" }}>
							<iframe id="vimeo-player" src={overlayData.streamUrl} width="600" height="100%" frameBorder="0" allow="autoplay; fullscreen" allowfullscreen="" style={{"height": "100%"}}></iframe>
						</div>
					) : ''}
				</div>
				<div className="col-item chat-box bg-3 box-30">
					{overlayData.stage == 1 && (
						<iframe src="https://app.sli.do/event/mCeQS9nRMp9YuLaxzXtZXQ?section=8c837b6c-930d-44c8-8f66-c4f002228dba" height="100%" width="100%" frameBorder="0" style={{"minHeight": "590px"}} title="Slido"></iframe>
					)}
					
					{overlayData.stage == 2 && (
						<iframe src="https://app.sli.do/event/mCeQS9nRMp9YuLaxzXtZXQ?section=8e153cf3-a53c-477b-95fe-478adafe9dac" height="100%" width="100%" frameBorder="0" style={{"minHeight": "590px"}} title="Slido"></iframe>
					)}

					{overlayData.stage == 3 && (
						<iframe src="https://app.sli.do/event/mCeQS9nRMp9YuLaxzXtZXQ?section=3dc2b8eb-f3a9-4fe0-8d48-b10635cb6556" height="100%" width="100%" frameBorder="0" style={{"minHeight": "590px"}} title="Slido"></iframe>
					)}
				</div>
			</div>
		
			<div className="next-overlay" ></div>
			{/* <button id="watch-next-btn" >This stream is over, the next stream will start soon. Click here to load it.</button> */}
			<script src="https://player.vimeo.com/api/player.js"></script>
		</div>
    )
}